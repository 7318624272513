/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MyRequestAddRequest from '../MyRequestAddRequest'
import { convertUTCToLocale } from '../../Utils/utils'
import { Link } from "react-router-dom";
import { SortableContainer, SortableElement, sortableHandle } from 'react-ordering'
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useCookies } from "react-cookie";
import "./indexsort.scss";

function RequestListItemSort({
  ratingCopyTimeSpentFlag,
  data,
  loadMoreRequest,
  viewRequest,
  viewRequestUrl,
  hideDeleteIcon,
  deleteRequest,
  copyClientRequest,
  requestStatusSection,
  draftRequestClick,
  requestPrioritize,
  isAllPriortise,
}) {
  const { user } = useSelector((state) => state.auth);
  const [requests, setRequests] = useState(data?.datatable?.data)
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const {
    filters,
    length,
    sortByFilter,
  } = useSelector((state) => state.myRequest);
  useEffect(() => {
    if (data && data.datatable && data.datatable.data)
      setRequests(JSON.parse(JSON.stringify(data.datatable.data)))
  }, [data])
  const isViewRequestUrlFunction = useMemo(() => typeof viewRequestUrl === 'function', [viewRequestUrl]);
  const loadMoreRecords = () => {
    loadMoreRequest();
  };
  const viewRequestNew = (e) => {
    viewRequest(e.target.id);
  };
  const deleteRequestFromList = (e) => {
    deleteRequest(e.target.id);
  };
  const copyRequest = (e) => {
    copyClientRequest(e.target.id);
  };
  const draftClick = (e) => {
    draftRequestClick(e.target.id);
  };

  const renderRatingStar = (ratingCount) => {
    const ratingArray = [0, 0, 0, 0, 0];
    for (let i = 0; i < ratingArray.length; i++) {
      if (i < ratingCount) {
        ratingArray[i] = 1;
      } else {
        ratingArray[i] = 0;
      }
    }

    return (
      <>
        {ratingArray.map((data, index) => {
          return (
            <span
              key={index}
              className={data === 1 ? "star active" : "star"}
            ></span>
          );
        })}
      </>
    );
  };

  const isDraftSection = Boolean(requestStatusSection === "yourdrafts");
  const whenAdded = (val) => {
    if (val == "today") {
      return "Added Today";
    } else if (val == "recently") {
      return "Added Recently";
    } else {
      return false;
    }
  }
  const getItemContent = (key) => (
    <div
      onClick={
        isDraftSection
          ? draftClick
          : viewRequestNew
      }
      style={{ cursor: "pointer" }}
      id={data.datatable.data[key]["clientRequestId"]}
      className="request-detail"
    >
      <div
        id={data.datatable.data[key]["clientRequestId"]}
        className="title-and-comment"
      >
        <div className="request-img">
          <img
            onClick={
              isDraftSection
                ? draftClick
                : viewRequestNew
            }
            id={data.datatable.data[key]["clientRequestId"]}
            src={data.datatable.data[key]["request_type_image"]}
            alt="CP2020"
          />
        </div>
        <h2
          id={data.datatable.data[key]["clientRequestId"]}
          className="title"
        >
          {data.datatable.data[key]["requestTitle"]}
        </h2>
        <ul
          id={data.datatable.data[key]["clientRequestId"]}
          className="comment-and-revision"
        >
          <li id={data.datatable.data[key]["clientRequestId"]}>
            <a
              id={data.datatable.data[key]["clientRequestId"]}
              href="#"
              title={
                data.datatable.data[key]["requestTotalComments"]
                  ? data.datatable.data[key][
                  "requestTotalComments"
                  ]
                  : 0 + " Comments"
              }
            >
              {data.datatable.data[key]["requestTotalComments"]
                ? data.datatable.data[key][
                "requestTotalComments"
                ]
                : 0}{" "}
              Comments
            </a>
          </li>
          <li>
            <a
              href="#"
              id={data.datatable.data[key]["clientRequestId"]}
              title={
                data.datatable.data[key][
                "requestRevisionNumber"
                ] + " Revisions"
              }
            >
              {
                data.datatable.data[key][
                "requestRevisionNumber"
                ]
              }{" "}
              Revisions
            </a>
          </li>
        </ul>
      </div>
      {data.datatable && data.datatable.data && data.datatable.data[key] && data.datatable.data[key]["whenAdded"] && <div className="when-added">
        <span>{whenAdded(data.datatable.data[key]["whenAdded"])}</span>
      </div>}
      <div
        className="name-and-status"
        id={data.datatable.data[key]["clientRequestId"]}
      >
        <div className="name-container">
          <span
            id={data.datatable.data[key]["clientRequestId"]}
            className="request-added"
            style={{
              display: user.group_id == 3 ? "" : "none",
            }}
          >
            Designer:{" "}
            {data.datatable.data[key]["assignedTo"] &&
              data.datatable.data[key]["assignedTo"]
              ? data.datatable.data[key]["assignedTo"]
              : ""}
          </span>
          <span
            id={data.datatable.data[key]["clientRequestId"]}
            className="request-added"
          >
            Added By: {data.datatable.data[key]["requestAddedBy"]}
          </span>
        </div>
        <div className="status-container">
          {data?.datatable?.data[key]["sold_id"] > 0 ?
            <span className="request-status sold" >
              Sold
            </span>
            : null}

          <span
            id={data.datatable.data[key]["clientRequestId"]}
            className={
              "request-status " +
              (data.datatable.data[key]["requestStatus"]
                ? data.datatable.data[key]["requestStatus"] == "To be Continued" && (user.group_id == 1 || user.group_id == 2 || user.group_id == 4 || user.group_id == 5) ? "In Progress" : data.datatable.data[key]["requestStatus"]
                : ""
              )
                .toLowerCase()
                .replace(/\s+/g, "-")
            }
          >
            {data.datatable.data[key]["requestStatus"] == "To be Continued" && (user.group_id == 1 || user.group_id == 2 || user.group_id == 4 || user.group_id == 5) ? "In Progress" : data.datatable.data[key]["requestStatus"]}
          </span>
        </div>
      </div>
    </div>
  );

  const borderHighlight = (requestStatusSection, isRequestPrioritised, isAllPriortise, is_all_tab_prioritisation_enabled) => {
    if (is_all_tab_prioritisation_enabled) {
      if (is_all_tab_prioritisation_enabled == 1) {
        return "";
      }
    } else if ((requestStatusSection == 'all-requests' || requestStatusSection == 'designerprioritized' || requestStatusSection == 'myopenrequest') && isRequestPrioritised == 1 && isAllPriortise == false) {
      return "border-highlight";
    } else {
      return "";
    }
  }

  const flagIcon = (isRequestPrioritised, isAllPriortise, is_all_tab_prioritisation_enabled) => {
    if (is_all_tab_prioritisation_enabled) {
      if (is_all_tab_prioritisation_enabled !== 1) {
        return (<div className="flagimg">
          <img src={require("../../assets/images/flag-iconnew.png")} alt="" />
        </div>)
      }
    } else if (user && user.is_all_tab_prioritisation_enabled && user.is_all_tab_prioritisation_enabled == 1) {
      return null;
    } else if (isRequestPrioritised == 1 && isAllPriortise == false) {
      return (<div className="flagimg">
        <img src={require("../../assets/images/flag-iconnew.png")} alt="" />
      </div>)
    } else {
      return null;
    }
  }

  const indexNo = (i, p, isAllPriortise) => {
    if (user["is_all_tab_prioritisation_enabled"] == 1 && p) {
      return p
    } else if (isAllPriortise && p) {
      return p;
    } else {
      return i + 1;
    }
  }
  const DragHandle = sortableHandle(() => <span className="request-drag">Drag</span>)
  const SortableItem = SortableElement(({ keyi, indexi, value, whenAdded, flagIcon, borderHighlight, isAllPriortise, viewRequestUrl, requestPrioritize, renderRatingStar, convertUTCToLocale, indexNo, ratingCopyTimeSpentFlag, copyRequest, deleteRequestFromList, requestStatusSection, isDraftSection, draftClick, viewRequestNew, hideDeleteIcon }) => {
    return (
      <li key={keyi} className={(requestStatusSection == 'yourqueue' || requestStatusSection == 'designerprioritized') && value["isRequestPrioritised"] == 1 ? "border-highlight dragged" : "dragged"}>
        <div className="about-request cf">
          {flagIcon(value["isRequestPrioritised"], isAllPriortise, value["is_all_tab_prioritisation_enabled"])}
          <WrapLinkTag
            to={isViewRequestUrlFunction ? viewRequestUrl(value["clientRequestId"]) : undefined}
            renderLink={isViewRequestUrlFunction}
          >
            <span
              onClick={
                isDraftSection
                  ? draftClick
                  : viewRequestNew
              }
              style={{ cursor: "pointer" }}
              id={value["clientRequestId"]}
              className="request-id"
            >
              Request #{value["requestId"]}
            </span>
          </WrapLinkTag>

          <span
            style={{
              color: "#DFDFDF",
              marginLeft: "14px",
              marginRight: "14px",
            }}
          >
            {"|"}
          </span>
          {(requestStatusSection == 'all-requests' || requestStatusSection == 'open-requests' || requestStatusSection == 'wip' || requestStatusSection == 'needs-information') && value["isRequestPrioritised"] == 1 &&
            user["is_all_tab_prioritisation_enabled"] !== 1 && <span className={value["isRequestPrioritised"] == 1 ? "prioritized" : "prioritize"}>PRIORITIZED</span>}
          {(requestStatusSection == 'all-requests' || requestStatusSection == 'open-requests' || requestStatusSection == 'wip' || requestStatusSection == 'needs-information') && value["requestStatusSlug"] !== "needs-approval"
            && value["requestStatusSlug"] !== "needs-information" &&
            value["requestStatusSlug"] !== "in-progress" && user.is_request_prioritisation_allowed == 1
            && value["isRequestPrioritised"] !== 1
            ? <span className={value["isRequestPrioritised"] == 1 ? "prioritized" : "prioritize"}
              onClick={() => {
                if (value["isRequestPrioritised"] == 0 && user.is_request_prioritisation_allowed == 1)
                  requestPrioritize(value["clientRequestId"], false)
              }}>
              {value["isRequestPrioritised"] == 1 ? "PRIORITIZED" : "PRIORITIZE"}
              <span className="tooltip-prioritize">Click here to prioritize this design request</span></span> : null}
          {requestStatusSection == 'prioritized' && user.is_request_prioritisation_allowed == 1 ? <span className={"deprioritized"}
            onClick={() => {
              if (user.is_request_prioritisation_allowed == 1) {
                requestPrioritize(value["clientRequestId"], true)
              }
            }}>DEPRIORITIZE<span className="tooltip-prioritize">Click here to deprioritize this design request</span></span> : null}
          {(requestStatusSection == 'designerprioritized' || requestStatusSection == 'designerprioritizedtab') && value["isRequestPrioritised"] == 1 && isAllPriortise == false ?
            <span className="prioritized">PRIORITIZED</span> : null}
          {requestStatusSection == 'myopenrequest' && value["isRequestPrioritised"] == 1 && value["is_all_tab_prioritisation_enabled"] !== 1 ?
            <><span className="prioritized">PRIORITIZED</span><span>&nbsp;</span></> : null}
          <span
            style={{
              display: user.group_id == 3 ? "" : "none",
              color: "#71B601",
            }}
            className="request-id"
          >
            {value["company_name"] &&
              value["company_name"]
              ? value["company_name"]
              : ""}
          </span>
          <div className="request-right-section">
            <span
              style={{
                display: ratingCopyTimeSpentFlag ? "" : "none",
              }}
              className="rating"
            >
              {renderRatingStar(
                value["requestRating"]
              )}
            </span>
            <span
              style={{
                display: ratingCopyTimeSpentFlag ? "" : "none",
              }}
              className="time-spent"
            >
              Time Spent: {value["timeSpent"]}
            </span>
            <span className="due-date">
              Created on: {convertUTCToLocale(value["requestCreateDate"])?.dateAndTime} &nbsp;
            </span>
            <span className="due-date">
              Due: {convertUTCToLocale(value["requestDueDate"])?.date}
            </span>
            <span className="last-updated">
              Last Updated:{" "}
              {convertUTCToLocale(value["requestLastUpdateDate"])?.dateAndTime}
            </span>
            {(isDraftSection || value["requestStatus"] ===
              "Queued" ||
              value["status"] === "0") && (
                <span
                  style={{
                    display:
                      hideDeleteIcon ||
                        (!value["is_delete"]) && !isDraftSection
                        ? "none"
                        : "",
                  }}
                  className="request-delete"
                  onClick={deleteRequestFromList}
                  id={value["clientRequestId"]}
                >
                  request-delete
                </span>
              )}
            <span className="request-no">#{indexNo(indexi, value["requestPrioritiseOrder"], isAllPriortise)}</span>
            {(user?.group_id !== 4 && user?.group_id !== 5 && user?.is_request_prioritisation_allowed == 1 &&
              user?.is_all_tab_prioritisation_enabled == 0) && <DragHandle />}
            {(user?.group_id !== 4 && user?.group_id !== 5 && user?.is_request_prioritisation_allowed == 1 && filters
              && Object.keys(filters).length == 0 && user?.is_all_tab_prioritisation_enabled == 1 && sortByFilter == "prioritise_order") && <DragHandle />}
            <span
              id={value["clientRequestId"]}
              onClick={copyRequest}
              style={{
                display: ratingCopyTimeSpentFlag ? "" : "none",
              }}
              className="copy-tool"
            >
              cursor-box
            </span>
            {value["has_updates"] == 1 ? <span id={"ind" + value["clientRequestId"]} className="indication"></span> : null}
          </div>
        </div>
        <WrapLinkTag
          to={isViewRequestUrlFunction ? viewRequestUrl(value["clientRequestId"]) : undefined}
          renderLink={isViewRequestUrlFunction}
        >
          <div
            onClick={
              isDraftSection
                ? draftClick
                : viewRequestNew
            }
            style={{ cursor: "pointer" }}
            id={value["clientRequestId"]}
            className="request-detail"
          >
            <div
              id={value["clientRequestId"]}
              className="title-and-comment"
            >
              <div className="request-img">
                <img
                  onClick={
                    isDraftSection
                      ? draftClick
                      : viewRequestNew
                  }
                  id={value["clientRequestId"]}
                  src={value["request_type_image"]}
                  alt="CP2020"
                />
              </div>
              <h2
                id={value["clientRequestId"]}
                className="title"
              >
                {value["requestTitle"]}
              </h2>
              <ul
                id={value["clientRequestId"]}
                className="comment-and-revision"
              >
                <li id={value["clientRequestId"]}>
                  <a
                    id={value["clientRequestId"]}
                    href="#"
                    title={
                      value["requestTotalComments"]
                        ? value[
                        "requestTotalComments"
                        ]
                        : 0 + " Comments"
                    }
                  >
                    {value["requestTotalComments"]
                      ? value[
                      "requestTotalComments"
                      ]
                      : 0}{" "}
                    Comments
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    id={value["clientRequestId"]}
                    title={
                      value[
                      "requestRevisionNumber"
                      ] + " Revisions"
                    }
                  >
                    {
                      value[
                      "requestRevisionNumber"
                      ]
                    }{" "}
                    Revisions
                  </a>
                </li>
              </ul>
            </div>
            {value && value["whenAdded"] && <div className="when-added">
              <span>{whenAdded(value["whenAdded"])}</span>
            </div>}
            <div
              className="name-and-status"
              id={value["clientRequestId"]}
            >
              <div className="name-container">
                <span
                  id={value["clientRequestId"]}
                  className="request-added"
                  style={{
                    display: user.group_id == 3 ? "" : "none",
                  }}
                >
                  Designer:{" "}
                  {value["assignedTo"] &&
                    value["assignedTo"]
                    ? value["assignedTo"]
                    : ""}
                </span>
                <span
                  id={value["clientRequestId"]}
                  className="request-added"
                >
                  Added By: {value["requestAddedBy"]}
                </span>
              </div>
              <div className="status-container">
                {value["sold_id"] > 0 ?
                  <span className="request-status sold" >
                    Sold
                  </span>
                : null}

                <span
                  id={value["clientRequestId"]}
                  className={
                    "request-status " +
                    (value["requestStatus"]
                      ? value["requestStatus"] == "To be Continued" && (user.group_id == 1 || user.group_id == 2 || user.group_id == 4 || user.group_id == 5) ? "In Progress" : value["requestStatus"]
                      : ""
                    )
                      .toLowerCase()
                      .replace(/\s+/g, "-")
                  }
                >
                  {value["requestStatus"] == "To be Continued" && (user.group_id == 1 || user.group_id == 2 || user.group_id == 4 || user.group_id == 5) ? "In Progress" : value["requestStatus"]}
                </span>
              </div>
            </div>
          </div>
        </WrapLinkTag>
      </li>)
  })

  const SortableList = SortableContainer(({ requests, whenAdded, flagIcon, borderHighlight, isAllPriortise, viewRequestUrl, requestPrioritize, renderRatingStar,
    convertUTCToLocale, indexNo, ratingCopyTimeSpentFlag, copyRequest, deleteRequestFromList, requestStatusSection, isDraftSection, draftClick, viewRequestNew, hideDeleteIcon }) => {

    return (
      <ul className="requestlis">
        {requests.map((value, index) => {
          return <SortableItem
            key={`itemi-${index}`}
            index={index}
            keyi={`item-${index}`}
            indexi={index}
            value={value}
            whenAdded={whenAdded}
            flagIcon={flagIcon}
            borderHighlight={borderHighlight}
            isAllPriortise={isAllPriortise}
            viewRequestUrl={viewRequestUrl}
            requestPrioritize={requestPrioritize}
            renderRatingStar={renderRatingStar}
            convertUTCToLocale={convertUTCToLocale}
            indexNo={indexNo}
            ratingCopyTimeSpentFlag={ratingCopyTimeSpentFlag}
            copyRequest={copyRequest}
            deleteRequestFromList={deleteRequestFromList}
            requestStatusSection={requestStatusSection}
            isDraftSection={isDraftSection}
            draftClick={draftClick}
            viewRequestNew={viewRequestNew}
            hideDeleteIcon={hideDeleteIcon}
          />
        })}
      </ul>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      var element = requests[oldIndex];
      requests.splice(oldIndex, 1);
      requests.splice(newIndex, 0, element);
      let tempArr = [...requests];
      let arrayForSubmit = [];
      tempArr.forEach((ta) => {
        arrayForSubmit.push(ta.clientRequestId)
      })
      dispatch(myRequestActions.sortPrioritizeRequest(element.clientRequestId, newIndex + 1, cookies.clientAccessToken));
      setRequests(tempArr)
    }
  }
  return (
    <div className="requestlistitem">
      <div className="container">

        {requests && requests.length !== 0
          && <SortableList
            useDragHandle={true}
            useWindowAsScrollContainer={true}
            onSortEnd={onSortEnd}
            requests={requests}
            whenAdded={whenAdded}
            flagIcon={flagIcon}
            borderHighlight={borderHighlight}
            isAllPriortise={isAllPriortise}
            viewRequestUrl={viewRequestUrl}
            requestPrioritize={requestPrioritize}
            renderRatingStar={renderRatingStar}
            convertUTCToLocale={convertUTCToLocale}
            indexNo={indexNo}
            ratingCopyTimeSpentFlag={ratingCopyTimeSpentFlag}
            copyRequest={copyRequest}
            deleteRequestFromList={deleteRequestFromList}
            requestStatusSection={requestStatusSection}
            isDraftSection={isDraftSection}
            draftClick={draftClick}
            viewRequestNew={viewRequestNew}
            hideDeleteIcon={hideDeleteIcon} />
        }
        <div className="moredesignrequests">
          {data &&
            data.datatable &&
            data.datatable.data.length !== 0 &&
            !(
              data.datatable.data.length === 0 ||
              data.datatable.data.length === data.datatable.recordsTotal ||
              data.datatable.data.length === data.datatable?.recordsFiltered
            ) && (
              <a
                style={{
                  cursor: "pointer",
                }}
                onClick={loadMoreRecords}
                href={void 0}
                title="And 20 more design requests..."
              >
                And 20 more design requests...
              </a>
            )}
        </div>
      </div>
      {(data === "" || data?.datatable?.data.length === 0) && <MyRequestAddRequest accountType={user.group_id} />}
    </div>
  );
}

const WrapLinkTag = memo(({ children, to, renderLink }) => {
  const { user } = useSelector((state) => state.auth);
  const clearIndicationFromItem = (id) => {
    const element = document.getElementById("ind" + id);
    if (element) {
      element.remove();
    }
  }
  return (
    renderLink ? (
      <Link to={to} target={(user.id == 1 || user.parent_id == 1) ? "_self" : "_blank"}>
        {children}
      </Link>
    ) : children
  )
});

export default RequestListItemSort;
