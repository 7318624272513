import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";
import { actions as generalActions } from "./GeneralReducer";
import { postApi, postApiToken, getApi, getApiToken } from "../apis";
import apiUrl from "../../config/urls";
import { actions as designerRequestActions } from "./DesignerRequestReducer";
const uid = new ShortUniqueId();

export const actionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  CHANGE_AUTHORIZATION: "CHANGE_AUTHORIZATION",
  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  CLEAR_LOGIN_FLAGS: "CLEAR_LOGIN_FLAGS",
  EMAIL_FORGOT_PASSWORD: "EMAIL_FORGOT_PASSWORD",
  CHANGE_EMAIL_FORGOT_PASSWORD: "CHANGE_EMAIL_FORGOT_PASSWORD",
  FORGOT_PASSWORD_LOADIING: "FORGOT_PASSWORD_LOADIING",
  FORGOT_PASSWORD_SUCESSS: "FORGOT_PASSWORD_SUCESSS",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
  CLEAR_FORGOT_PASSWORD_FLAGS: "CLEAR_FORGOT_PASSWORD_FLAGS",
  SIGNUP: "SIGNUP",
  SIGNUP_LOADIING: "SIGNUP_LOADIING",
  SIGNUP_SUCESSS: "SIGNUP_SUCESSS",
  SIGNUP_FAILED: "SIGNUP_FAILED",
  CLEAR_SIGNUP_FLAGS: "CLEAR_SIGNUP_FLAGS",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_LOADIING: "RESET_PASSWORD_PASSWORD_LOADIING",
  RESET_PASSWORD_SUCESSS: "RESET_PASSWORD_PASSWORD_SUCESSS",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_PASSWORD_FAILED",
  CLEAR_RESET_PASSWORD_FLAGS: "CLEAR_RESET_PASSWORD_FLAGS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  CLEAR_LOGOUT_FLAGS: "CLEAR_LOGOUT_FLAGS",
  LOGOUT_LOADIING: "LOGOUT_LOADIING",
  LOGIN_WITH_GOOGLE: "LOGIN_WITH_GOOGLE",
  LOGIN_WITH_GOOGLE_SUCCESS: "LOGIN_WITH_GOOGLE_SUCCESS",
  CLEAR_GOOGLE_LOGIN_FLAGS: "CLEAR_GOOGLE_LOGIN_FLAGS",
  LOGIN_WITH_GOOGLE_CALLBACK: "LOGIN_WITH_GOOGLE_CALLBACK",
  CLEAR_GOOGLE_CALLBACK_FLAG: "CLEAR_GOOGLE_CALLBACK_FLAG",
  SET_PASSWORD_FOR_REGISTERATION: "SET_PASSWORD_FOR_REGISTERATION",
  SET_PASSWORD: "SET_PASSWORD",
  SET_PASSWORD_LOADIING: "SET_PASSWORD_LOADIING",
  SET_PASSWORD_SUCESSS: "SET_PASSWORD_SUCESSS",
  SET_PASSWORD_FAILED: "SET_PASSWORD_FAILED",
  CLEAR_SET_PASSWORD_FLAGS: "CLEAR_SET_PASSWORD_FLAGS",
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  CLEAR_ACCESS_TOKEN:'CLEAR_ACCESS_TOKEN',
  SET_INDICATION_STATUS: 'SET_INDICATION_STATUS',
  SET_UNDER_CLIENT_SECTION: 'SET_UNDER_CLIENT_SECTION',
  GET_INDICATION_STATUS: 'GET_INDICATION_STATUS',
  SET_HEADER_MSG: 'SET_HEADER_MSG',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  CLEAR_USER_DATA: 'CLEAR_USER_DATA',
};

const initialState = {
  user: null,
  loginErrorMessage: "",
  loginLoading: false,
  loginSuccess: false,
  loginFailed: false,
  setErrorMsgForSignup:"",
  signupLoading: false,
  setSuccessForSignup: false,
  signupFailed: false,
  clientAccessToken: "",
  expire_in: null,
  email: "",
  forgotPasswordLoading: false,
  setErrorMsgForForgotPassword: "",
  setSuccessForForgotPassword: false,
  forgotPasswordFailed: false,
  resetPasswordLoading: false,
  setErrorMsgForResetPassword: "",
  setSuccessForResetPassword: false,
  resetPasswordFailed: false,
  isAuthorized: false,
  logoutSuccess: false,
  logoutFailure: false,
  logoutErrorMsg: "",
  logoutLoading: false,
  loginWithGoogleSuccessFlag: false,
  loginWithGoogleUrl: "",
  setFlagForGoogleCallback: false,
  setPasswordLoading: false,
  setErrorMsgForPassword: "",
  setPasswordFailed: "",
  setSuccessForPassword: false,
  logoutOnTokenExpire: false,  
  redirect: null,
  indicationStatus:null,
  underClientSection: false,
  headerMsg: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS: {
      const { flag, user, clientAccessToken, expire_in, redirect } = action.payload;
      return {
        ...state,
        isAuthorized: flag,
        loginSuccess: flag,
        user: user,
        clientAccessToken: clientAccessToken,
        expire_in: expire_in,
        redirect: redirect
      };
    }

    case actionTypes.LOGIN_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        loginLoading: flag,
      };
    }

    case actionTypes.LOGIN_FAILED: {
      const { flag, errorMessage } = action.payload;
      return {
        ...state,
        loginFailed: flag,
        loginErrorMessage: errorMessage,
      };
    }

    case actionTypes.LOGOUT_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        isAuthorized: false,
        logoutSuccess: flag,        
      };
    }
    case actionTypes.LOGOUT_FAILURE: {
      const { flag, errorMessage, tokenExpireFlag } = action.payload;
      return {
        ...state,
        logoutFailure: flag,
        logoutErrorMsg: errorMessage,
        logoutOnTokenExpire: tokenExpireFlag,
      };
    }
    case actionTypes.CLEAR_LOGOUT_FLAGS: {
      return {
        ...state,
        logoutFailure: false,
        logoutSuccess: false,
        logoutOnTokenExpire: false,
      };
    }
    case actionTypes.LOGOUT_LOADIING: {
      const { flag } = action.payload;
      return {
        ...state,
        logoutLoading: flag,
      };
    }

    case actionTypes.CHANGE_EMAIL_FORGOT_PASSWORD: {
      const { email } = action.payload;
      return {
        ...state,
        email: email,
      };
    }

    case actionTypes.CLEAR_LOGIN_FLAGS: {
      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        loginFailed: false,
      };
    }
    case actionTypes.FORGOT_PASSWORD_LOADIING: {
      const { flag } = action.payload;
      return {
        ...state,
        forgotPasswordLoading: flag,
      };
    }
    case actionTypes.FORGOT_PASSWORD_SUCESSS: {
      const { flag } = action.payload;
      return {
        ...state,
        setSuccessForForgotPassword: flag,
      };
    }
    case actionTypes.FORGOT_PASSWORD_FAILED: {
      const { flag, errorMessage } = action.payload;
      return {
        ...state,
        setErrorMsgForForgotPassword: errorMessage,
        forgotPasswordFailed: flag,
      };
    }


    case actionTypes.SIGNUP_LOADIING: {
      const { flag } = action.payload;
      return {
        ...state,
        signupLoading: flag,
      };
    }
    case actionTypes.SIGNUP_SUCESSS: {
      const { flag } = action.payload;
      return {
        ...state,
        setSuccessForSignup: flag,
      };
    }
    case actionTypes.SIGNUP_FAILED: {
      const { flag, errorMessage } = action.payload;
      return {
        ...state,
        setErrorMsgForSignup: errorMessage,
        signupFailed: flag,
      };
    }
    case actionTypes.CLEAR_SIGNUP_FLAGS: {
      return {
        ...state,
        signupLoading: false,
        setSuccessForSignup: false,
        signupFailed: false,
      };
    }


    case actionTypes.RESET_PASSWORD_LOADIING: {
      const { flag } = action.payload;
      return {
        ...state,
        resetPasswordLoading: flag,
      };
    }
    case actionTypes.RESET_PASSWORD_SUCESSS: {
      const { flag } = action.payload;
      return {
        ...state,
        setSuccessForResetPassword: flag,
      };
    }
    case actionTypes.RESET_PASSWORD_FAILED: {
      const { flag, errorMessage } = action.payload;
      return {
        ...state,
        setErrorMsgForResetPassword: errorMessage,
        resetPasswordFailed: flag,
      };
    }
    case actionTypes.CLEAR_FORGOT_PASSWORD_FLAGS: {
      return {
        ...state,
        forgotPasswordLoading: false,
        setSuccessForForgotPassword: false,
        forgotPasswordFailed: false,
        email: "",
      };
    }
    case actionTypes.CLEAR_RESET_PASSWORD_FLAGS: {
      return {
        ...state,
        resetPasswordLoading: false,
        setSuccessForResetPassword: false,
        resetPasswordFailed: false,
      };
    }
    case actionTypes.LOGIN_WITH_GOOGLE_SUCCESS: {
      const { flag, loginWithGoogleUrl } = action.payload;
      return {
        ...state,
        loginWithGoogleSuccessFlag: flag,
        loginWithGoogleUrl: loginWithGoogleUrl,
      };
    }
    case actionTypes.CLEAR_GOOGLE_LOGIN_FLAGS: {
      return {
        ...state,
        loginWithGoogleSuccessFlag: false,
        loginWithGoogleUrl: "",
        setFlagForGoogleCallback: true,
      };
    }
    case actionTypes.CLEAR_GOOGLE_CALLBACK_FLAG: {
      const { flag } = action.payload;
      return {
        ...state,
        setFlagForGoogleCallback: flag,
      };
    }
    case actionTypes.SET_PASSWORD_LOADIING: {
      const { flag } = action.payload;
      return {
        ...state,
        setPasswordLoading: flag,
      };
    }
    case actionTypes.SET_PASSWORD_SUCESSS: {
      const { flag } = action.payload;
      return {
        ...state,
        setSuccessForPassword: flag,
      };
    }
    case actionTypes.SET_PASSWORD_FAILED: {
      const { flag, errorMessage } = action.payload;
      return {
        ...state,
        setErrorMsgForPassword: errorMessage,
        setPasswordFailed: flag,
      };
    }
    case actionTypes.CLEAR_SET_PASSWORD_FLAGS: {
      return {
        ...state,
        setPasswordLoading: false,
        setSuccessForPassword: false,
        setPasswordFailed: false,
        setErrorMsgForPassword: "",
      };
    }
    case actionTypes.SET_ACCESS_TOKEN: {
      const { accessToken } = action.payload;
      return {
        ...state,
       clientAccessToken:accessToken
      };
    }
    case actionTypes.CLEAR_ACCESS_TOKEN: {
      return {
        ...state,
        clientAccessToken:""
      };
    }
    case actionTypes.SET_INDICATION_STATUS: {
      const { data } = action.payload;
      return {
        ...state,
        indicationStatus: data
      };
    }
    case actionTypes.SET_UNDER_CLIENT_SECTION: {
      const { data } = action.payload;
      return {
        ...state,
        underClientSection: data
      };
    }
    case actionTypes.SET_HEADER_MSG: {
      const { data } = action.payload;
      return {
        ...state,
        headerMsg: data
      };
    }
    case actionTypes.UPDATE_USER_INFO: {
      const { data } = action.payload;
      return {
        ...state,
        user: data,
      };
    }
    case actionTypes.CLEAR_USER_DATA: {
      
      return {
        ...state,
        user: null,
        loginErrorMessage: "",
        loginLoading: false,
        loginSuccess: false,
        loginFailed: false,
        setErrorMsgForSignup:"",
        signupLoading: false,
        setSuccessForSignup: false,
        signupFailed: false,
        clientAccessToken: "",
        expire_in: null,
        email: "",
        forgotPasswordLoading: false,
        setErrorMsgForForgotPassword: "",
        setSuccessForForgotPassword: false,
        forgotPasswordFailed: false,
        resetPasswordLoading: false,
        setErrorMsgForResetPassword: "",
        setSuccessForResetPassword: false,
        resetPasswordFailed: false,
        isAuthorized: false,
        logoutSuccess: false,
        logoutFailure: false,
        logoutErrorMsg: "",
        logoutLoading: false,
        loginWithGoogleSuccessFlag: false,
        loginWithGoogleUrl: "",
        setFlagForGoogleCallback: false,
        setPasswordLoading: false,
        setErrorMsgForPassword: "",
        setPasswordFailed: "",
        setSuccessForPassword: false,
        logoutOnTokenExpire: false,  
        redirect: null,
        indicationStatus:null,
        underClientSection: false,
        headerMsg: "",
      };
    }
      default: {
      return state;
    }
  }
};

export const actions = {
  login: (email, password) => ({
    type: actionTypes.LOGIN,
    payload: { email, password },
  }),
  loginLoading: (flag) => ({
    type: actionTypes.LOGIN_LOADING,
    payload: { flag },
  }),
  loginFailure: (flag, errorMessage) => ({
    type: actionTypes.LOGIN_FAILED,
    payload: { flag, errorMessage },
  }),
  loginSuccess: (
    flag,
    user = null,
    clientAccessToken = "",
    expire_in = null,
    redirect = null
  ) => ({
    type: actionTypes.LOGIN_SUCCESS,
    payload: { flag, user, clientAccessToken, expire_in, redirect },
  }),
  logout: (clientAccessToken) => ({
    type: actionTypes.LOGOUT,
    payload: { clientAccessToken },
  }),
  clearLoginFlags: () => ({ type: actionTypes.CLEAR_LOGIN_FLAGS }),
  changeAuthorization: (flag) => ({
    type: actionTypes.CHANGE_AUTHORIZATION,
    payload: { flag },
  }),
  emailForgotPassword: (email) => ({
    type: actionTypes.EMAIL_FORGOT_PASSWORD,
    payload: { email },
  }),
  changeEmailForgotPassword: (email) => ({
    type: actionTypes.CHANGE_EMAIL_FORGOT_PASSWORD,
    payload: { email },
  }),
 
  signup: (payload) => ({
    type: actionTypes.SIGNUP,
    payload,
  }),
  signupLoading: (flag) => ({
    type: actionTypes.SIGNUP_LOADIING,
    payload: { flag },
  }),
  setErrorMsgForSignup: (flag, errorMessage) => ({
    type: actionTypes.SIGNUP_FAILED,
    payload: { flag, errorMessage },
  }),
  setSuccessForSignup: (flag) => ({
    type: actionTypes.SIGNUP_SUCESSS,
    payload: { flag },
  }),
  clearSignupFlags: () => ({
    type: actionTypes.CLEAR_SIGNUP_FLAGS,
  }),
  resetPassword: (email, password, confirmPassowrd, token) => ({
    type: actionTypes.RESET_PASSWORD,
    payload: { email, password, confirmPassowrd, token },
  }),
  forgotPasswordLoading: (flag) => ({
    type: actionTypes.FORGOT_PASSWORD_LOADIING,
    payload: { flag },
  }),
  setErrorMsgForForgotPassword: (flag, errorMessage) => ({
    type: actionTypes.FORGOT_PASSWORD_FAILED,
    payload: { flag, errorMessage },
  }),
  setSuccessForForgotPassword: (flag) => ({
    type: actionTypes.FORGOT_PASSWORD_SUCESSS,
    payload: { flag },
  }),
  resetPasswordLoading: (flag) => ({
    type: actionTypes.RESET_PASSWORD_LOADIING,
    payload: { flag },
  }),
  setErrorMsgForResetPassword: (flag, errorMessage) => ({
    type: actionTypes.RESET_PASSWORD_FAILED,
    payload: { flag, errorMessage },
  }),
  setSuccessForResetPassword: (flag) => ({
    type: actionTypes.RESET_PASSWORD_SUCESSS,
    payload: { flag },
  }),
  clearResetPasswordFlags: () => ({
    type: actionTypes.CLEAR_RESET_PASSWORD_FLAGS,
  }),
  clearForgotPasswordFlags: () => ({
    type: actionTypes.CLEAR_FORGOT_PASSWORD_FLAGS,
  }),
  logoutSuccess: (flag) => ({
    type: actionTypes.LOGOUT_SUCCESS,
    payload: { flag },
  }),
  logoutFailure: (flag, errorMessage, tokenExpireFlag) => ({
    type: actionTypes.LOGOUT_FAILURE,
    payload: { flag, errorMessage, tokenExpireFlag },
  }),
  logoutLoading: (flag) => ({
    type: actionTypes.LOGOUT_LOADIING,
    payload: { flag },
  }),
  clearLogoutFlags: () => ({ type: actionTypes.CLEAR_LOGOUT_FLAGS }),
  loginWithGoogle: () => ({ type: actionTypes.LOGIN_WITH_GOOGLE }),
  loginWithGoogleSuccess: (flag, loginWithGoogleUrl) => ({
    type: actionTypes.LOGIN_WITH_GOOGLE_SUCCESS,
    payload: { flag, loginWithGoogleUrl },
  }),
  clearGoogleLoginFlags: () => ({ type: actionTypes.CLEAR_GOOGLE_LOGIN_FLAGS }),
  loginWithGoogleCallback: (url) => ({
    type: actionTypes.LOGIN_WITH_GOOGLE_CALLBACK,
    payload: { url },
  }),
  clearGoogleCallbackFlag: (flag) => ({
    type: actionTypes.CLEAR_GOOGLE_CALLBACK_FLAG,
    payload: { flag },
  }),
  setPasswordForRegisteration: (
    email,
    password,
    password_confirmation,
    token
  ) => ({
    type: actionTypes.SET_PASSWORD_FOR_REGISTERATION,
    payload: { email, password, password_confirmation, token },
  }),
  setPasswordLoading: (flag) => ({
    type: actionTypes.SET_PASSWORD_LOADIING,
    payload: { flag },
  }),
  setErrorMsgForPassword: (flag, errorMessage) => ({
    type: actionTypes.SET_PASSWORD_FAILED,
    payload: { flag, errorMessage },
  }),
  setSuccessForPassword: (flag) => ({
    type: actionTypes.SET_PASSWORD_SUCESSS,
    payload: { flag },
  }),
  clearSetPasswordFlags: () => ({ type: actionTypes.CLEAR_SET_PASSWORD_FLAGS }),
  getUserDetails: (clientAccessToken, redirect) => ({
    type: actionTypes.GET_USER_DETAILS,
    payload: { clientAccessToken, redirect },
  }),
  setAccessToken: (accessToken) => ({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: { accessToken },
  }),
  clearAccessToken: () => ({ type: actionTypes.CLEAR_ACCESS_TOKEN }),
  setTabIndication: (data) => ({
    type: actionTypes.SET_INDICATION_STATUS,
    payload: { data },
  }),
  setUnderClientSection: (data) => ({
    type: actionTypes.SET_UNDER_CLIENT_SECTION,
    payload: { data },
  }),
  getTabIndicationStatus: (clientId,clientAccessToken) => ({
    type: actionTypes.GET_INDICATION_STATUS,
    payload: { clientId,clientAccessToken },
  }),
  setHeaderMsg: (data) => ({
    type: actionTypes.SET_HEADER_MSG,
    payload: { data },
  }),
  updateUserInfo: (data) => ({
    type: actionTypes.UPDATE_USER_INFO,
    payload: { data },
  }),
  clearUserData: () => ({
    type: actionTypes.CLEAR_USER_DATA,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.LOGIN, loginSaga);
  yield takeLatest(actionTypes.EMAIL_FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(actionTypes.RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(actionTypes.SIGNUP, signupSaga);
  yield takeLatest(actionTypes.LOGOUT, logoutSaga);
  yield takeLatest(actionTypes.LOGIN_WITH_GOOGLE, loginWithGoogleSaga);
  yield takeLatest(
    actionTypes.LOGIN_WITH_GOOGLE_CALLBACK,
    loginWithGoogleCallbackSaga
  );
  yield takeLatest(
    actionTypes.SET_PASSWORD_FOR_REGISTERATION,
    setPasswordRegisterationSaga
  );
  yield takeLatest(
    actionTypes.GET_USER_DETAILS,
    getUserDetailsSaga
  );  
  yield takeLatest(actionTypes.GET_INDICATION_STATUS, getTabIndicationStatusSaga);
}

function* loginSaga(action) {
  const { email, password } = action.payload;
  yield all([
    put(actions.loginLoading(true)),
    put(generalActions.loginFailure(false)),
    put(actions.loginSuccess(false)),
  ]);
  try {
    const response = yield call(
      postApi,
      apiUrl("LOGIN_URL"),
      queryString.stringify({ email, password })
    );
    if (response && response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (
        responseData &&
        responseData.data &&
        responseData.data.accessToken &&
        responseData.data.user &&
        responseData.data.expire_in
      ) {
        yield all([
          put(actions.loginLoading(false)),
          put(generalActions.loginFailure(false)),
          put(
            actions.loginSuccess(
              true,
              responseData.data.user,
              responseData.data.accessToken,
              responseData.data.expire_in
            )
          ),
        ]);
      } else {
        yield all([
          put(actions.loginLoading(false)),
          put(generalActions.loginFailure(true)),
          put(actions.loginSuccess(false)),
        ]);
      }
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.message) {
        yield all([
          put(actions.loginLoading(false)),
          put(generalActions.loginFailure(true, responseData.message)),
          put(actions.loginSuccess(false)),
        ]);
      } else {
        yield all([
          put(actions.loginLoading(false)),
          put(generalActions.loginFailure(true, "Something went wrong")),
          put(actions.loginSuccess(false)),
        ]);
      }
    } else {
      yield all([
        put(actions.loginLoading(false)),
        put(generalActions.loginFailure(true, "Something went wrong")),
        put(actions.loginSuccess(false)),
      ]);
    }
  } catch (error) {
    yield all([
      put(actions.loginLoading(false)),
      put(generalActions.loginFailure(true, "Something went wrong")),
      put(actions.loginSuccess(false)),
    ]);
  }
}

function* forgotPasswordSaga(action) {
  const { email } = action.payload;
  yield all([
    put(actions.forgotPasswordLoading(true)),
    put(actions.setErrorMsgForForgotPassword(false, "")),
    put(actions.setSuccessForForgotPassword(false)),
  ]);
  try {
    const response = yield call(
      postApi,
      apiUrl("FORGOT_PASSWORD_URL"),
      queryString.stringify({ email })
    );
    if (response.status === 200) {
      yield put(actions.setSuccessForForgotPassword(true));
      return response.json();
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.error) {
        yield all([
          put(actions.forgotPasswordLoading(false)),
          put(actions.setErrorMsgForForgotPassword(true, responseData.error)),
          put(actions.setSuccessForForgotPassword(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Forgot Password",
            responseData.error,
            "danger",
            uid()
          )
        );
      } else {        
        yield all([
          put(actions.forgotPasswordLoading(false)),
          put(actions.setErrorMsgForForgotPassword(true)),
          put(actions.setSuccessForForgotPassword(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Forgot Password",
            responseData.message,
            "danger",
            uid()
          )
        );
      }
    }
  } catch (error) {
    yield all([
      put(actions.forgotPasswordLoading(false)),
      put(actions.setErrorMsgForForgotPassword(true)),
      put(actions.setSuccessForForgotPassword(false)),
    ]);
  }
}
function* resetPasswordSaga(action) {
  const { email, password, confirmPassowrd, token } = action.payload;
  yield all([
    put(actions.resetPasswordLoading(true)),
    put(actions.setErrorMsgForResetPassword(false, "")),
    put(actions.setSuccessForResetPassword(false)),
  ]);
  try {
    const response = yield call(
      postApi,
      apiUrl("RESET_PASSWORD_URL"),
      queryString.stringify({ email, password, confirmPassowrd, token })
    );
    if (response.status === 200) {
      yield put(actions.setSuccessForResetPassword(true));
      return response.json();
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.error) {
        yield all([
          put(actions.resetPasswordLoading(false)),
          put(actions.setErrorMsgForResetPassword(true, responseData.error)),
          put(actions.setSuccessForResetPassword(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Reset Password",
            responseData.error,
            "danger",
            uid()
          )
        );
      } else {
        yield all([
          put(actions.resetPasswordLoading(false)),
          put(actions.setErrorMsgForResetPassword(true)),
          put(actions.setSuccessForResetPassword(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Reset Password",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    }
  } catch (error) {
    yield all([
      put(actions.resetPasswordLoading(false)),
      put(actions.setErrorMsgForResetPassword(true)),
      put(actions.setSuccessForResetPassword(false)),
    ]);
  }
}

function* signupSaga(action) {
  yield all([
    put(actions.signupLoading(true)),
    put(actions.setErrorMsgForSignup(false, "")),
    put(actions.setSuccessForSignup(false)),
  ]);
  try {
    const response = yield call(
      postApi,
      apiUrl("SIGNUP_URL"),
      queryString.stringify(action.payload)
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if(responseData && responseData.data && responseData.data.accessToken){
        yield put(actions.setAccessToken(responseData.data.accessToken));
        yield put(actions.signupLoading(false));
      }
      
      // yield put(actions.setSuccessForSignup(true));
      // return response.json();
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.message) {
        yield all([
          put(actions.signupLoading(false)),
          put(actions.setErrorMsgForSignup(true, responseData.message)),
          put(actions.setSuccessForSignup(false)),
        ]);
        yield put(
          generalActions.addToast(
            "sign up",
            responseData.message,
            "danger",
            uid()
          )
        );
      } else {
        yield all([
          put(actions.signupLoading(false)),
          put(actions.setErrorMsgForSignup(true)),
          put(actions.setSuccessForSignup(false)),
        ]);
        yield put(
          generalActions.addToast(
            "sign up",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    }
  } catch (error) {
    yield all([
      put(actions.signupLoading(false)),
      put(actions.setErrorMsgForSignup(true)),
      put(actions.setSuccessForSignup(false)),
    ]);
  }
}

function* logoutSaga(action) {
  const { clientAccessToken } = action.payload;
  yield all([
    put(actions.logoutLoading(true)),
    put(actions.logoutFailure(false)),
    put(actions.logoutSuccess(false)),
  ]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("LOGOUT_URL"),
      clientAccessToken
    );
    if (response.status === 200) {
      yield all([
        put(actions.clearAccessToken()),
        put(actions.logoutLoading(false)),
        put(actions.logoutSuccess(true)),
        put(actions.logoutFailure(false)),
        put(actions.clearUserData()),
      ]);
      yield put(
        generalActions.addToast(
          "Logout",
          "You Have Successfully Logged Out Of Your Account",
          "success",
          uid()
        )
      );
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.message) {
        yield all([
          put(actions.clearAccessToken()),
          put(actions.logoutLoading(false)),
          put(actions.logoutSuccess(true)),
          put(actions.logoutFailure(false)),
        ]);
      } else if (response && response.status === 422) {
        const responseData = yield call([response, response.json]);
        if (responseData && responseData.error) {
          yield all([
            put(actions.clearAccessToken()),
            put(actions.logoutLoading(false)),
            put(actions.logoutSuccess(false)),
            put(actions.logoutFailure(true, responseData.error)),
          ]);
          yield put(
            generalActions.addToast(
              "Logout",
              responseData.error,
              "danger",
              uid()
            )
          );
        } else {
          yield all([
            put(actions.clearAccessToken()),
            put(actions.logoutLoading(false)),
            put(actions.logoutSuccess(false)),
            put(actions.logoutFailure(true)),
          ]);
          yield put(
            generalActions.addToast(
              "Logout",
              "Something went wrong!",
              "danger",
              uid()
            )
          );
        }
      }
    } else if (response && response.status === 403) {
      yield all([
        put(actions.clearAccessToken()),
        put(actions.logoutLoading(false)),
        put(actions.logoutSuccess(false)),
        put(actions.logoutFailure(true, "", true)),
      ]);
    }
  } catch (error) {
    yield all([
      put(actions.clearAccessToken()),
      put(actions.logoutLoading(false)),
      put(actions.logoutSuccess(false)),
      put(actions.logoutFailure(true)),
    ]);
  }
}
function* loginWithGoogleSaga(action) {
  try {
    const response = yield call(getApi, apiUrl("LOGIN_WITH_GOOGLE_URL"));
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);      
      if (responseData.data && responseData.data.url) {
        yield put(actions.loginWithGoogleSuccess(true, responseData.data.url));
      }
    }
  } catch (error) {}
}
function* loginWithGoogleCallbackSaga(action) {
  const { url } = action.payload;
  try {
    const response = yield call(
      getApi,
      apiUrl("LOGIN_WITH_GOOGLE_CALLBACK_URL"),
      `${url}`
    );
    if (response.status === 200) {
      yield put(actions.clearGoogleCallbackFlag(false));
      const responseData = yield call([response, response.json]);
      if (
        responseData &&
        responseData.data[0] &&
        responseData.data[0].accessToken &&
        responseData.data.user &&
        responseData.data[0].expire_in
      ) {
        yield all([
          put(actions.loginLoading(false)),
          put(generalActions.loginFailure(false)),
          put(
            actions.loginSuccess(
              true,
              responseData.data.user,
              responseData.data[0].accessToken,
              responseData.data[0].expire_in
            )
          ),
        ]);
      } else {
        yield all([
          put(actions.loginLoading(false)),
          put(generalActions.loginFailure(true)),
          put(actions.loginSuccess(false)),
        ]);
      }
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.message) {
        yield all([
          put(actions.loginLoading(false)),
          put(generalActions.loginFailure(true, responseData.message)),
          put(actions.loginSuccess(false)),
          put(actions.clearGoogleCallbackFlag(false)),
        ]);
        yield put(
          generalActions.addToast("Login", responseData.message, "danger", uid())
        );
      } else {
        yield all([
          put(actions.loginLoading(false)),
          put(generalActions.loginFailure(true)),
          put(actions.loginSuccess(false)),
          put(actions.clearGoogleCallbackFlag(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Login",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.loginLoading(false)),
        put(generalActions.loginFailure(true)),
        put(actions.loginSuccess(false)),
        put(actions.clearGoogleCallbackFlag(false)),
      ]);
    }
  } catch (error) {
    yield all([
      put(actions.loginLoading(false)),
      put(generalActions.loginFailure(true)),
      put(actions.loginSuccess(false)),
      put(actions.clearGoogleCallbackFlag(false)),
    ]);
  }
}

function* setPasswordRegisterationSaga(action) {
  const { email, password, password_confirmation, token } = action.payload;
  yield all([
    put(actions.setPasswordLoading(true)),
    put(actions.setErrorMsgForPassword(false, "")),
    put(actions.setSuccessForPassword(false)),
  ]);
  try {
    const response = yield call(
      postApi,
      apiUrl("SET_PASSWORD_URL"),
      queryString.stringify({ email, password, password_confirmation, token })
    );
    if (response.status === 200) {
      yield put(actions.setSuccessForPassword(true));
      return response.json();
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.error) {
        yield all([
          put(actions.setPasswordLoading(false)),
          put(actions.setErrorMsgForPassword(true, responseData.error)),
          put(actions.setSuccessForPassword(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Set Password",
            responseData.error,
            "danger",
            uid()
          )
        );
      } else {
        yield all([
          put(actions.setPasswordLoading(false)),
          put(actions.setErrorMsgForPassword(true)),
          put(actions.setSuccessForPassword(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Set Password",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    }
  } catch (error) {
    yield all([
      put(actions.setPasswordLoading(false)),
      put(actions.setErrorMsgForPassword(true)),
      put(actions.setSuccessForPassword(false)),
    ]);
  }
}

function* getUserDetailsSaga(action){
  const { clientAccessToken, redirect } = action.payload;
  if(clientAccessToken == "/app/client/submitrequest"){
    return;
  }
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_USER_DETAILS_URL"),null,
      clientAccessToken
    ) 
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);      
      if (
        responseData
      ) {
        yield all([
          put(
            actions.loginSuccess(
              true,
              responseData,
              clientAccessToken,
              "",
              redirect,
            )
          ),
          put(generalActions.loginFailure(false)),
          put(
            actions.setTabIndication(responseData.has_updates)
          ),
          put(
            actions.setHeaderMsg(responseData.free_sample_designs)
          ),
        ]);
      }
    } else if (response && response.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.error) {
        yield all([
          put(generalActions.loginFailure(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Login As Client",
            responseData.error,
            "danger",
            uid()
          )
        );
      } else {
        yield put(
          generalActions.addToast(
            "Login As Client",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    }
  }catch(error){
    yield all([
     put(generalActions.loginFailure(false)),
    ]);
  }
}

function* getTabIndicationStatusSaga(action) {
  let { clientId, clientAccessToken } = action.payload;
  
  let submitData;
  const { user } = yield select((state) => state.auth);
  if(clientId){
    submitData = queryString.stringify({client_id:clientId})
  }else{
    submitData= null;
  }
  
  try {
    const response = yield call(
      postApiToken,
      apiUrl("GET_INDICATION_STATUS_URL"),
      clientAccessToken,
      submitData
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      yield put(actions.setTabIndication(responseData.data));
      
      if(clientId){
        yield put(designerRequestActions.setPriorityForAllTab(responseData.data.is_all_tab_prioritisation_enabled==1?true:false));
      }else{
        yield put(actions.updateUserInfo({...user,is_all_tab_prioritisation_enabled:responseData.data.is_all_tab_prioritisation_enabled}));
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(actions.logout());
      } else {
        yield put(
          generalActions.addToast(
            "Get Indication Status",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get Indication Status",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
}
