import { getBase64DataFromUrl } from "../redux/apis";
import { actions as generalActions } from '../redux/reducers/GeneralReducer'
import store from '../redux/store'
import axios from 'axios';
import apiUrl from '../config/urls'
// import Loader from "react-loader-spinner";

export const convertUTCToLocale = (date) => {
  const dateOptions = { day: "numeric", month: "short", year: "numeric" };
  const timeOptions = { hour12: true, hour: "2-digit", minute: "2-digit" };
  const localeRegion = "en-GB";

  try {
    if (new Date(date) instanceof Date && !isNaN(new Date(date))) {
      let time = new Date(date)
        .toLocaleTimeString(localeRegion, timeOptions)
        .toUpperCase();
      let [day, month, year] = new Date(date)
        .toLocaleDateString(localeRegion, dateOptions)
        .split(" ");

      return {
        time,
        date: `${day} ${month}, ${year}`,
        dateAndTime: `${day} ${month}, ${year} \xa0 ${time}`,
      };
    }
    // throw new Error(new Date(date));
  } catch (e) {
    console.error("Error while parsing date : ", e);
  }
};

export const increaseDateByDays = (givenDate, incrementalDays) => {
  let todaysDate = new Date();
  let providedDate = new Date(givenDate);
  let diffrentBetweenDates = Math.ceil(
    (providedDate - todaysDate) / (1000 * 3600 * 24)
  );

  if (diffrentBetweenDates > 0 && diffrentBetweenDates < incrementalDays) {
    providedDate.setDate(
      providedDate.getDate() + (incrementalDays - diffrentBetweenDates)
    );
    return providedDate;
  }

  if (diffrentBetweenDates < 0) {
    providedDate.setDate(
      providedDate.getDate() +
      (Math.abs(diffrentBetweenDates) + incrementalDays)
    );
    return providedDate;
  }

  providedDate.setDate(providedDate.getDate() + incrementalDays);
  return providedDate;
};

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const quillOptionsConfigForComment = {
  modules: {
    imageDropAndPaste: {
      // add an custom image handler
      handler: function (imageDataUrl, type, imageData){
        const file = imageData.toFile();
        let commentMedia = new FormData()
        commentMedia.append("images[]", file);
        let getLoader = document.getElementById('loader-for-comment');
        getLoader.style.display = "block";
        axios.post(
          apiUrl('UPLOAD_COMMENT_EDITOR_MEDIA'),
          commentMedia,
          {
            headers: {        
              Platform: "spa",
              Authorization: `Bearer ${getCookie('clientAccessToken')}`,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: progress => {
              let { loaded, total } = progress
              let percentageProgress = Math.floor((loaded/total) * 100)
              document.getElementById('fileUploadPercentage').innerText = percentageProgress+" %"
            },
          }
        ).then(response => {
          if (response.status === 200) {
            getLoader.style.display = "none";
            document.getElementById('fileUploadPercentage').innerText = 0+" %"
            let index = (this.quill.getSelection() || {}).index;
            if (index === undefined || index < 0) index = this.quill.getLength();
            this.quill.insertEmbed(index, 'image', response.data.data[0], 'user')
          }else{
            getLoader.style.display = "none";
            document.getElementById('fileUploadPercentage').innerText = 0+" %"
          }
        });
        },
    },
    toolbar: {  
      container:[
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ header: [1, 2, 3, false] }],
      [{ align: [] }, { indent: "-1" }, { indent: "+1" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "clean", "image"],
    ],
    handlers: {  
      image: function(){
        const input = document.createElement('input');
        input.setAttribute('type', 'file'); 
        input.setAttribute('multiple', 'multiple');  
        input.setAttribute('accept', 'image/*');  
        input.click();  
      
        input.onchange = async () => {  
          let commentMedia = new FormData()
          for (let i = 0; i < input.files.length; i++) {
            commentMedia.append("images[]", input.files[i]);
          }
          let getLoader = document.getElementById('loader-for-comment');
          getLoader.style.display = "block";
          axios.post(
            apiUrl('UPLOAD_COMMENT_EDITOR_MEDIA'),
            commentMedia,
            {
              headers: {        
                Platform: "spa",
                Authorization: `Bearer ${getCookie('clientAccessToken')}`,
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: progress => {
                let { loaded, total } = progress
                let percentageProgress = Math.floor((loaded/total) * 100)
                document.getElementById('fileUploadPercentage').innerText = percentageProgress+" %"
              },
            }
          ).then(response => {
            if (response.status === 200) {
              getLoader.style.display = "none";
              document.getElementById('fileUploadPercentage').innerText = 0+" %"
              let index = (this.quill.getSelection() || {}).index;
              if (index === undefined || index < 0) index = this.quill.getLength();
              response.data.data.forEach(function(v){
                this.quill.insertEmbed(index, 'image', v, 'user')
              }.bind(this))
            }else{
              getLoader.style.display = "none";
              document.getElementById('fileUploadPercentage').innerText = 0+" %"
            }
          }).catch(error =>{
              getLoader.style.display = "none";
              document.getElementById('fileUploadPercentage').innerText = 0+" %"
          });
        }
      }  
    }},
  },
  formats: [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'header',
    'list', 'bullet', 'indent', 'align',
    'link', 'image'
  ],
};

export const quillOptionsCongif = {
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ header: [1, 2, 3, false] }],
      [{ align: [] }, { indent: "-1" }, { indent: "+1" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "clean"],
    ],
  },
  formats: [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'header',
    'list', 'bullet', 'indent', 'align',
    'link',
  ],
};

export const getFileTypeIcon = (fileType) => {
  switch (fileType) {
    case "image/png": {
      return `${require("../assets/images/png.png")}`;
    }
    case "image/jpeg": {
      return `${require("../assets/images/jpeg.png")}`;
    }
    case "application/pdf": {
      return `${require("../assets/images/pdf.png")}`;
    }

    case "application/octet-stream": {
      return `${require("../assets/images/kit.png")}`;
    }

    default: {
      return `${require("../assets/images/file.png")}`;
    }
  }
};

export const downloadFile = (fileUrl, filename, cookie) => {
  getBase64DataFromUrl(fileUrl, cookie)
    .then((url) => {
      const link = document.createElement("a");
      link.href = url?.data?.data[0];
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((error) => console.log("Error : ", error));
};

export const convertStringToHtml = (stringToBeParsed) => {
  const parser = new DOMParser();
  return parser.parseFromString(stringToBeParsed, "text/html").body.innerHTML
}

export const validDateChecker = date => new Date(date) instanceof Date && !isNaN(new Date(date))
export const showToast = (title, message, type) => store.dispatch(generalActions.addToast(title, message, type));


/** Error getFieldCSSClasses
*  Class Name managed by This function   
 * @param {Boolean} touched  Label Pass Boolean Value.
 * @param {String} errors htmlFor Pass String Value.
 * @returns Class Name
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 *  Notes:- Dec 10 2021 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
*/

export const getFieldCSSClasses = ({ touched, errors }) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};


export const capitalizeFirstLetter = (string) => {
  if (string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
  } else {
      return string
  }
}