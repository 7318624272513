/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as AuthActions } from "../../redux/reducers/AuthReducer";
import SignupForm from "../../components/SignupForm";
import LoaderComponent from "../../components/Loader";
import "./index.scss";
import { useCookies } from "react-cookie";
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";
import ShortUniqueId from "short-unique-id";
import FooterLinks from "../../components/Common/FooterLinks";
import { useConfig } from "../../context/configContext";

function Login() {
  const {project_name,login_page_banner} = useConfig();
  const uid = new ShortUniqueId();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    user,
    signupLoading,
    setSuccessForSignup,
    clientAccessToken,
  } = useSelector((state) => state.auth);
  const [cookies, setCookie, removeCookie] = useCookies(["clientAccessToken"]);

  useEffect(() => {
    if (setSuccessForSignup) {
      history.push("/login");
    }
    return () => { dispatch(AuthActions.clearSignupFlags()) }
  }, [setSuccessForSignup])

  const signupClicked = (payload) => {
    dispatch(AuthActions.signup(payload));
  };

  useEffect(() => {
    if (clientAccessToken) {
      dispatch(AuthActions.getUserDetails(clientAccessToken));
    }
  }, [clientAccessToken, history, dispatch, setCookie, removeCookie])

  useEffect(() => {
    if (user && clientAccessToken) {
      removeCookie('clientAccessToken', { path: '/' });
      setCookie("clientAccessToken", clientAccessToken, {
        path: "/",
      });
      dispatch(
        generalActions.addToast(
          "Sign up",
          "Your account has been created successfully!",
          "success",
          uid()
        )
      );
      history.push("/app/client/myrequests/all-requests");
      dispatch(AuthActions.clearAccessToken());
    }
  }, [user, clientAccessToken])

  return (
    <div>
      <LoaderComponent showhide={signupLoading} />
      <div className="login-wrapper signup-page">
        <div className="login-left">
          {/* <SignupForm
            signupClicked={signupClicked}
          /> */}
        </div>
        <div className="login-right">
          <div className="login-title">
            <h2>
              Creating Beautiful<span>Kitchen Designs</span>
            </h2>
          </div>
          <div className="kitchen-cabinet">
            <img
              src={login_page_banner?.value}
              alt={project_name?.value+" Banner"}
            />
          </div>
        </div>
      </div>
      <FooterLinks/>
    </div>
  );
}

export default Login;
