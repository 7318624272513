import React from "react";
import { useConfig } from "../../context/configContext";

const FooterLinks = () => {
  const {
    copyright_text,
    facebook_link,
    linkedin_link,
    twitter_x_link,
    instagram_link,
    pinterest_link,
    youtube_link,
    footer_links
  } = useConfig();

  const footerLinks = JSON.parse(footer_links?.value) || [] 
  return (
    <section className="footer-container">
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-outer">
            <div className="footer-column footer-col1">
              <p className="footer-site-info">{copyright_text?.value}</p>
            </div>
            <div className="footer-column footer-col2">
              <div className="footer-nav">
                <ul>
                  {footerLinks.map(i=>(
                    <li key={i?.text}>
                      <a href={i.link}>{i?.text}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="footer-column footer-col3">
              <ul className="social-icons">
                {facebook_link?.value &&
                  <li>
                    <a href={facebook_link.value}>
                      <img src={require("../../assets/images/facebook-img.svg")} alt="Facebook" />
                      <img src={require("../../assets/images/facebook-img-h.svg")} alt="Facebook" className="hover-img" />
                    </a>
                  </li>
                }
                {linkedin_link?.value &&
                  <li>
                    <a href={linkedin_link.value}>
                      <img src={require("../../assets/images/linkedin-img.svg")} alt="Linkedin" />
                      <img src={require("../../assets/images/linkedin-img-h.svg")} alt="Linkedin" className="hover-img" />
                    </a>
                  </li>
                }
                {twitter_x_link?.value &&
                  <li className="twitter-img">
                    <a href={twitter_x_link.value}>
                      <img src={require("../../assets/images/twitter-img.svg")} alt="Twitter" />
                      <img src={require("../../assets/images/twitter-img-h.svg")} alt="Twitter" className="hover-img" />
                    </a>
                  </li>
                }
                {instagram_link?.value &&
                  <li className="instagram-img">
                    <a href={instagram_link.value}>
                      <img src={require("../../assets/images/instagram-img.svg")} alt="Instagram" />
                      <img src={require("../../assets/images/instagram-img-h.svg")} alt="Instagram" className="hover-img" />
                    </a>
                  </li>
                }
                {pinterest_link?.value &&
                  <li>
                    <a href={pinterest_link.value}>
                      <img src={require("../../assets/images/pinterest-img.svg")} alt="Pitenrest" />
                      <img src={require("../../assets/images/pinterest-img-h.svg")} alt="Pitenrest" className="hover-img" />
                    </a>
                  </li>
                }
                {youtube_link?.value &&
                  <li>
                    <a href={youtube_link.value}>
                      <img src={require("../../assets/images/youtube-img.svg")} alt="Youtube" />
                      <img src={require("../../assets/images/youtube-img-h.svg")} alt="Youtube" className="hover-img" />
                    </a>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FooterLinks;