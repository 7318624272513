import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actions as ClientActions } from "../../redux/reducers/ManageClientReducer";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import LoaderComponent from "../Loader";
import "./index.scss";

function ClientsList() {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { clientsList, clientLoading } = useSelector(
    (state) => state.manageClients
  );
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(ClientActions.getClientList(cookies.clientAccessToken));
  }, [cookies.clientAccessToken, dispatch]);
  const setClientData = (data) => {
    dispatch(designerRequestActions.setClientSelectedData(data.clientId));
    dispatch(designerRequestActions.setPriorityForAllTab(data.is_all_tab_prioritisation_enabled==1?true:false));
  };
  return (
    <div className="clients-list-page">
      <LoaderComponent showhide={clientLoading} />
      <div className="container">
        <ul className="clients-list">
          {clientsList &&
            clientsList.map((data, index) => (
              <li key={index} style={{position:"relative"}}>
                {data.has_updates == 1?<span className="indication-client"></span>:null}
                <div className="clients-title">
                  <Link
                    to="/app/designer/allclients/clientrequests"
                    onClick={() => setClientData(data)}
                  >
                    <h3>{data.companyName}</h3>{" "}
                  </Link>
                  <span className="name">{data.clientName}</span>
                  {data.is_pm == 1?
                    <div className="contact-details-block">
                  <span className="name">Phone: <a href = {"tel: "+data.clientPhone}>{data.clientPhone}</a></span>
                  <span className="name">Email: <a href = {"mailto: "+data.clientEmail}>{data.clientEmail}</a></span>
                  </div>:null
                    }
                </div>
                <span className="totalopen-request">
                  Total Open Requests: <strong>{data.requestCount}</strong>
                </span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default ClientsList;
