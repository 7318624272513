import React, {useEffect} from "react";
import { useSelector,useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { actions as AuthActions } from '../../redux/reducers/AuthReducer';
import "./index.scss";
import ResetPasswordForm from "../../components/RegisterationForm";
import LoaderComponent from "../../components/Loader";
import { useConfig } from "../../context/configContext";

function Registeration() {
  const {project_name,login_page_banner} = useConfig();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setSuccessForPassword, setPasswordLoading } = useSelector(state => state.auth);
   useEffect(() => {
   if(setSuccessForPassword){
   dispatch(AuthActions.clearSetPasswordFlags());
   history.push("/login");
   }
  });
  const restClicked = (email,password,confirmPassword,token) => {
    dispatch(AuthActions.setPasswordForRegisteration(email,password,confirmPassword,token));
  }
  return (
    <div>
      <LoaderComponent showhide={setPasswordLoading} />
        <div className="login-wrapper">
          <div className="login-left">
            <ResetPasswordForm restClicked={(email,password,confirmPassword,token) =>restClicked(email,password,confirmPassword,token)}/>
          </div>
          <div className="login-right">
            <div className="login-title">
              <h2>
                Creating Beautiful<span>Kitchen Designs</span>
              </h2>
            </div>
            <div className="kitchen-cabinet">
              <img
                src={login_page_banner?.value}
                alt={project_name?.value+" Banner"}
              />
            </div>
          </div>
        </div>
    </div>
  );
}

export default Registeration;
