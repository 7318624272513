/* eslint-disable eqeqeq */
import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { actions as authActions } from "./AuthReducer";
import { actions as generalActions, actionTypes as generalActionsTypes } from "./GeneralReducer";
import { actions as designerActions } from "./DesignerRequestReducer";
import ShortUniqueId from "short-unique-id";
import apiUrl from "../../config/urls";
import queryString from "query-string";
import {
  getApiToken, postApiToken, putApiTokenJson,
  fetchDoorDetails, updateUserDetails, resetUserPassword, postApiTokenFormData
} from "../apis";

const uid = new ShortUniqueId();

export const actionTypes = {
  MY_REQUEST_YOURQUEUE_LISTING: "MY_REQUEST_YOURQUEUE_LISTING",
  MY_REQUEST_LISTING_LOADING: "MY_REQUEST_LISTING_LOADING",
  CHANGE_REQUEST_LISTING_DATA: "CHANGE_REQUEST_LISTING_DATA",
  DESIGN_DELIVERED_LISTING: "DESIGN_DELIVERED_LISTING",
  DRAFT_LISTING: "DRAFT_LISTING",
  LOAD_MORE_REQUEST: "LOAD_MORE_REQUEST",
  CHANGE_START_FROM: "CHANGE_START_FROM",
  CHANGE_CLIENT_REQUEST_ID: "CHANGE_CLIENT_REQUEST_ID",
  HIDE_SUB_NAVBAR: "HIDE_SUB_NAVBAR",
  GET_REQUEST_DETAIL: "GET_REQUEST_DETAIL",
  CHANGE_REQUEST_DETAIL: "CHANGE_REQUEST_DETAIL",
  DELETE_REQUEST: "DELETE_REQUEST",
  ADD_COMMENT: "ADD_COMMENT",
  CHANGE_ADD_COMMENT_DATA: "CHANGE_ADD_COMMENT_DATA",
  CHANGE_ADD_COMMENT_DATA_JSON: "CHANGE_ADD_COMMENT_DATA_JSON",
  GET_DISCUSSION_DATA: "GET_DISCUSSION_DATA",
  CHANGE_DISCUSSION_DATA: "CHANGE_DISCUSSION_DATA",
  UPDATE_COMMENT_DATA: "UPDATE_COMMENT_DATA",
  CLOSE_UPDATE_BOX: "CLOSE_UPDATE_BOX",
  DELETE_COMMENT: "DELETE_COMMENT",
  UPDATE_STATUS_CLIENT: "UPDATE_STATUS_CLIENT",
  DYNAMIC_FORM_DATA: "DYNAMIC_FORM_DATA",
  DYNAMIC_FORM_VALUES: "DYNAMIC_FORM_VALUES",
  CHANGE_DYNAMIC_FORM_VALUES: "CHANGE_DYNAMIC_FORM_VALUES",
  CHANGE_IMAGES_DATA: "CHANGE_IMAGES_DATA",
  UPDATE_REQUEST_DETAIL: "UPDATE_REQUEST_DETAIL",
  CHANGE_EDIT_REQUEST_TITLE: "CHANGE_EDIT_REQUEST_TITLE",
  CHANGE_EDIT_REQUEST_TYPE: "CHANGE_EDIT_REQUEST_TYPE",
  CHANGE_EDIT_REQUEST_UPDATED_MEDIA: "CHANGE_EDIT_REQUEST_UPDATED_MEDIA",
  CHANGE_EDIT_REQUEST_DELETED_MEDIA: "CHANGE_EDIT_REQUEST_DELETED_MEDIA",
  CHANGE_EDIT_REQUEST_DUEDATE: "CHANGE_EDIT_REQUEST_DUEDATE",
  CHANGE_EDIT_REQUEST_ADDITIONAL_NOTE: "CHANGE_EDIT_REQUEST_ADDITIONAL_NOTE",
  CHANGE_EDIT_REQUEST_ALL_SECTION_PROPERLY_LABELED:
    "CHANGE_EDIT_REQUEST_ALL_SECTION_PROPERLY_LABELED",
  SAVE_ACTIVE_REQUEST_SECTION_DATA: "SAVE_ACTIVE_REQUEST_SECTION_DATA",
  SET_COUNT: "SET_COUNT",
  CHANGE_REMOVED_IMAGES: "CHANGE_REMOVED_IMAGES",
  COPY_CLIENT_REQUEST: "COPY_CLIENT_REQUEST",
  COPY_ACTION: "COPY_ACTION",
  CHANGE_COPY_ID: "CHANGE_COPY_ID",
  REQUEST_APPOROVED: "REQUEST_APPOROVED",
  CLIENT_RATING_COMMENT: "CLIENT_RATING_COMMENT",
  COMMENT_RATING_ADDED: "COMMENT_RATING_ADDED",
  SAVE_START_FROM: "SAVE_START_FROM",
  CHANGE_HISTORY_DATA: "CHANGE_HISTORY_DATA",
  GET_HISTORY_DATA: "GET_HISTORY_DATA",
  REDIRECT_PAGE: "REDIRECT_PAGE",
  GET_DOOR_DETAILS_EDIT_INIT: 'GET_DOOR_DETAILS_EDIT_INIT',
  GET_DOOR_DETAILS_EDIT_SUCCESS: 'GET_DOOR_DETAILS_EDIT_SUCCESS',
  GET_DOOR_DETAILS_EDIT_FAILURE: 'GET_DOOR_DETAILS_EDIT_FAILURE',
  SET_UPDATE_STATUS_FLAG_MYREQUEST: 'SET_UPDATE_STATUS_FLAG_MYREQUEST',
  CLEAR_UPDATE_STATUS_FLAG_MYREQUEST: 'CLEAR_UPDATE_STATUS_FLAG_MYREQUEST',
  UPDATE_CLIENT_STATUS_INIT: 'UPDATE_CLIENT_STATUS_INIT',
  UPDATE_CLIENT_STATUS_SUCCESS: 'UPDATE_CLIENT_STATUS_SUCCESS',
  UPDATE_CLIENT_STATUS_FAILURE: 'UPDATE_CLIENT_STATUS_FAILURE',
  RESET_CLIENT_APPROVED_STATUS: 'RESET_CLIENT_APPROVED_STATUS',

  UPDATE_USER_PROFILE_INFO_INIT: "UPDATE_USER_PROFILE_INFO_INIT",
  UPDATE_USER_PROFILE_INFO_SUCCESS: "UPDATE_USER_PROFILE_INFO_SUCCESS",
  UPDATE_USER_PROFILE_INFO_FAILURE: "UPDATE_USER_PROFILE_INFO_FAILURE",

  USER_RESET_PASSWORD_INIT: 'USER_RESET_PASSWORD_INIT',
  USER_RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_FAILURE: 'USER_RESET_PASSWORD_FAILURE',
  UPDATE_FILTERS: 'MY_REQUEST/UPDATE_FILTERS',
  UPDATE_FILTERS_DROPDONW_DATA: 'MY_REQUEST/UPDATE_FILTERS_DROPDONW_DATA',
  CLEAR_REQUEST_DETAIL_DATA: 'CLEAR_REQUEST_DETAIL_DATA',
  FILE_UPLOAD_PROGRESS: 'FILE_UPLOAD_PROGRESS',
  SORT_BY_FILTER : 'SORT_BY_FILTER',
  REDIRECT_TO_REVIEW_PAGE: 'REDIRECT_TO_REVIEW_PAGE',
  CLIENT_OPEN_REQUESTS: 'CLIENT_OPEN_REQUESTS',
  CLIENT_WIP_REQUESTS: 'CLIENT_WIP_REQUESTS',
  CLIENT_NEEDS_INFORMATION_REQUESTS: 'CLIENT_NEEDS_INFORMATION_REQUESTS',
  CLIENT_CLOSED_REQUESTS: 'CLIENT_CLOSED_REQUESTS',
  REQUEST_PRIORITIZE: 'REQUEST_PRIORITIZE',
  REQUEST_DEPRIORITIZE: 'REQUEST_DEPRIORITIZE',
  MY_REQUEST_PRIORITIZE_LISTING: "MY_REQUEST_PRIORITIZE_LISTING",
  SORT_PRIORITIZE_REQUEST: "SORT_PRIORITIZE_REQUEST",
  FILTER_NOT_CHANGE_BY_API: "FILTER_NOT_CHANGE_BY_API",
  CLIENT_ALL_REQUESTS: 'CLIENT_ALL_REQUESTS',
  ADD_COMMENT_LOADING: 'ADD_COMMENT_LOADING',
  ROLL_BACK_REVISION_REQUEST: 'ROLL_BACK_REVISION_REQUEST',
  CHANGE_SORT_DIR: 'CHANGE_SORT_DIR',
  MARK_AS_SOLD_POPUP: "MARK_AS_SOLD_POPUP",
  MARK_AS_SOLD_DEFAULT_DATA: "MARK_AS_SOLD_DEFAULT_DATA",
  SAVE_SOLD_FORM_DATA: "SAVE_SOLD_FORM_DATA",
  MARK_AS_SOLD_FORM_DATA: "MARK_AS_SOLD_FORM_DATA",
  SAVE_MARK_AS_SOLD_RES_DATA: "SAVE_MARK_AS_SOLD_RES_DATA",
  MARK_AS_SOLD_DATA_UPDATE: "MARK_AS_SOLD_DATA_UPDATE",
};

const initialState = {
  myRequestListingData: [],
  myRequestListingLoading: false,
  start: 0,
  length: 20,   //10
  requestId: "",
  hideNavbar: false,
  requestDetailData: "",
  addCommentData: "",
  addCommentDataJson: "",
  commentIdUpdate: "",
  discussionData: "",
  dynamicFormdata: "",
  dynamicFormValues: "",
  images: "",
  editRequestTitle: "",
  editRequestType: "",
  editRequestUpdatedMedia: "",
  editRequestDeletedMedia: "",
  editRequestAdditionalNote: "",
  editRequestDueDate: "",
  isAllsectionproperlyLabeled: false,
  searchRequestValue: "",
  saveActiveSectionData: "",
  count: 1,
  removedImages: {
    kitchen_measurement: [],
    kitchen_photo: [],
    kit_file: [],
  },
  copyRequestId: "",
  copyAction: false,
  isRequestApprove: false,
  isCommentRatingAdded: false,
  savedStartFrom: 0,
  historyData: "",
  redirect: false,

  doorDetailsFetchEdit: {
    isFetchingDoorDetails: false,
    doorDetailsData: [],
    isFetchingDoorDetailsFailed: false,
    isFetchingDoorDetailsFailedMessage: '',
  },

  clientRequestUpdateProcess: {
    isUpdatingStatus: false,
    updatedStatusResponse: [],
    isUpdateRequestFailed: false,
    updateRequestFailedMessage: ""
  },

  userProfileUpdateProcess: {
    isUpdatingUserProfile: false,
    updatedProfileResponse: "",
    isUserProfileUpdateFailed: false,
    userProfileUpdateFailedMessage: ""
  },

  userResetPasswordProcess: {
    isResetingUserPassword: false,
    resetPasswordSuccessResponse: '',
    isResetPasswordProcessFailed: false,
    resetPasswordProcessFailedMessage: ""
  },

  setUpdateStatusForMyRequest: false,
  filters: {},
  filtersDropdownData: {
    assign_to: [],
    created_by: [],
    request_status: [],
    request_types: [],
  },
  fileUploadPercentage: 0,
  sortByFilter: "created_at",
  redirectToreview: false,
  filterNotChangeByApi: false,
  addCommentLoading: false,
  sortDir: 'ASC',
  soldPopup:{
    open:false,
    editSold:false
  },
  soldForm:{
    dataTable:{
    }
  },
  soldRes:{
    dataTable:{
    }
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REDIRECT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        redirect: data,
      };
    }
    case actionTypes.SAVE_START_FROM: {
      const { data } = action.payload;
      return {
        ...state,
        savedStartFrom: data,
      };
    }
    case actionTypes.COMMENT_RATING_ADDED: {
      const { data } = action.payload;
      return {
        ...state,
        isCommentRatingAdded: data,
      };
    }
    case actionTypes.SAVE_ACTIVE_REQUEST_SECTION_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        saveActiveSectionData: data,
      };
    }
    case actionTypes.REQUEST_APPOROVED: {
      const { data } = action.payload;
      return {
        ...state,
        isRequestApprove: data,
      };
    }
    case actionTypes.COPY_ACTION: {
      const { data } = action.payload;
      return {
        ...state,
        copyAction: data,
      };
    }

    case actionTypes.CHANGE_COPY_ID: {
      const { data } = action.payload;
      return {
        ...state,
        copyRequestId: data,
      };
    }

    case actionTypes.SET_COUNT: {
      const { data } = action.payload;
      return {
        ...state,
        count: data,
      };
    }

    case actionTypes.CHANGE_REMOVED_IMAGES: {
      const { data } = action.payload;
      return {
        ...state,
        removedImages: data,
      };
    }

    case actionTypes.CHANGE_EDIT_REQUEST_ALL_SECTION_PROPERLY_LABELED: {
      const { data } = action.payload;
      return {
        ...state,
        isAllsectionproperlyLabeled: data,
      };
    }
    case actionTypes.CHANGE_IMAGES_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        images: data,
      };
    }
    case actionTypes.CHANGE_EDIT_REQUEST_TITLE: {
      const { data } = action.payload;
      return {
        ...state,
        editRequestTitle: data,
      };
    }
    case actionTypes.CHANGE_EDIT_REQUEST_TYPE: {
      const { data } = action.payload;
      return {
        ...state,
        editRequestType: data,
      };
    }
    case actionTypes.CHANGE_EDIT_REQUEST_UPDATED_MEDIA: {
      const { data } = action.payload;
      return {
        ...state,
        editRequestUpdatedMedia: data,
      };
    }
    case actionTypes.CHANGE_EDIT_REQUEST_DELETED_MEDIA: {
      const { data } = action.payload;
      return {
        ...state,
        editRequestDeletedMedia: data,
      };
    }
    case actionTypes.CHANGE_EDIT_REQUEST_DUEDATE: {
      const { data } = action.payload;
      return {
        ...state,
        editRequestDueDate: data,
      };
    }
    case actionTypes.CHANGE_EDIT_REQUEST_ADDITIONAL_NOTE: {
      const { data } = action.payload;
      return {
        ...state,
        editRequestAdditionalNote: data,
      };
    }
    case actionTypes.DYNAMIC_FORM_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        dynamicFormdata: data,
      };
    }

    case actionTypes.DYNAMIC_FORM_VALUES: {
      const { data, values } = action.payload;
      let valueData = {};
      // eslint-disable-next-line array-callback-return
      Object.keys(data).map((key) => {
        if (key === "appliances" || key === "loose-appliances") {
          if (values[key] && values[key]["data"]) {
            for (var i = 0; i < data[key]["data"].length; i++) {
              for (var j = 0; j < values[key]["data"].length; j++) {
                if (
                  data[key]["data"][i]["category_option_id"] ===
                  values[key]["data"][j]["category_option_id"]
                ) {
                  if (values[key]["data"][j]["child_data"].length) {
                    data[key]["data"][i]["category_option_value"] =
                      values[key]["data"][j]["category_option_value"];
                  } else {
                    let filterOutDropdown = data[key]["data"][i]["child_data"].filter(
                      (x) => x && (x.category_option_type == "input-text" || x.category_option_type == "input-number")
                    );
                    values[key]["data"][j]["child_data"] =
                        filterOutDropdown;
                    data[key]["data"][i]["category_option_value"] =
                      values[key]["data"][j]["category_option_value"];
                  }
                  let width = values[key]["data"][j]["child_data"].filter(
                    (x) => x && x.name == "Width"
                  );
                  let brand = values[key]["data"][j]["child_data"].filter(
                    (x) => x && x.name == "Brand & Model"
                  );
                  let depth = values[key]["data"][j]["child_data"].filter(
                    (x) => x && x.name == "Depth"
                  );
                  let height = values[key]["data"][j]["child_data"].filter(
                    (x) => x && x.name == "Height"
                  );

                  let dropdownOption = values[key]["data"][j]["child_data"].filter(
                    (x) => x && x.category_option_type == "dropdown"
                  );

                  let otherValue;

                  if(values[key]["data"][j]["is_others"] == "1"){
                    otherValue = values[key]["data"][j]["child_data"][0];
                  }

                  if(brand.length){
                    values[key]["data"][j]["child_data"][0] = brand[0];
                  }else{
                    values[key]["data"][j]["child_data"][0] = data[key]["data"][i]["child_data"][0];
                  }
                  if(depth.length){
                    values[key]["data"][j]["child_data"][1] = depth[0];
                  }else{
                    values[key]["data"][j]["child_data"][1] = data[key]["data"][i]["child_data"][1];
                  }
                  if(width.length){
                    values[key]["data"][j]["child_data"][2] = width[0];
                  }else{
                    values[key]["data"][j]["child_data"][2] = data[key]["data"][i]["child_data"][2];
                  }
                  if(height.length){
                    values[key]["data"][j]["child_data"][3] = height[0];
                  }else{
                    values[key]["data"][j]["child_data"][3] = data[key]["data"][i]["child_data"][3];
                  }
                  if(dropdownOption.length){
                    dropdownOption[0]['selected'] = true;
                    values[key]["data"][j]["child_data"][4] = dropdownOption[0];
                  }

                  if(data[key]["data"][i]["is_others"] == "1"){
                    otherValue['is_others'] = "1";
                    values[key]["data"][j]["child_data"][4] = otherValue;
                  }
                  
                  // for (
                  //   var k = 0;
                  //   k < data[key]["data"][i]["child_data"].length;
                  //   k++
                  // ) {
                  //   for (
                  //     var l = 0;
                  //     l < values[key]["data"][j]["child_data"].length;
                  //     l++
                  //   ) {
                  //     if (
                  //       data[key]["data"][i]["child_data"][k]["id"] ===
                  //       values[key]["data"][j]["child_data"][l]["id"]
                  //     ) {
                  //       data[key]["data"][i]["child_data"][k] =
                  //         values[key]["data"][j]["child_data"][l];
                  //     }
                  //   }
                  // }
                }
              }
            }
          }
          valueData[key] = {
            option: data[key]["option"],
            data: values[key] && values[key]["data"] ? values[key]["data"] : [],
          };
        } else {
          valueData[key] = {
            option: data[key]["option"],
            data: values[key] && values[key]["data"] ? values[key]["data"] : [],
          };
        }
      });
      return {
        ...state,
        dynamicFormValues: valueData,
      };
    }

    case actionTypes.CHANGE_DYNAMIC_FORM_VALUES: {
      const {
        value,
        categoryName,
        optionId,
        type,
        indexChild,
        isPopup,
      } = action.payload;
      
      const optionObject = JSON.parse(JSON.stringify(state.dynamicFormValues));
      const mainObject = JSON.parse(JSON.stringify(state.dynamicFormdata));
      switch (type) {
        case "dropdown":
          let categoryObject = optionObject[categoryName];
          let categoryObjectData = categoryObject["data"];
          let dropdownValueIndex = categoryObjectData.findIndex(
            (x) => x.category_option_type === "dropdown"
          );
          if (value !== "Select") {
            let object = {};
            let dropdownObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === Number(optionId));
            if (dropdownObjectIndex !== -1) {
              object = mainObject[categoryName]["data"][dropdownObjectIndex];
            }
            if (dropdownValueIndex !== -1) {
              optionObject[categoryName]["data"][dropdownValueIndex] = object;
            } else {
              optionObject[categoryName]["data"].push(object);
            }
          } else {
            if (dropdownValueIndex !== -1) {
              optionObject[categoryName]["data"].splice(dropdownValueIndex, 1);
            }
          }
          break;

        case "input-text":
          if (value !== "") {
            let object = {};
            let inputTextObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (inputTextObjectIndex !== -1) {
              object = mainObject[categoryName]["data"][inputTextObjectIndex];
              object["category_option_value"] = value;
              let inputTextValueObjectIndex = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
              if (inputTextValueObjectIndex !== -1) {
                optionObject[categoryName]["data"][inputTextValueObjectIndex][
                  "category_option_value"
                ] = value;
              } else {
                optionObject[categoryName]["data"].push(object);
              }
            }
          } else {
            let inputTextObjectIndex = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (inputTextObjectIndex !== -1) {
              optionObject[categoryName]["data"].splice(
                inputTextObjectIndex,
                1
              );
            }
          }
          break;
        case "checkbox":
          if (value) {
            let object = {};
            let checkboxObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);

            if (checkboxObjectIndex !== -1) {
              object = mainObject[categoryName]["data"][checkboxObjectIndex];
              object["category_option_value"] = value;
              let checkboxObjectValueIndex = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);

              let objectNew = JSON.parse(JSON.stringify(object))
              let filterOutDropdown = objectNew['child_data'].filter(
                (x) => x && (x.category_option_type == "input-text" || x.category_option_type == "input-number")
              );
              objectNew['child_data'] = filterOutDropdown;

              if (checkboxObjectValueIndex !== -1) {
                optionObject[categoryName]["data"][
                  checkboxObjectValueIndex
                ] = objectNew;
              } else {
                optionObject[categoryName]["data"].push(objectNew);
              }
            }
          } else {
            let checkboxObjectIndex = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (checkboxObjectIndex !== -1) {
              optionObject[categoryName]["data"].splice(checkboxObjectIndex, 1);
            }
          }
          break;
          case "appliances":
            if(isPopup){                   
                let applianceObjectIndex = mainObject[categoryName][
                  "data"
                ].findIndex((x) => x.category_option_id === optionId);
                if (applianceObjectIndex !== -1) {
                  let applianceValueObjectIndex = optionObject[categoryName][
                    "data"
                  ].findIndex((x) => x.category_option_id === optionId);
                  if (applianceValueObjectIndex !== -1) {
                    optionObject[categoryName]["data"][applianceValueObjectIndex][
                      "child_data"
                    ][indexChild]["value"] = value;
                  }
                }
            }else{
              let object = {};
              let dropdownObjectIndex = mainObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === Number(optionId));
              let dropdownObjectIndexi = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === Number(optionId));
              
              if (value !== "Select") {
                if (dropdownObjectIndex !== -1) {
                  
                  object = JSON.parse(JSON.stringify(mainObject[categoryName]["data"][dropdownObjectIndex]));
                  
                  let dropdownValueOptions = object['child_data'].filter(
                    (x) => x.category_option_type === "dropdown"
                  );
                  let inputNumberText = object['child_data'].filter(
                    (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                  );
                  if(optionObject[categoryName]['data'].length){
                    let inputNumberTextValue = optionObject[categoryName]['data'][dropdownObjectIndexi]['child_data'].filter(
                      (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                    );
                    inputNumberText.forEach(function(v,i){
                      inputNumberText[i] = inputNumberTextValue[i]
                    });
                    
                  }
                  let getSelectedDropdown;
                  dropdownValueOptions.forEach(function(v,i){
                    if(v.id == Number(value)){
                      v['selected'] = true;
                      getSelectedDropdown = v;
                    }
                  });
                  inputNumberText.push(getSelectedDropdown);
                  optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = inputNumberText;
                }
              } else {
                if (dropdownObjectIndexi !== -1) {
                  optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = [];
                }
              }
            }
            break;
        case "loose-appliances":
          if(isPopup){                   
            let applianceObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (applianceObjectIndex !== -1) {
              let applianceValueObjectIndex = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
              if (applianceValueObjectIndex !== -1) {
                optionObject[categoryName]["data"][applianceValueObjectIndex][
                  "child_data"
                ][indexChild]["value"] = value;
              }
            }
          }else{
            let object = {};
            let dropdownObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === Number(optionId));
            let dropdownObjectIndexi = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === Number(optionId));
            
            if (value !== "Select") {
              if (dropdownObjectIndex !== -1) {
                
                object = JSON.parse(JSON.stringify(mainObject[categoryName]["data"][dropdownObjectIndex]));
                
                let dropdownValueOptions = object['child_data'].filter(
                  (x) => x.category_option_type === "dropdown"
                );
                let inputNumberText = object['child_data'].filter(
                  (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                );
                if(optionObject[categoryName]['data'].length){
                  let inputNumberTextValue = optionObject[categoryName]['data'][dropdownObjectIndexi]['child_data'].filter(
                    (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                  );
                  inputNumberText.forEach(function(v,i){
                    inputNumberText[i] = inputNumberTextValue[i]
                  });
                  
                }
                let getSelectedDropdown;
                dropdownValueOptions.forEach(function(v,i){
                  if(v.id == Number(value)){
                    v['selected'] = true;
                    getSelectedDropdown = v;
                  }
                });
                inputNumberText.push(getSelectedDropdown);
                optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = inputNumberText;
              }
            } else {
              if (dropdownObjectIndexi !== -1) {
                optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = [];
              }
            }
          }
          break;
          case "others":
          if (value) {
            if(categoryName == "sink"){
              optionObject[categoryName]["data"][0]['others_value'] = value;
            }else{
              let object = {};
              let checkboxObjectIndex = mainObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
  
              if (checkboxObjectIndex !== -1) {
                object = mainObject[categoryName]["data"][checkboxObjectIndex];
                object["category_option_value"] = true;
                object["others_value"] = value;
                let checkboxObjectValueIndex = optionObject[categoryName][
                  "data"
                ].findIndex((x) => x.category_option_id === optionId);
                if (checkboxObjectValueIndex !== -1) {
                  optionObject[categoryName]["data"][checkboxObjectValueIndex] =
                    object;
                } else {
                  optionObject[categoryName]["data"].push(object);
                }
              }
            }
            
          } else {
            if(categoryName == "sink"){
              optionObject[categoryName]["data"][0]['others_value'] = "";
            }
            let checkboxObjectIndex = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (checkboxObjectIndex !== -1) {
              optionObject[categoryName]["data"].splice(checkboxObjectIndex, 1);
            }
          }
          break;
        default:
      }
      return {
        ...state,
        dynamicFormValues: optionObject,
      };
    }

    case actionTypes.CLEAR_REQUEST_DATA: {
      return {
        ...state,
        myRequestListingData: [],
        myRequestListingLoading: false,
      };
    }

    case actionTypes.CHANGE_CLIENT_REQUEST_ID: {
      const { data } = action.payload;
      return {
        ...state,
        requestId: data,
      };
    }

    case actionTypes.CHANGE_ADD_COMMENT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        addCommentData: data,
      };
    }

    case actionTypes.CHANGE_DISCUSSION_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        discussionData: data,
      };
    }

    case actionTypes.CHANGE_ADD_COMMENT_DATA_JSON: {
      const { data } = action.payload;
      return {
        ...state,
        addCommentDataJson: data,
      };
    }

    case actionTypes.CHANGE_REQUEST_DETAIL: {
      const { data } = action.payload;
      return {
        ...state,
        requestDetailData: data,
      };
    }

    case actionTypes.HIDE_SUB_NAVBAR: {
      const { flag } = action.payload;
      return {
        ...state,
        hideNavbar: flag,
      };
    }

    case actionTypes.CLOSE_UPDATE_BOX: {
      const { data } = action.payload;
      return {
        ...state,
        commentIdUpdate: data,
      };
    }

    case actionTypes.CHANGE_REQUEST_LISTING_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        myRequestListingData: data,
      };
    }

    case actionTypes.MY_REQUEST_LISTING_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        myRequestListingLoading: flag,
      };
    }

    case actionTypes.CHANGE_START_FROM: {
      const { count } = action.payload;
      return {
        ...state,
        start: count,
      };
    }
    case actionTypes.CHANGE_HISTORY_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        historyData: data,
      };
    }
    case actionTypes.GET_DOOR_DETAILS_EDIT_INIT: {
      return {
        ...state,
        dynamicFormValues: {
          ...state.dynamicFormValues,
          "door-style": { option:{category_name: "Door Style",
          category_option_type: "dropdown",
          is_required: true}, data: [] },
        },
        doorDetailsFetchEdit: {
          ...state.doorDetailsFetchEdit,
          isFetchingDoorDetails: true,
          doorDetailsData: [],
          isFetchingDoorDetailsFailed: false,
          isFetchingDoorDetailsFailedMessage: ''
        }
      }
    }

    case actionTypes.GET_DOOR_DETAILS_EDIT_SUCCESS: {
      return {
        ...state,
        dynamicFormdata: {
          ...state.dynamicFormdata,
          'door-style': { ...state.dynamicFormdata["door-style"], data: action.payload?.length > 0 ? action.payload : [{ category_option_type: "dropdown" }] }
        },
        doorDetailsFetchEdit: {
          ...state.doorDetailsFetchEdit,
          isFetchingDoorDetails: false,
          doorDetailsData: action.payload,
          isFetchingDoorDetailsFailed: false,
          isFetchingDoorDetailsFailedMessage: ''
        }
      }
    }

    case actionTypes.GET_DOOR_DETAILS_EDIT_FAILURE: {
      return {
        ...state,
        doorDetailsFetchEdit: {
          ...state.doorDetailsFetchEdit,
          isFetchingDoorDetails: false,
          doorDetailsData: [],
          isFetchingDoorDetailsFailed: true,
          isFetchingDoorDetailsFailedMessage: action.payload
        }
      }
    }
    case actionTypes.UPDATE_CLIENT_STATUS_INIT: {
      return {
        ...state,
        clientRequestUpdateProcess: {
          ...state.clientRequestUpdateProcess,
          isUpdatingStatus: true,
          updatedStatusResponse: [],
          isUpdateRequestFailed: false,
          updateRequestFailedMessage: ""
        },
      }
    }
    case actionTypes.UPDATE_CLIENT_STATUS_SUCCESS: {
      return {
        ...state,
        clientRequestUpdateProcess: {
          ...state.clientRequestUpdateProcess,
          isUpdatingStatus: false,
          updatedStatusResponse: action.payload,
          isUpdateRequestFailed: false,
          updateRequestFailedMessage: ""
        },
      }
    }
    case actionTypes.UPDATE_CLIENT_STATUS_FAILURE: {
      return {
        ...state,
        clientRequestUpdateProcess: {
          ...state.clientRequestUpdateProcess,
          isUpdatingStatus: false,
          updatedStatusResponse: [],
          isUpdateRequestFailed: true,
          updateRequestFailedMessage: action.payload
        },
      }
    }
    case actionTypes.RESET_CLIENT_APPROVED_STATUS: {
      return {
        ...state,
        requestDetailData: "",
        clientRequestUpdateProcess: {
          ...state.clientRequestUpdateProcess,
          isUpdatingStatus: true,
          updatedStatusResponse: [],
          isUpdateRequestFailed: false,
          updateRequestFailedMessage: ""
        },
      }
    }
    case actionTypes.SET_UPDATE_STATUS_FLAG_MYREQUEST: {
      return {
        ...state,
        setUpdateStatusForMyRequest: true
      }
    }
    case actionTypes.CLEAR_UPDATE_STATUS_FLAG_MYREQUEST: {
      return {
        ...state,
        setUpdateStatusForMyRequest: false
      };
    }
    case actionTypes.UPDATE_USER_PROFILE_INFO_INIT: {
      return {
        ...state,
        userProfileUpdateProcess: {
          ...state.userProfileUpdateProcess,
          isUpdatingUserProfile: true,
          updatedProfileResponse: "",
          isUserProfileUpdateFailed: false,
          userProfileUpdateFailedMessage: ""
        }
      }
    }

    case actionTypes.UPDATE_USER_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        userProfileUpdateProcess: {
          ...state.userProfileUpdateProcess,
          isUpdatingUserProfile: false,
          updatedProfileResponse: action.payload,
          isUserProfileUpdateFailed: false,
          userProfileUpdateFailedMessage: ""
        }
      }
    }

    case actionTypes.UPDATE_USER_PROFILE_INFO_FAILURE: {
      return {
        ...state,
        userProfileUpdateProcess: {
          ...state.userProfileUpdateProcess,
          isUpdatingUserProfile: false,
          updatedProfileResponse: "",
          isUserProfileUpdateFailed: true,
          userProfileUpdateFailedMessage: action.payload
        }
      }
    }
    case generalActionsTypes.REMOVE_TOAST: {
      if (state.userProfileUpdateProcess.updatedProfileResponse !== "") {
        return {
          ...state,
          userProfileUpdateProcess: {
            ...state.userProfileUpdateProcess,
            updatedProfileResponse: "",
          },
          userResetPasswordProcess: {
            ...state.userResetPasswordProcess,
            resetPasswordSuccessResponse: ""
          }
        }
      }
      return state
    }

    case actionTypes.USER_RESET_PASSWORD_INIT: {
      return {
        ...state,
        userResetPasswordProcess: {
          ...state.userResetPasswordProcess,
          isResetingUserPassword: true,
          resetPasswordSuccessResponse: '',
          isResetPasswordProcessFailed: false,
          resetPasswordProcessFailedMessage: ""
        },
      }
    }
    case actionTypes.USER_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        userResetPasswordProcess: {
          ...state.userResetPasswordProcess,
          isResetingUserPassword: false,
          resetPasswordSuccessResponse: action.payload,
          isResetPasswordProcessFailed: false,
          resetPasswordProcessFailedMessage: ""
        },
      }
    }
    case actionTypes.USER_RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        userResetPasswordProcess: {
          ...state.userResetPasswordProcess,
          isResetingUserPassword: false,
          resetPasswordSuccessResponse: '',
          isResetPasswordProcessFailed: true,
          resetPasswordProcessFailedMessage: action.payload
        },
      }
    }
    case actionTypes.UPDATE_FILTERS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case actionTypes.UPDATE_FILTERS_DROPDONW_DATA: {
      return {
        ...state,
        filtersDropdownData: action.payload,
      }
    }
    case actionTypes.CLEAR_REQUEST_DETAIL_DATA: {
      return {
        ...state,
        requestDetailData: "",
      };
    }
    case actionTypes.FILE_UPLOAD_PROGRESS: {
      const { data } = action.payload;
      return {
        ...state,
        fileUploadPercentage: data,
      };
    }
    case actionTypes.SORT_BY_FILTER: {
      const { data } = action.payload;
      return {
        ...state,
        sortByFilter: data,
      };
    }
    case actionTypes.REDIRECT_TO_REVIEW_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        redirectToreview: data,
      };
    }
    case actionTypes.FILTER_NOT_CHANGE_BY_API: {
      const { data } = action.payload;
      return {
        ...state,
        filterNotChangeByApi: data,
      };
    }
    case actionTypes.ADD_COMMENT_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        addCommentLoading: flag,
      };
    }
    case actionTypes.CHANGE_SORT_DIR: {
      const { data } = action.payload;
      return {
        ...state,
        sortDir: data,
      };
    }
    case actionTypes.MARK_AS_SOLD_POPUP: {
      const { data } = action.payload;
      return {
        ...state,
        soldPopup: data,
      };
    }
    case actionTypes.SAVE_SOLD_FORM_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        soldForm: data,
      };
    }
    case actionTypes.SAVE_MARK_AS_SOLD_RES_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        soldRes: data,
      };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  redirectPage: (data) => ({
    type: actionTypes.REDIRECT_PAGE,
    payload: { data },
  }),
  changeSavedStartFrom: (data) => ({
    type: actionTypes.SAVE_START_FROM,
    payload: { data },
  }),
  changeCommentRatingAdded: (data) => ({
    type: actionTypes.COMMENT_RATING_ADDED,
    payload: { data },
  }),
  changeRequestApprove: (data) => ({
    type: actionTypes.REQUEST_APPOROVED,
    payload: { data },
  }),
  changeCopyAction: (data) => ({
    type: actionTypes.COPY_ACTION,
    payload: { data },
  }),
  changeCopyRequestId: (data) => ({
    type: actionTypes.CHANGE_COPY_ID,
    payload: { data },
  }),
  changeCount: (data) => ({
    type: actionTypes.SET_COUNT,
    payload: { data },
  }),
  changeActiveSectionData: (data) => ({
    type: actionTypes.SAVE_ACTIVE_REQUEST_SECTION_DATA,
    payload: { data },
  }),
  changeAllSectionProperlyLabeled: (data) => ({
    type: actionTypes.CHANGE_EDIT_REQUEST_ALL_SECTION_PROPERLY_LABELED,
    payload: { data },
  }),
  changeImagesData: (data) => ({
    type: actionTypes.CHANGE_IMAGES_DATA,
    payload: { data },
  }),
  changeEditRequestTitle: (data) => ({
    type: actionTypes.CHANGE_EDIT_REQUEST_TITLE,
    payload: { data },
  }),
  changeEditRequestType: (data) => ({
    type: actionTypes.CHANGE_EDIT_REQUEST_TYPE,
    payload: { data },
  }),
  changeEditRequestUpdatedMedia: (data) => ({
    type: actionTypes.CHANGE_EDIT_REQUEST_UPDATED_MEDIA,
    payload: { data },
  }),
  changeEditRequestDeletedMedia: (data) => ({
    type: actionTypes.CHANGE_EDIT_REQUEST_DELETED_MEDIA,
    payload: { data },
  }),
  changeEditRequestAdditionalNote: (data) => ({
    type: actionTypes.CHANGE_EDIT_REQUEST_ADDITIONAL_NOTE,
    payload: { data },
  }),
  changeEditRequestDueDate: (data) => ({
    type: actionTypes.CHANGE_EDIT_REQUEST_DUEDATE,
    payload: { data },
  }),
  setDynamicFormData: (data) => ({
    type: actionTypes.DYNAMIC_FORM_DATA,
    payload: { data },
  }),
  setDynamicFormValues: (data, values) => ({
    type: actionTypes.DYNAMIC_FORM_VALUES,
    payload: { data, values },
  }),
  changeDynamicFormValues: (
    value,
    categoryName,
    optionId,
    type,
    indexChild = null,
    isPopup = null,
  ) => ({
    type: actionTypes.CHANGE_DYNAMIC_FORM_VALUES,
    payload: { value, categoryName, optionId, type, indexChild, isPopup },
  }),
  updateStatusClient: (id, statusId, statusSlug, rating, clientAccessToken) => ({
    type: actionTypes.UPDATE_CLIENT_STATUS_INIT,
    payload: { id, statusId, statusSlug, rating, clientAccessToken },
  }),
  changeStartFrom: (count) => ({
    type: actionTypes.CHANGE_START_FROM,
    payload: { count },
  }),
  changeRequestDetail: (data) => ({
    type: actionTypes.CHANGE_REQUEST_DETAIL,
    payload: { data },
  }),
  changeDiscussionData: (data) => ({
    type: actionTypes.CHANGE_DISCUSSION_DATA,
    payload: { data },
  }),
  getDiscussionData: (id, clientAccessToken) => ({
    type: actionTypes.GET_DISCUSSION_DATA,
    payload: { id, clientAccessToken },
  }),
  changeAddCommentData: (data) => ({
    type: actionTypes.CHANGE_ADD_COMMENT_DATA,
    payload: { data },
  }),
  changeAddCommentDataJson: (data) => ({
    type: actionTypes.CHANGE_ADD_COMMENT_DATA_JSON,
    payload: { data },
  }),
  addCommentData: (id, comment, mediaUploads, mediaDelete, clientAccessToken) => ({
    type: actionTypes.ADD_COMMENT,
    payload: { id, comment, mediaUploads, mediaDelete, clientAccessToken },
  }),
  updateCommentData: (id, comment, mediaUploads, mediaDelete, clientAccessToken) => ({
    type: actionTypes.UPDATE_COMMENT_DATA,
    payload: { id, comment, mediaUploads, mediaDelete, clientAccessToken },
  }),
  getRequestDetail: (requestId, clientAccessToken) => ({
    type: actionTypes.GET_REQUEST_DETAIL,
    payload: { requestId, clientAccessToken },
  }),
  changeRequestId: (data) => ({
    type: actionTypes.CHANGE_CLIENT_REQUEST_ID,
    payload: { data },
  }),
  loadMoreRequest: (data) => ({
    type: actionTypes.LOAD_MORE_REQUEST,
    payload: { data },
  }),
  getNeesApprovalListing: ( filters, sortByFilter, sortDir, start, length, clientAccessToken) => ({
    type: actionTypes.DESIGN_DELIVERED_LISTING,
    payload: { filters, sortByFilter, sortDir, start, length, clientAccessToken },
  }),
  getDraftListing: (start, length, clientAccessToken) => ({
    type: actionTypes.DRAFT_LISTING,
    payload: { start, length, clientAccessToken },
  }),
  changeRequestListingData: (data) => ({
    type: actionTypes.CHANGE_REQUEST_LISTING_DATA,
    payload: { data },
  }),
  updateFiltersDropdonwData: (data) => ({
    type: actionTypes.UPDATE_FILTERS_DROPDONW_DATA,
    payload: { ...data },
  }),
  myRequestListingLoading: (flag) => ({
    type: actionTypes.MY_REQUEST_LISTING_LOADING,
    payload: { flag },
  }),
  hideSubNavbar: (flag) => ({
    type: actionTypes.HIDE_SUB_NAVBAR,
    payload: { flag },
  }),
  closeUpdateCommentBox: (data) => ({
    type: actionTypes.CLOSE_UPDATE_BOX,
    payload: { data },
  }),
  deleteRequest: (id, section, clientAccessToken) => ({
    type: actionTypes.DELETE_REQUEST,
    payload: { id, section, clientAccessToken },
  }),
  deleteComment: (id, clientAccessToken) => ({
    type: actionTypes.DELETE_COMMENT,
    payload: { id, clientAccessToken },
  }),
  updateRequestDetail: (data, clientAccessToken) => ({
    type: actionTypes.UPDATE_REQUEST_DETAIL,
    payload: { data, clientAccessToken },
  }),
  changeRemovedImages: (data) => ({
    type: actionTypes.CHANGE_REMOVED_IMAGES,
    payload: { data },
  }),
  copyClientRequest: (id, clientAccessToken) => ({
    type: actionTypes.COPY_CLIENT_REQUEST,
    payload: { id, clientAccessToken },
  }),
  submitClientRatingComment: (data, requestId, clientAccessToken) => ({
    type: actionTypes.CLIENT_RATING_COMMENT,
    payload: { data, clientAccessToken, requestId },
  }),
  changeHistoryData: (data) => ({
    type: actionTypes.CHANGE_HISTORY_DATA,
    payload: { data },
  }),
  getHistoryData: (id, clientAccessToken) => ({
    type: actionTypes.GET_HISTORY_DATA,
    payload: { id, clientAccessToken },
  }),
  getLatestDoorDetailsEdit: (doorId, clientAccessToken) => ({
    type: actionTypes.GET_DOOR_DETAILS_EDIT_INIT,
    payload: { doorId, clientAccessToken }
  }),
  setUpdateStatusFlagForMyRequest: (flag) => ({
    type: actionTypes.SET_UPDATE_STATUS_FLAG_MYREQUEST,
    payload: { flag },
  }),
  clearUpdateStatusFlagMyRequest: () => ({
    type: actionTypes.CLEAR_UPDATE_STATUS_FLAG_MYREQUEST,
  }),
  resetApproveStatus: () => ({
    type: actionTypes.RESET_CLIENT_APPROVED_STATUS,
  }),
  updateUserProfileDetails: (email, fullName, clientAccessToken, userPhoneNumber, userCompany) => ({
    type: actionTypes.UPDATE_USER_PROFILE_INFO_INIT,
    payload: { email, fullName, userPhoneNumber, userCompany, clientAccessToken }
  }),

  resetUserPassword: (email, clientAccessToken) => ({
    type: actionTypes.USER_RESET_PASSWORD_INIT,
    payload: { email, clientAccessToken }
  }),
  updateFilters: (data) => ({
    type: actionTypes.UPDATE_FILTERS,
    payload: {
      filters: data,
    },
  }),
  clearRequestDetailData: () => ({
    type: actionTypes.CLEAR_REQUEST_DETAIL_DATA,
  }),
  changeFileUploadPercentage: (data) => ({
    type: actionTypes.FILE_UPLOAD_PROGRESS,
    payload: { data },
  }),
  changeSortByFilter: (data) => ({
    type: actionTypes.SORT_BY_FILTER,
    payload: { data },
  }),
  changeRedirectPageReview: (data) => ({
    type: actionTypes.REDIRECT_TO_REVIEW_PAGE,
    payload: { data },
  }),
  getOpenRequestListing: ( filters, sortByFilter,sortDir, start, length, clientAccessToken) => ({
    type: actionTypes.CLIENT_OPEN_REQUESTS,
    payload: { filters, sortByFilter,sortDir, start, length, clientAccessToken },
  }),
  getWipRequestListing: ( filters, sortByFilter,sortDir, start, length, clientAccessToken) => ({
    type: actionTypes.CLIENT_WIP_REQUESTS,
    payload: { filters, sortByFilter,sortDir, start, length, clientAccessToken },
  }),
  getNeedsInfoRequestListing: ( filters, sortByFilter,sortDir, start, length, clientAccessToken) => ({
    type: actionTypes.CLIENT_NEEDS_INFORMATION_REQUESTS,
    payload: { filters, sortByFilter,sortDir, start, length, clientAccessToken },
  }),
  getClosedRequestListing: ( filters, sortByFilter,sortDir, start, length, clientAccessToken) => ({
    type: actionTypes.CLIENT_CLOSED_REQUESTS,
    payload: { filters, sortByFilter,sortDir, start, length, clientAccessToken },
  }),
  requestPrioritize: (id, section, clientAccessToken) => ({
    type: actionTypes.REQUEST_PRIORITIZE,
    payload: { id, section, clientAccessToken },
  }),
  requestDePrioritize: (id, clientAccessToken) => ({
    type: actionTypes.REQUEST_DEPRIORITIZE,
    payload: { id, clientAccessToken },
  }),
  getRequestListingPrioritize: (
    start,
    length,
    clientAccessToken
  ) => ({
    type: actionTypes.MY_REQUEST_PRIORITIZE_LISTING,
    payload: { start, length, clientAccessToken },
  }),
  sortPrioritizeRequest: (id, order, clientAccessToken) => ({
    type: actionTypes.SORT_PRIORITIZE_REQUEST,
    payload: { id, order, clientAccessToken },
  }),
  setFilterNotChangeByApi: (data) => ({
    type: actionTypes.FILTER_NOT_CHANGE_BY_API,
    payload: { data },
  }),
  getAllRequestListing: ( filters, sortByFilter,sortDir, start, length, clientAccessToken) => ({
    type: actionTypes.CLIENT_ALL_REQUESTS,
    payload: { filters, sortByFilter,sortDir, start, length, clientAccessToken },
  }),
  addCommentLoading: (flag) => ({
    type: actionTypes.ADD_COMMENT_LOADING,
    payload: { flag },
  }),
  rollBackRevisionRequest: (data,requestId,clientAccessToken) => ({
    type: actionTypes.ROLL_BACK_REVISION_REQUEST,
    payload: { data,requestId,clientAccessToken },
  }),
  changeSortDir: (data) => ({
    type: actionTypes.CHANGE_SORT_DIR,
    payload: { data },
  }),
  markAsSoldPopup: ({open=false,editSold=false}) => ({
    type: actionTypes.MARK_AS_SOLD_POPUP,
    payload: { data:{ open, editSold } },
  }),
  getSoldFormData: (requestId, clientAccessToken) => ({
    type: actionTypes.MARK_AS_SOLD_DEFAULT_DATA,
    payload: { requestId, clientAccessToken },
  }),
  saveSoldFormDefaultData: (data) => ({
    type: actionTypes.SAVE_SOLD_FORM_DATA,
    payload: { data },
  }),
  markAsSoldFormData: (requestId, clientAccessToken,data) => ({
    type: actionTypes.MARK_AS_SOLD_FORM_DATA,
    payload: { requestId, clientAccessToken, data },
  }),
  saveMarkAsSoldResData: (data) => ({
    type: actionTypes.SAVE_MARK_AS_SOLD_RES_DATA,
    payload: { data },
  }),
  updateMarkAsSoldData: (requestId, clientAccessToken,data) => ({
    type: actionTypes.MARK_AS_SOLD_DATA_UPDATE,
    payload: { requestId, clientAccessToken, data },
  }),
};

export function* saga() {
  
  yield takeLatest(
    actionTypes.DESIGN_DELIVERED_LISTING,
    getNeesApprovalListingSaga
  );
  yield takeLatest(actionTypes.GET_REQUEST_DETAIL, getRequestDetailSaga);

  yield takeLatest(actionTypes.MARK_AS_SOLD_DEFAULT_DATA, getSoldFormSaga);
  yield takeLatest(actionTypes.MARK_AS_SOLD_FORM_DATA, createMarkAsSold);
  yield takeLatest(actionTypes.MARK_AS_SOLD_DATA_UPDATE, updateMarkAsSold);

  yield takeLatest(actionTypes.DRAFT_LISTING, getDraftListingSaga);
  yield takeLatest(actionTypes.DELETE_REQUEST, deleteRequestSaga);
  yield takeLatest(actionTypes.ADD_COMMENT, addCommentDataSaga);
  yield takeLatest(actionTypes.UPDATE_COMMENT_DATA, updateCommentDataSaga);
  yield takeLatest(actionTypes.GET_DISCUSSION_DATA, getDiscussionDataSaga);
  yield takeLatest(actionTypes.DELETE_COMMENT, deleteCommentDataSaga);
  yield takeLatest(actionTypes.UPDATE_CLIENT_STATUS_INIT, updateStatusClientSaga);
  yield takeLatest(actionTypes.UPDATE_REQUEST_DETAIL, updateRequestDetailSaga);
  yield takeLatest(actionTypes.COPY_CLIENT_REQUEST, copyClientRequestSaga);
  yield takeLatest(
    actionTypes.CLIENT_RATING_COMMENT,
    submitClientRatingCommentSaga
  );
  yield takeLatest(actionTypes.GET_HISTORY_DATA, getHistoryDataSaga);
  yield takeLatest(actionTypes.GET_DOOR_DETAILS_EDIT_INIT, getLatestDoorDetailsSaga);
  yield takeLatest(actionTypes.UPDATE_USER_PROFILE_INFO_INIT, updateUserProfileDetailsWorkerSaga)
  yield takeLatest(actionTypes.USER_RESET_PASSWORD_INIT, userResetPasswordWorkerSaga)
  yield takeLatest(
    actionTypes.CLIENT_OPEN_REQUESTS,
    getOpenRequestListingSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_WIP_REQUESTS,
    getWipRequestListingSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_NEEDS_INFORMATION_REQUESTS,
    getNeedsInfoRequestListingSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_CLOSED_REQUESTS,
    getClosedRequestListingSaga
    );
  yield takeLatest(actionTypes.REQUEST_PRIORITIZE, requestPrioritizeSaga)
  yield takeLatest(actionTypes.REQUEST_DEPRIORITIZE, requestDePrioritizeSaga)
  yield takeLatest(
    actionTypes.MY_REQUEST_PRIORITIZE_LISTING,
    getRequestListingPrioritizeSaga
  );
  yield takeLatest(actionTypes.SORT_PRIORITIZE_REQUEST, sortPrioritizeRequestSaga);
  yield takeLatest(
    actionTypes.CLIENT_ALL_REQUESTS,
    getAllRequestListingSaga
  );
  yield takeLatest(
    actionTypes.ROLL_BACK_REVISION_REQUEST,
    rollBackRevisionRequestSaga
  );
}

function* getOpenRequestListingSaga(action) {
  let {
    filters,
    sortByFilter,
    sortDir,
    clientAccessToken,
    start,
    length,
  } = action.payload;
  const { myRequestListingData } = yield select((state) => state.myRequest);
  yield all([put(actions.myRequestListingLoading(true))]);
  const { user } = yield select((state) => state.auth);
  const filterParams = {
    ...filters,
    client_id: user.id,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_LISTING_YOURQUEUE_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (start) {
          let temparr = myRequestListingData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "My Request Your Queue",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "My Request Your Queue",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getNeesApprovalListingSaga(action) {
  let { filters, sortByFilter, sortDir, start, length, clientAccessToken } = action.payload;
  yield all([put(actions.myRequestListingLoading(true))]);
  const { user } = yield select((state) => state.auth);
  const { myRequestListingData } = yield select((state) => state.myRequest);
  const filterParams = {
    ...filters,
    client_id: user.id,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_LISTING_NEEDS_APPROVAL_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (start) {
          let temparr = myRequestListingData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "My Request Design Delivered",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "My Request Design Delivered",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getDraftListingSaga(action) {
  let { start, length, clientAccessToken } = action.payload;
  yield all([put(actions.myRequestListingLoading(true))]);
  const { myRequestListingData } = yield select((state) => state.myRequest);
  const filterParams = {
    start: start,
    length: length,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_LISTING_DRAFT_URL"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (start) {
          let temparr = myRequestListingData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
          ]);
        } else {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
          ]);
        }
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "My Request Draft",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "My Request Draft",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getRequestDetailSaga(action) {
  let { requestId, clientAccessToken } = action.payload;
  yield all([put(actions.myRequestListingLoading(true))]);
  // const { myRequestListingData } = yield select((state) => state.myRequest);
  const filterParams = {
    request_id: requestId,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_DETAIL_VIEW"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.changeRequestDetail(responseData.data)),
        ]);
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Request Detail View",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Request Detail View",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getSoldFormSaga(action) {
  let { requestId, clientAccessToken } = action.payload;
  yield all([put(actions.myRequestListingLoading(true))]);
  const filterParams = {
    client_request_id: requestId,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("MARK_AS_SOLD_DEFAULT_DATA"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.saveSoldFormDefaultData(responseData.data)),
        ]);
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Mark as Sold Request",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Mark as Sold Request",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* createMarkAsSold(action) {
  let { requestId, clientAccessToken, data } = action.payload;
  yield all([put(actions.myRequestListingLoading(true))]);
  // const { myRequestListingData } = yield select((state) => state.myRequest);
  const postData = {
    ...data,
    client_request_id: requestId,
  };

  try {
    const response = yield call(
      postApiToken,
      apiUrl("MARK_AS_SOLD_FORM_DATA"),
      clientAccessToken,
      queryString.stringify(postData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.saveMarkAsSoldResData(responseData.data)),
        ]);
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Mark as Sold Request",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Mark as Sold Request",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* updateMarkAsSold(action) {
  let { requestId, clientAccessToken, data } = action.payload;
  yield all([put(actions.myRequestListingLoading(true))]);
  // const { myRequestListingData } = yield select((state) => state.myRequest);
  const postData = {
    ...data,
    client_request_id: requestId,
  };
  const { id } = data

  try {
    const response = yield call(
      putApiTokenJson,
      apiUrl("MARK_AS_SOLD_DATA_UPDATE")+id,
      JSON.stringify(postData),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.saveMarkAsSoldResData(responseData.data)),
        ]);
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Mark as Sold Request",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Mark as Sold Request",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* deleteRequestSaga(action) {
  let { id, section, clientAccessToken } = action.payload;
  let submitData = {
    request_id: id,
  };
  yield all([put(actions.myRequestListingLoading(true))]);
  const { filters, sortByFilter, sortDir, start, length } = yield select(
    (state) => state.myRequest
  );
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_DELETE_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (section === "draft") {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.getDraftListing(0, initialState.length, clientAccessToken)), //length => 10
          ]);
        }else if(section === "prioritized"){
          yield put(actions.myRequestListingLoading(false));
          yield put(actions.getRequestListingPrioritize(
            0,
            initialState.length,
            clientAccessToken
          ));
        }else if(section === "all-requests"){
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(
              actions.getAllRequestListing(
                filters,
                sortByFilter,
                sortDir,
                0,
                start+length,  //10
                clientAccessToken
              )
            ),
          ]);
        } else {
          
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(
              actions.getOpenRequestListing(
                filters,
                sortByFilter,
                sortDir,
                0,
                start+length,  //10
                clientAccessToken
              )
            ),
          ]);
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Delete",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Delete",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* addCommentDataSaga(action) {
  let { id, comment, mediaUploads, mediaDelete, clientAccessToken } = action.payload;
  let commentMedia = new URLSearchParams();
  commentMedia.set("client_request_id", id)
  commentMedia.set("note", comment)
  mediaUploads.forEach(media => commentMedia.append("upload_media[]", media.media_id))
  mediaDelete.forEach(media => commentMedia.append("deleted_media[]", media.media_id))
  //const { requestId } = yield select((state) => state.myRequest);
  const { user } = yield select((state) => state.auth);
  yield all([put(actions.myRequestListingLoading(true)),
    put(actions.addCommentLoading(true))
  ]);
  let tempApiUrl;
  if (user.group_id == 3) {
    tempApiUrl = "DESIGNER_REQUEST_ADD_COMMENT_URL";
  } else {
    tempApiUrl = "CLIENT_REQUEST_ADD_COMMENT_URL";
  }
  try {
    const response = yield call(
      postApiToken,
      apiUrl(tempApiUrl),
      clientAccessToken,
      commentMedia,
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.addCommentLoading(false)),
          put(actions.changeAddCommentData("")),
          put(actions.getDiscussionData(id, clientAccessToken)),
          put(actions.getHistoryData(id, clientAccessToken)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Add Comment",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Add Comment",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* updateCommentDataSaga(action) {
  let { id, comment, mediaUploads, mediaDelete, clientAccessToken } = action.payload;
  let commentMedia = new URLSearchParams();
  commentMedia.set("comment_id", id)
  commentMedia.set("note", comment)
  mediaUploads.forEach((media, index) => commentMedia.append(`upload_media[${index}][id]`, media.media_id))
  mediaDelete.forEach((media, index) => commentMedia.append(`deleted_media[${index}][id]`, media.media_id))

  yield all([put(actions.myRequestListingLoading(true))]);
  const { requestId } = yield select((state) => state.myRequest);
  const { requestIdDesigner } = yield select((state) => state.designerRequest);

  const { user } = yield select((state) => state.auth);
  let tempApiUrl, tempId;
  if (user.group_id == 3) {
    tempApiUrl = "DESIGNER_REQUEST_UPDATE_COMMENT_URL";
    tempId = requestIdDesigner;
  } else {
    tempApiUrl = "CLIENT_REQUEST_UPDATE_COMMENT_URL";
    tempId = requestId;
  }
  try {
    const response = yield call(
      postApiToken,
      apiUrl(tempApiUrl),
      clientAccessToken,
      commentMedia
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.closeUpdateCommentBox(true)),
          put(actions.getDiscussionData(tempId, clientAccessToken)),
          put(actions.getHistoryData(tempId, clientAccessToken)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Comment",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Update Comment",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getDiscussionDataSaga(action) {
  let { id, clientAccessToken } = action.payload;
  yield all([put(actions.myRequestListingLoading(true))]);
  const filterParams = {
    request_id: id,
  };
  const { user } = yield select((state) => state.auth);
  let tempApiUrl;
  if (user.group_id == 3) {
    tempApiUrl = "DESIGNER_REQUEST_GET_DISCUSSION_URL";
  } else {
    tempApiUrl = "CLIENT_REQUEST_GET_DISCUSSION_URL";
  }

  try {
    const response = yield call(
      getApiToken,
      apiUrl(tempApiUrl),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.changeDiscussionData(responseData.data)),
        ]);
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "DISCUSSION DATA",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "DISCUSSION DATA",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* deleteCommentDataSaga(action) {
  let { id, clientAccessToken } = action.payload;

  let submitData = {
    comment_id: id,
  };
  const { user } = yield select((state) => state.auth);
  const { requestIdDesigner } = yield select((state) => state.designerRequest);

  yield all([put(actions.myRequestListingLoading(true))]);
  const { requestId } = yield select((state) => state.myRequest);
  let tempApiUrl, tempRequestId;

  if (user.group_id == 3) {
    tempApiUrl = "DESIGNER_REQUEST_DELETE_COMMENT_URL";
    tempRequestId = requestIdDesigner;
  } else {
    tempApiUrl = "CLIENT_REQUEST_DELETE_COMMENT_URL";
    tempRequestId = requestId;
  }
  try {
    const response = yield call(
      postApiToken,
      apiUrl(tempApiUrl),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.getDiscussionData(tempRequestId, clientAccessToken)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Delete Comment",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Delete Comment",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* updateStatusClientSaga(action) {
  let { id, statusId, statusSlug, rating, clientAccessToken } = action.payload;
  let submitData = {
    request_status_id: statusId,
    request_id: id,
  };

  yield all([put(actions.myRequestListingLoading(true))]);
  const { requestId } = yield select((state) => state.myRequest);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_UPDATE_STATUS_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if(statusSlug == 'approved' && !rating){
          yield all([put(actions.changeRedirectPageReview(true))]);
        }else if(statusSlug == 'approved' && rating){
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.getRequestDetail(requestId, clientAccessToken)),
          ]);
        }
        yield put({
          type: actionTypes.UPDATE_CLIENT_STATUS_SUCCESS,
          payload: { ...responseData, statusChangedTo: statusSlug, requestedId: id },
        })
        yield put(
          generalActions.addToast(
            "Update Status",
            "Request Updated Successfully",
            "success",
            uid()
          )
        );

        if (statusSlug == "approved") {
          yield all([
            put(actions.myRequestListingLoading(false)),
          ]);
        } else {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.getRequestDetail(requestId, clientAccessToken)),
          ]);
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Status",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Update Status",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* updateRequestDetailSaga(action) {
  let { data, clientAccessToken } = action.payload;

  yield all([put(actions.myRequestListingLoading(true))]);

  try {
    const response = yield call(
      putApiTokenJson,
      apiUrl("CLIENT_REQUEST_DETAIL_UPDATE_URL"),
      JSON.stringify(data),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.redirectPage(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Request Update",
            "Request data updated successfully",
            "success",
            uid()
          )
        );
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Request Update",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Request Update",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* copyClientRequestSaga(action) {
  let { id, clientAccessToken } = action.payload;

  let submitData = {
    request_id: id,
  };
  yield all([put(actions.myRequestListingLoading(true))]);

  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_COPY_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.changeCopyAction(true)),
          put(actions.changeCopyRequestId(responseData.data.request_id)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Copy",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Copy",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* submitClientRatingCommentSaga(action) {
  let { data, clientAccessToken, requestId } = action.payload;
  let submitData = {
    request_id: requestId,
    rating: data.rating,
    note: data.comment,
  };
  yield all([put(actions.myRequestListingLoading(true))]);

  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_COMMENT_RATING_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {

      yield all([
        put(actions.myRequestListingLoading(false)),
        put(actions.changeCommentRatingAdded(true)),
      ]);
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Rating Comment",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Rating Comment",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getHistoryDataSaga(action) {
  let { id, clientAccessToken } = action.payload;
  yield all([put(actions.myRequestListingLoading(true))]);
  const filterParams = {
    request_id: id,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_HISTORY_DATA_URL"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(actions.changeHistoryData(responseData.data)),
        ]);
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get History Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Get History Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}
function* getLatestDoorDetailsSaga(action) {
  let { doorId, clientAccessToken } = action.payload;
  try {
    let response = yield call(fetchDoorDetails,
      apiUrl("CLIENT_GET_DOOR_STYLE"),
      doorId, clientAccessToken
    )
    let responseData = yield call([response, response.json]);
    if (response) {
      yield put({
        type: actionTypes.GET_DOOR_DETAILS_EDIT_SUCCESS,
        payload: responseData?.data
      })
    }
  } catch (e) {
    yield put({
      type: actionTypes.GET_DOOR_DETAILS_EDIT_FAILURE,
      payload: e
    })
  }
}
function* updateUserProfileDetailsWorkerSaga(action) {
  const { email, fullName, userPhoneNumber, userCompany, clientAccessToken } = action.payload;
  try {
    const response = yield call(updateUserDetails, email, fullName, clientAccessToken, userPhoneNumber, userCompany,);
    if (response) {
      yield put({
        type: actionTypes.UPDATE_USER_PROFILE_INFO_SUCCESS,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_USER_PROFILE_INFO_FAILURE,
      payload: error.response.data.message,
    });
  }
}

function* userResetPasswordWorkerSaga(action) {
  const { email, clientAccessToken } = action.payload
  try {
    const response = yield call(resetUserPassword, email, clientAccessToken)
    if (response.status === 200) {
      yield put({
        type: actionTypes.USER_RESET_PASSWORD_SUCCESS,
        payload: response.data.message
      })
    } else {
      yield put({
        type: actionTypes.USER_RESET_PASSWORD_FAILURE,
        payload: response.data.message
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.USER_RESET_PASSWORD_FAILURE,
      payload: error.response.data.message
    })
  }

}

function* requestPrioritizeSaga(action) {
  let { id, section, clientAccessToken } = action.payload;

  let submitData = {
    request_id: id,
  };
  yield all([put(actions.myRequestListingLoading(true))]);
  const { filters, sortByFilter, sortDir } = yield select(
    (state) => state.myRequest
  );
  try {
    const response = yield call(
      postApiToken,
      apiUrl("REQUEST_PRIORITIZE_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if((section === "open-requests")){
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(
              actions.getOpenRequestListing(
                filters,
                sortByFilter,
                sortDir,
                0,
                initialState.length,  //10
                clientAccessToken
              )
            ),
          ]);
        }else{
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(
              actions.getAllRequestListing(
                filters,
                sortByFilter,
                sortDir,
                0,
                initialState.length,  //10
                clientAccessToken
              )
            ),
          ]);
        }
          
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Request Prioritize",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Request Prioritize",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* requestDePrioritizeSaga(action) {
  let { id, clientAccessToken } = action.payload;

  let submitData = {
    request_id: id,
  };
  yield all([put(actions.myRequestListingLoading(true))]);
  // const { filters, sortByFilter } = yield select(
  //   (state) => state.myRequest
  // );
  try {
    const response = yield call(
      postApiToken,
      apiUrl("REQUEST_DEPRIORITIZE_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(
              actions.getRequestListingPrioritize(
                0,
                initialState.length,  //10
                clientAccessToken
              )
            ),
          ]);
          yield put(
            generalActions.addToast(
              "Request Deprioritize",
              responseData.message,
              "success",
              uid()
            )
          );
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Request Deprioritize",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Request Deprioritize",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getRequestListingPrioritizeSaga(action) {
  let {
    start,
    length,
    clientAccessToken,
  } = action.payload;
  const { myRequestListingData } = yield select((state) => state.myRequest);
  yield all([put(actions.myRequestListingLoading(true))]);
  //const { user } = yield select((state) => state.auth);
  const filterParams = {
    start: start,
    length: length,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("REQUEST_PRIORITIZE_LIST_URL"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (start) {
          let temparr = myRequestListingData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
          ]);
        } else {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
          ]);
        }
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "My Request Prioritized",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "My Request Prioritized",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* sortPrioritizeRequestSaga(action) {
  let { id, order, clientAccessToken } = action.payload;
  const { start, length, filters, sortByFilter, sortDir } = yield select((state) => state.myRequest);
  const { user } = yield select((state) => state.auth);
  let submitData = {
    request_id: id,
    order: order,
  };
  yield all([put(actions.myRequestListingLoading(true))]);

  try {
    const response = yield call(
      postApiToken,
      apiUrl("REQUEST_PRIORITIZE_SORT_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if(user.is_all_tab_prioritisation_enabled){
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.getAllRequestListing(
              filters,
              sortByFilter,
              sortDir,
              0,
              start+length,
              clientAccessToken
            ))
          ]);
          
        }else{
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.getRequestListingPrioritize(
              0,
              start+length,
              clientAccessToken
            ))
          ]);
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Request Prioritize",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Request Prioritize",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getWipRequestListingSaga(action) {
  let {
    filters,
    sortByFilter,
    sortDir,
    clientAccessToken,
    start,
    length,
  } = action.payload;
  const { myRequestListingData } = yield select((state) => state.myRequest);
  yield all([put(actions.myRequestListingLoading(true))]);
  const { user } = yield select((state) => state.auth);
  const filterParams = {
    ...filters,
    client_id: user.id,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_LISTING_WIP_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (start) {
          let temparr = myRequestListingData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "My Request WIP",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "My Request WIP",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getNeedsInfoRequestListingSaga(action) {
  let {
    filters,
    sortByFilter,
    sortDir,
    clientAccessToken,
    start,
    length,
  } = action.payload;
  const { myRequestListingData } = yield select((state) => state.myRequest);
  yield all([put(actions.myRequestListingLoading(true))]);
  const { user } = yield select((state) => state.auth);
  const filterParams = {
    ...filters,
    client_id: user.id,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_LISTING_NEEDS_INFO_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (start) {
          let temparr = myRequestListingData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "My Request WIP",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "My Request WIP",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getClosedRequestListingSaga(action) {
  let {
    filters,
    sortByFilter,
    sortDir,
    clientAccessToken,
    start,
    length,
  } = action.payload;
  const { myRequestListingData } = yield select((state) => state.myRequest);
  yield all([put(actions.myRequestListingLoading(true))]);
  const { user } = yield select((state) => state.auth);
  const filterParams = {
    ...filters,
    client_id: user.id,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_LISTING_CLOSED_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (start) {
          let temparr = myRequestListingData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "My Request WIP",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "My Request WIP",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* getAllRequestListingSaga(action) {
  let {
    filters,
    sortByFilter,
    sortDir,
    clientAccessToken,
    start,
    length,
  } = action.payload;
  const { myRequestListingData } = yield select((state) => state.myRequest);
  yield all([put(actions.myRequestListingLoading(true))]);
  const { user } = yield select((state) => state.auth);
  const filterParams = {
    ...filters,
    client_id: user.id,
    start: start,
    length: length,
    sort_by: sortByFilter,
    sort_dir: sortDir,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_LISTING_ALL_URL"),
      queryString.stringify(filterParams, {arrayFormat: 'bracket'}),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (start) {
          let temparr = myRequestListingData.datatable.data;
          for (var i = 0; i < responseData.data.datatable.data.length; i++) {
            temparr.push(responseData.data.datatable.data[i]);
          }
          responseData.data.datatable.data = temparr;
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        } else {
          yield all([
            put(actions.myRequestListingLoading(false)),
            put(actions.changeRequestListingData(responseData.data)),
            put(actions.updateFiltersDropdonwData({
              assign_to: responseData.data?.assign_to || [],
              created_by: responseData.data?.created_by || [],
              request_status: responseData.data?.request_status || [],
              request_types: responseData.data?.request_types || [],
            })),
          ]);
        }
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);

        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client All Request",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client All Request",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}

function* rollBackRevisionRequestSaga(action) {
  let { data, requestId, clientAccessToken } = action.payload;
  //const { user } = yield select((state) => state.auth);
  yield all([put(actions.myRequestListingLoading(true)),
  ]);
  
  try {
    const response = yield call(
      postApiTokenFormData,
      apiUrl("ROLL_BACK_REVISION_REQUEST_URL"),
      clientAccessToken,
      data
    );
    
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.myRequestListingLoading(false)),
          put(
            generalActions.addToast(
              "Roll Back Revision Due to Design Error",
              responseData.message,
              "success",
              uid()
            )
          ),
          put(designerActions.getRequestDetail(requestId, clientAccessToken)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(authActions.logout());
      } else {
        yield all([put(actions.myRequestListingLoading(false))]);
        yield put(
          generalActions.addToast(
            "Add Comment",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.myRequestListingLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Add Comment",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.myRequestListingLoading(false))]);
  }
}