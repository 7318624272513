/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import './index.scss';
import DatePicker from 'react-date-picker';
import { Formik } from 'formik';
import { Input, PhoneInput, Select } from '../../ReusableComponents';
import { useDispatch, useSelector } from 'react-redux';
import { actions as myRequestActions } from "../../../redux/reducers/MyRequestReducer";
import { useCookies } from "react-cookie";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { getFormattedDate } from '../../../Utils/CommonHelper';

export const sFormFields = {
  cabinet_brand_id: {
    key: "cabinet_brand_id",
    type: "text",
    className: "form-control",
    name: "cabinet_brand_id",
    placeholder: "Select Cabinet Brand",
    label: "Cabinet Brand",
    htmlFor: "cabinet_brand_id",
    required: false,
    selectkeyObj: {
      value: "id",
      text: "name"
    }
  },
  door_style_id: {
    key: "door_style_id",
    type: "text",
    className: "form-control",
    name: "door_style_id",
    placeholder: "Select Door Style",
    label: "Door Style",
    htmlFor: "door_style_id",
    required: false,
    selectkeyObj: {
      value: "id",
      text: "name"
    }
  },
  order_value: {
    key: "order_value",
    type: "number",
    className: "form-control",
    name: "order_value",
    placeholder: "Enter Order Value",
    label: "Order Value",
    htmlFor: "order_value",
    required: false,
  },
  customer_name: {
    key: "customer_name",
    type: "text",
    className: "form-control",
    name: "customer_name",
    placeholder: "Enter Name",
    label: "Name",
    htmlFor: "customer_name",
    required: false,
  },
  email: {
    key: "email",
    type: "text",
    className: "form-control",
    name: "email",
    placeholder: "Enter Email address",
    label: "Email",
    htmlFor: "email",
    required: false,
  },
  phone: {
    key: "phone",
    type: "text",
    className: "form-control fullwidth",
    name: "phone",
    placeholder: "Enter a valid 10 digit number",
    label: "Phone",
    htmlFor: "phone",
    required: false,
    disableDropdown: false,
    maxlength: "11",
    from: false
  },
  street: {
    key: "street",
    type: "text",
    className: "form-control",
    name: "street",
    placeholder: "Enter Street Address",
    label: "Street",
    htmlFor: "street",
    required: false,
  },
  city: {
    key: "city",
    type: "text",
    className: "form-control",
    name: "city",
    placeholder: "Enter City",
    label: "City",
    htmlFor: "city",
    required: false,
  },
  state: {
    key: "state",
    type: "text",
    className: "form-control",
    name: "state",
    placeholder: "Enter State",
    label: "State",
    htmlFor: "state",
    required: false,
  },
  zipcode: {
    key: "zipcode",
    type: "text",
    className: "form-control",
    name: "zipcode",
    placeholder: "Enter Zipcode",
    label: "Zipcode",
    htmlFor: "zipcode",
    required: false,
  }
}

function SoldForm(props) {
  const { data } = props;
  const { sold } = data
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { soldPopup, soldForm = {}, soldRes } = useSelector((state) => state.myRequest);
  const { dataTable = {} } = soldForm;

  const [formData, setFormData] = useState({
    cabinet_brand_id: undefined,
    door_style_id: "",
    order_date: new Date(),
    order_value: "",
    customer_name: "",
    email: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    zipcode: ""
  });

  useEffect(() => {
    if (soldRes?.dataTable?.id) {
      handlePopupClose();
      dispatch(myRequestActions.getRequestDetail(
        props.match.params.id,
        cookies.clientAccessToken
      ));
    }
  }, [soldRes])

  var phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const soldFormValidation = Yup.object().shape({
    cabinet_brand: Yup.number().nullable(),
    door_style_id: Yup.number().nullable(),
    order_date: Yup.string().nullable(),
    order_value: Yup.number().nullable(),
    customer_name: Yup.string().nullable(),
    email: Yup.string().email("Enter valid email").nullable(),
    phone: Yup.string().matches(phoneRegEx, "Please enter valid Phone.").min(11, "Please enter valid Phone.").nullable(),
    street: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    zipcode: Yup.string().matches(/^\d+$/, "The field should have digits only.").min(5, "Please enter valid zipcode.").nullable(),
  });

  useEffect(() => {
    if (soldPopup?.open) {
      dispatch(
        myRequestActions.getSoldFormData(
          props.match.params.id,
          cookies.clientAccessToken
        )
      );
    }
  }, [soldPopup?.open]);

  useEffect(() => {
    if (!sold?.id) {
      const { cabinet_brand_id, door_style_id, order_date } = dataTable
      if (cabinet_brand_id || door_style_id || order_date) {
        setFormData({
          ...formData,
          cabinet_brand_id,
          door_style_id,
          order_date: new Date(order_date)
        })
      }
    }
  }, [sold, dataTable])

  useEffect(() => {
    if (sold && soldPopup?.editSold) {
      const order_date = new Date(sold?.order_date);
      setFormData((f) => ({ ...f, ...sold, order_date }));
    }
  }, [sold, soldPopup?.editSold])

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  }

  const handleFormikSubmit = (values, actions) => {
    const formikValues = { ...values }
    if (values?.order_date && typeof formikValues.order_date === "object") {
      formikValues.order_date = getFormattedDate(formikValues.order_date);
    }
    if (soldPopup?.editSold) {
      dispatch(
        myRequestActions.updateMarkAsSoldData(
          props.match.params.id,
          cookies.clientAccessToken,
          { ...formData, ...formikValues }
        )
      );
    } else {
      dispatch(
        myRequestActions.markAsSoldFormData(
          props.match.params.id,
          cookies.clientAccessToken,
          formikValues
        )
      );
    }
  }

  const handlePopupClose = () => {
    dispatch(myRequestActions.markAsSoldPopup({ open: false, editSold: false }))
  }

  return (
    <div className='popup-container soldform'>
      <div className={`popup-outer ${soldPopup?.open ? 'show' : ''}`}>
        <div className="popup-inner">
          <span className="close-btn" onClick={() => handlePopupClose()}></span>
          <div className="mark-sold-form-box">

            <Formik
              enableReinitialize
              initialValues={formData}
              handleChange={handleChange}
              validationSchema={soldFormValidation}
              onSubmit={(values, actions) => {
                handleFormikSubmit(values, actions)
              }}
            >
              {({ touched, errors, values, handleBlur, handleChange, handleSubmit }) => (
                <form className="mark-sold-form" onSubmit={handleSubmit}>
                  <div className="sold-form-box">

                    <div className='sold-form-content'>
                      <h3 className='title'>Mark as Sold</h3>
                      <p className='sub-title'>Once Marked as Sold you won't be able to make any status updates & comments to this request</p>

                      <hr className='hr' />

                      <p className='section-title'><span className='info'>General Info </span><span>(Optional)</span></p>

                      <ul className='form-group-row'>
                        <li>
                          <div className="form-group">
                            <Select
                              setting={sFormFields.cabinet_brand_id}
                              touched={touched[sFormFields.cabinet_brand_id.key]}
                              errors={errors[sFormFields.cabinet_brand_id.key]}
                              value={values[sFormFields.cabinet_brand_id.key]}
                              optionData={dataTable?.cabinet_brands || []}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <Select
                              setting={sFormFields.door_style_id}
                              touched={touched[sFormFields.door_style_id.key]}
                              errors={errors[sFormFields.door_style_id.key]}
                              value={values[sFormFields.door_style_id.key]}
                              optionData={dataTable?.door_styles || []}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <label>Order Date </label>
                            <DatePicker
                              name="order_date"
                              value={values?.order_date}
                              onChange={(d) => {
                                handleChange({ target: { name: "order_date", value: d } })
                              }}
                              format="y-MM-dd"
                            />
                            <span className='error'>{errors.order_date && touched.order_date && errors.order_date}</span>
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <Input
                              setting={sFormFields.order_value}
                              touched={touched[sFormFields.order_value.key]}
                              errors={errors[sFormFields.order_value.key]}
                              value={values[sFormFields.order_value.key]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                      </ul>

                      <p className='section-title mt-0'><span className='info'>Customer Details </span><span>(Optional)</span></p>

                      <ul className='form-group-row'>
                        <li>
                          <div className="form-group">
                            <Input
                              setting={sFormFields.customer_name}
                              touched={touched[sFormFields.customer_name.key]}
                              errors={errors[sFormFields.customer_name.key]}
                              value={values[sFormFields.customer_name.key]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <Input
                              setting={sFormFields.email}
                              touched={touched[sFormFields.email.key]}
                              errors={errors[sFormFields.email.key]}
                              value={values[sFormFields.email.key]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <PhoneInput
                              country={"us"}
                              setting={sFormFields.phone}
                              touched={touched[sFormFields.phone.key]}
                              errors={errors[sFormFields.phone.key]}
                              value={values[sFormFields.phone.key]}
                              onChange={(value) => {
                                handleChange({ target: { name: "phone", value: value } })
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <Input
                              setting={sFormFields.street}
                              touched={touched[sFormFields.street.key]}
                              errors={errors[sFormFields.street.key]}
                              value={values[sFormFields.street.key]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <Input
                              setting={sFormFields.city}
                              touched={touched[sFormFields.city.key]}
                              errors={errors[sFormFields.city.key]}
                              value={values[sFormFields.city.key]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <Input
                              setting={sFormFields.state}
                              touched={touched[sFormFields.state.key]}
                              errors={errors[sFormFields.state.key]}
                              value={values[sFormFields.state.key]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <Input
                              setting={sFormFields.zipcode}
                              touched={touched[sFormFields.zipcode.key]}
                              errors={errors[sFormFields.zipcode.key]}
                              value={values[sFormFields.zipcode.key]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li></li>
                      </ul>
                    </div>
                    <div className="action-buttons">
                      <button type="submit" className="btn btn-orange">MARK AS SOLD</button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(SoldForm);