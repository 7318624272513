/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Myprofile from "../MyProfile";
import { actions as clientRequestActions } from "../../redux/reducers/NewRequestReducer";
import { actions as AuthActions } from "../../redux/reducers/AuthReducer";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { actions as myAccountActions } from "../../redux/reducers/MyAccountReducer";
import LoaderComponent from "../Loader";
import "./index.scss";
import Tour from 'reactour'
import { useConfig } from "../../context/configContext";

function NavBar() {
  const {logo, project_name} = useConfig();
  const dropDownRef = useRef();
  // ! Don't remove setCookie or re-arrange it
  const [cookies, setCookie, removeCookie] = useCookies(["clientAccessToken"]);
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, indicationStatus } = useSelector((state) => state.auth);
  const { logoutSuccess, logoutOnTokenExpire, logoutFailure, headerMsg } = useSelector(state => state.auth);
  const {
    myRequestListingData,
  } = useSelector((state) => state.myRequest);
  const { apiLoading, spocInfo } = useSelector(state => state.myAccountReducer)
  const [showDropdown, setShowDropdown] = useState(false);
  const [show, setShow] = useState(false);
  const [showSpoc, setShowSpoc] = useState(false);
  const [isOpenTour, setIsOpenTour] = useState(false);
  const [closeMsg, setCloseMsg] = useState(false);
  const steps = [
    {
      selector: '#spoc-link',
      content: 'Get the contact details of your SPOC by clicking here',
    },
    // ...
  ]
  useEffect(() => {
    if (logoutSuccess || logoutOnTokenExpire) {
      removeCookie("clientAccessToken", { path: "/" });
      dispatch(AuthActions.clearLogoutFlags());
      history.push("/login");
    }
    if (logoutFailure) {
      removeCookie("clientAccessToken", { path: "/" });
      dispatch(AuthActions.clearLogoutFlags());
      history.push("/login");
    }
  }, [logoutSuccess, logoutOnTokenExpire, logoutFailure]);

  const handleClick = (e) => {
    if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [handleClick]);

  useEffect(() => {
        
  }, []);

  useEffect(() => {
    if(myRequestListingData && !isOpenTour){
      if(!localStorage.getItem('isSpocOpened')){
        setShowDropdown(true);
        setTimeout(() => {
          setIsOpenTour(true)
        }, 1000);
        localStorage.setItem("isSpocOpened", true);
      }
    }
  }, [myRequestListingData]);

  const redirectToStep1 = () => {
    dispatch(myRequestActions.changeCopyRequestId(""));
    dispatch(myRequestActions.changeCopyAction(false));
    dispatch(clientRequestActions.clearRequestData());
    dispatch(
      clientRequestActions.getRequestDetails(cookies.clientAccessToken)
    );
    history.push("/app/client/submitrequest");
  };
  const clearSubmitRequestData = () => {
    dispatch(myRequestActions.changeCopyRequestId(""));
    dispatch(myRequestActions.changeCopyAction(false));
    dispatch(clientRequestActions.clearRequestData());
    history.push("/app/client/myrequests/");
  };

  const logoutClick = (e) => {
    e.preventDefault();
    setShowDropdown(false);
    dispatch(myRequestActions.changeCopyRequestId(""));
    dispatch(myRequestActions.changeCopyAction(false));
    dispatch(clientRequestActions.clearRequestData());
    dispatch(AuthActions.logout(cookies.clientAccessToken));
  };


  const handleProfileClick = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const showSpocPopup = (e) =>{
    e.preventDefault();
    if(!showSpoc){
      setIsOpenTour(false);
      localStorage.setItem("isSpocOpened", true);
      dispatch(myAccountActions.getAccountSpocInfo({ client_id: user?.id, clientAccessToken: cookies.clientAccessToken }));
    }
    setShowSpoc(!showSpoc);
  }
  const closeTour = () => {
    setIsOpenTour(false);
    localStorage.setItem("isSpocOpened", true);
  };
  const closeAnnouncementMsg = () =>{
    setCloseMsg(true);
  }
  return (
    <>
    <Tour
        className="tour-msg"
        steps={steps}
        isOpen={isOpenTour}
        onRequestClose={()=>{closeTour()}}
        highlightedMaskClassName="tour-highlighted-mask"
        showPrevNextButtons={true}
        showNavigation={false}
      />
      {indicationStatus && indicationStatus.announcement && !closeMsg?
    <div className="announcement-msg">
      <div className="announcementOfferText">
        <a href={void(0)} onClick={closeAnnouncementMsg} className="closeSticky-btn" style={{cursor: "pointer"}}></a>
        <div className="container cf">
          <p className="announcementIcon" style={ { backgroundImage: `url(${require("../../assets/images/announcement-icon.png")})`, } }>
          <div dangerouslySetInnerHTML={{__html: indicationStatus.announcement}}></div>
          </p>
        </div>
      </div>
    </div>
   :null}
    {headerMsg && headerMsg.status == 1?<div className="header-msg" dangerouslySetInnerHTML={{__html: headerMsg.message}}></div>:null}
    {headerMsg && headerMsg.status == 2?<div className="header-msg-red" dangerouslySetInnerHTML={{__html: headerMsg.message}}></div>:null}
    <header className="header">
      <LoaderComponent
        showhide={apiLoading}
      />
      <div className="container cf">
        <span className="nav-toggle">
          <span>Toggle Nav</span>
        </span>
        <Link to="/app" className="mainlogo" title={project_name?.value}>
          <img 
            src={logo.value}
            alt={project_name.value}
          />
        </Link>

        <div
          ref={dropDownRef}
          onClick={() => setShowDropdown(true)}
          className="right-btn profile_ddown"
        >
          <a data-tip={user?.email} href="#" className="user-name">
            {user && user.name?user.name.substring(0, 2):""}
          </a>
          <ReactTooltip />
          <ul className={`account-linkd${showDropdown ? " active" : ""}`}>
            {user?.client_type !== "standard" && <li><a href="#" onClick={handleProfileClick}>My Profile</a></li>}
            {user?.client_type !== "enterprise" && <li><Link to="/app/client/myaccount" >My Account</Link></li>}
            <li><Link id="spoc-link" to="#" onClick={showSpocPopup} style={{position: "relative"}}>SPOC</Link></li>
            <li>
              <a href="#" onClick={logoutClick}>Sign Out</a>
            </li>
          </ul>
          <div className={`image-popup-outer ${show ? "show" : ""}`}>
            <div className="image-popup-inner">
              <span
                className="close-btn"
                onClick={(e) => {
                  setShow(!show);
                  e.stopPropagation();
                }}
              ></span>
              <div className="image-box">
                <Myprofile popupStatus={show} popupStatusHandler={setShow} isClientView />
              </div>
            </div>
          </div>
          <div className={`image-popup-outer spo-popup ${showSpoc ? "show" : ""}`}>
            <div className="image-popup-inner">
              <span
                className="close-btn"
                onClick={(e) => {
                  setShowSpoc(!showSpoc);
                  e.stopPropagation();
                }}
              ></span>
              <div className="image-box">
              <div className="myprofile">
      
              <div className="myprofile-inner">
                <div className="myprofilebox">
                  <span className="title-text">
                  <img 
                    src={logo.value}
                    alt={project_name.value} 
                  />
                  </span>
                  <p>Point of Contact</p>
                  {spocInfo.map((data, index) => {
                        return (<div className="spoc" key={"spoc"+index}>
                          <div className="profile-info-block">
                        <div className="user-profile"><img src={require("../../assets/images/user-icon.svg")} alt={project_name?.value +" User"}/></div>
                        <div className="client-right-profile">
                          <h4>{data.name}</h4>
                          <p className="profile-email"><a href = {"mailto: "+data.email}>{data.email}</a></p>
                          {data.phone_number && <p className="profile-phone"><a href = {"tel: "+data.phone_number}>+{data.phone_number}</a></p>}
                        </div>
                        </div>
                        <div className="profile-position">
                          <p>{data.position}</p>
                        </div>
                      </div>)})}
                </div>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar">
          <ul>
            <li>
              <NavLink
                onClick={clearSubmitRequestData}
                to="/app/client/myrequests/"
                title="My Request"
                activeClassName="active-link"
              >
                My Requests
              </NavLink>
            </li>
            {user?.group_id === 4 ? null : <>
              <li>
                <NavLink
                  onClick={redirectToStep1}
                  to="/app/client/submitrequest"
                  title="Submit New Request"
                  activeClassName="active-link"
                  isActive={(match, location) => {
                    let pathStrings = location.pathname.split("/");

                    if (match) {
                      return true;
                    } else if (pathStrings[3] === "submitrequest") {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                >
                  Submit New Request
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/app/client/reports"
                  title="Reports"
                  activeClassName="active-link"
                >
                  Reports
                </NavLink>
              </li>
            </>}
          </ul>
        </nav>
      </div>
    </header>
    </>
  );
}

export default NavBar;
