/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import './index.scss';
import { actions as AuthActions } from '../../redux/reducers/AuthReducer'
import { useConfig } from '../../context/configContext';

function ForgetPasswordForm({ onResetButtonClicked}) {
  const {logo, project_name, logo_redirection_link} = useConfig();
  const dispatch = useDispatch()
  const history = useHistory()
  const { setSuccessForForgotPassword,forgotPasswordLoading} = useSelector(state => state.auth)
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter valid email")
      .required("Please provide your registered email address"),
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    ForgotPasswordSchema.validateAt('email', {email: email}, {abortEarly: false})
    .then(() => {
      setEmailError('')
    })
    .catch(function(error) {
      console.log('error: ', error);
      setEmailError(error.errors);
    })
    if (emailError === "" && email) {
      onResetButtonClicked(email);
    }
  }
  useEffect(() => {
    if(setSuccessForForgotPassword){
    dispatch(AuthActions.clearForgotPasswordFlags());
    setShow(true);
    }
    if(!setSuccessForForgotPassword && !forgotPasswordLoading){
      setEmail("")
    }
   },[setSuccessForForgotPassword,forgotPasswordLoading]);
  const handleChange = (e) => {
   setEmail(e.target.value);
    ForgotPasswordSchema.validateAt('email', {email: e.target.value}, {abortEarly: false})
    .then(() => {
      setEmailError('')
    })
    .catch(function(error) {
      console.log('error: ', error);
      setEmailError(error.errors);
    })
  }
  const handleOk = () =>{
history.push("/login");
  }
  const [show, setShow] = useState(false);

  return (
      <form className="login-form forget-password-form">
        <div className="login-box">
          <a href={logo_redirection_link?.value}>
            <span className="title-text">
              <img 
                src={logo?.value}
                alt={project_name?.value} />
            </span>
          </a>
           <div className="title-text">
              Forgot Password ?
           </div>
           <div className="fieldset">
             <div className="field">
               <label className="label required" htmlFor="email">Email</label>
               <input
                id="email"
                type="email"
                className="input-text"
                name="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => handleChange(e)}
              />
              {emailError && emailError !== '' ? <div className="error">{emailError}</div> : null}
             </div>
             <div className="action">
               <button  className="btn btn-black" onClick={(e) => handleSubmit(e)}  >Reset My Password</button>
             </div>
             <div className="return-home"><Link to="/">Return to Home</Link></div>
           </div>
           <div className={`popup-outer ${show ? "show" : ""}`}>
              <div className="popup-inner">
                <span className="close-btn"  onClick={() => setShow(false)}></span>
                 <div className="sent-message">
                   <p>Reset Password URL has been successfully sent to your registered email. Please reset your password.</p>
                   <div className="button-row cf">
                     <button className="btn back"> cancel</button>
                     <button className="btn btn-black" onClick={handleOk}> ok </button>
                   </div>
                  </div>
              </div>
            </div>
        </div>
      </form>
  );
}

export default ForgetPasswordForm;