/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { showToast } from '../../Utils/utils'
import { actions as requestActions } from '../../redux/reducers/MyRequestReducer';
import { actions as AuthActions } from "../../redux/reducers/AuthReducer";
import LoaderComponent from "../Loader";
import "./index.scss";
import { useConfig } from "../../context/configContext";

function MyProfile({ popupStatus, popupStatusHandler, isClientView }) {
  const {logo, project_name} = useConfig();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);

  const { user: { name, email, phone, company_name } } = useSelector(state => state.auth)
  const { userProfileUpdateProcess, userResetPasswordProcess } = useSelector(state => state.myRequest)

  const { isUpdatingUserProfile, updatedProfileResponse,
    isUserProfileUpdateFailed, userProfileUpdateFailedMessage } = userProfileUpdateProcess

  const { isResetingUserPassword, resetPasswordSuccessResponse,
    isResetPasswordProcessFailed, resetPasswordProcessFailedMessage } = userResetPasswordProcess

  const [userEmail, setUserEmail] = useState("")
  const [fullName, setFullName] = useState("")
  const [userPhoneNumber, setUserPhoneNumber] = useState("")
  const [userCompany, setUserCompany] = useState("")

  const isEmailChanged = userEmail === email
  const isNameChanged = name === fullName
  const isPhoneChanged = phone === userPhoneNumber
  const isCompanyChanged = company_name === userCompany

  const vaildPhoneRegex = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
  const vaildEmailRegex = /\S+@\S+\.\S+/gm

  // ? useEffect for user details updation
  useEffect(() => {
    if (popupStatus) {
      setUserEmail(email); setFullName(name); setUserPhoneNumber(phone); setUserCompany(company_name)
    }
  }, [popupStatus])

  // ? useEffect for user profile updation
  useEffect(() => {
    if (isUserProfileUpdateFailed) {
      showToast("Profile Update Failed", userProfileUpdateFailedMessage, "danger")
    }
    if (updatedProfileResponse !== "" && isUpdatingUserProfile === false) {
      popupStatusHandler(false);
      showToast("Profile Update Success", updatedProfileResponse, "info")
      dispatch(AuthActions.getUserDetails(cookies.clientAccessToken));
    }
  }, [isUpdatingUserProfile]);

  // ? useEffect for user reset password process
  useEffect(() => {
    if (isResetPasswordProcessFailed) {
      showToast("Reset Password Failed", resetPasswordProcessFailedMessage, "danger")
    }
    if (
      resetPasswordSuccessResponse !== "" && isResetingUserPassword === false) {
      popupStatusHandler(false);
      showToast("Reset Password Success", resetPasswordSuccessResponse, "success")
    }
  }, [isResetingUserPassword]);

  const submitHandler = () => {
    if (isClientView) {
      if(userPhoneNumber.length<11){
        showToast("Required field", 'Please enter valid phone number', "danger")
        return;
      }
      if (!vaildPhoneRegex.test(userPhoneNumber)) {
        showToast("Required field", 'Please enter valid phone number', "danger")
        return;
      }

      if (!vaildEmailRegex.test(userEmail)) {
        showToast("Required field", 'Please enter valid email', "danger")
      }

      else {
        dispatch(requestActions.updateUserProfileDetails(userEmail, fullName, cookies.clientAccessToken, userPhoneNumber,
          userCompany));
      }
    } else {
      if (!vaildEmailRegex.test(userEmail)) {
        showToast("Required field", 'Please enter valid email', "danger")
      } else {
        dispatch(requestActions.updateUserProfileDetails(userEmail, fullName, cookies.clientAccessToken));
      }
    }
  };

  const resetPasswordHandler = () => {
    if (userEmail === "") {
      showToast("Required field", "Please enter email", "danger")
    } else {
      dispatch(requestActions.resetUserPassword(userEmail, cookies.clientAccessToken));
    }
  };

  const phoneChangeHandler = (e) => {
    // let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    // e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    if(e.target.value.length < 12){
      setUserPhoneNumber(e.target.value)
    }
  }

  return (
    <div className="myprofile">
      <LoaderComponent
        showhide={isUpdatingUserProfile || isResetingUserPassword}
      />
      <div className="myprofile-inner">
        <div className="myprofilebox">
          <span className="title-text">
            <img 
              src={logo?.value}
              alt={project_name?.value} 
            />
          </span>
          <div className="fieldset">
            <div className="field field-email">
              <label className="label required" htmlFor="email">
                Email address
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                className="input-text"
                placeholder="Enter your email"
              />
            </div>
            <div className="field field-name">
              <label className="label required" htmlFor="name">
                Name
              </label>
              <input
                id="name"
                type="text"
                name="name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="input-text"
                placeholder="Full Name"
              />
            </div>
            {isClientView && (
              <>
                <div className="field field-name">
                  <label className="label required" htmlFor="mobile-number">
                    Mobile Number
                  </label>
                  <input
                    id="mobile-number"
                    type="text"
                    name="mobile-number"
                    value={userPhoneNumber}
                    onChange={phoneChangeHandler}
                    className="input-text"
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="field field-name">
                  <label className="label required" htmlFor="company-name">
                    Company Name
                  </label>
                  <input
                    id="company-name"
                    type="text"
                    name="company-name"
                    value={userCompany}
                    onChange={(e) => setUserCompany(e.target.value)}
                    className="input-text"
                    placeholder="Company Name"
                  />
                </div>
              </>
            )}
            <div className="action">
              <button
                className="btn btn-black"
                onClick={submitHandler}
                disabled={
                  isEmailChanged &&
                  isNameChanged &&
                  isPhoneChanged &&
                  isCompanyChanged
                }
              >
                {" "}
                Update Detail{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="reset-pass">
          <a onClick={resetPasswordHandler}>Reset password?</a>
        </div>
      </div>
    </div>
  );
}
export default MyProfile;
