/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, memo } from "react";
import { useSelector } from "react-redux";
import MyRequestAddRequest from '../MyRequestAddRequest'
import { convertUTCToLocale } from '../../Utils/utils'
import { Link } from "react-router-dom";

import "./index.scss";

function RequestListItem({
  ratingCopyTimeSpentFlag,
  data,
  loadMoreRequest,
  viewRequest,
  viewRequestUrl,
  hideDeleteIcon,
  deleteRequest,
  copyClientRequest,
  requestStatusSection,
  draftRequestClick,
  requestPrioritize,
  isAllPriortise,
}) {
  const { user } = useSelector((state) => state.auth);
  const isViewRequestUrlFunction = useMemo(() => typeof viewRequestUrl === 'function', [viewRequestUrl]);
  const loadMoreRecords = () => {
    loadMoreRequest();
  };
  const viewRequestNew = (e) => {
    viewRequest(e.target.id);
  };
  const deleteRequestFromList = (e) => {
    deleteRequest(e.target.id);
  };
  const copyRequest = (e) => {
    copyClientRequest(e.target.id);
  };
  const draftClick = (e) => {
    draftRequestClick(e.target.id);
  };

  const renderRatingStar = (ratingCount) => {

    const ratingArray = [0, 0, 0, 0, 0];
    for (let i = 0; i < ratingArray.length; i++) {
      if (i < ratingCount) {
        ratingArray[i] = 1;
      } else {
        ratingArray[i] = 0;
      }
    }
    return (
      <>
        {ratingArray.map((data, index) => {
          return (
            <span
              key={index}
              className={data === 1 ? "star active" : "star"}
            ></span>
          );
        })}
      </>
    );
  };

  const isDraftSection = Boolean(requestStatusSection === "yourdrafts");
  const whenAdded = (val) => {
    if (val == "today") {
      return "Added Today";
    } else if (val == "recently") {
      return "Added Recently";
    } else {
      return false;
    }
  }
  const getItemContent = (key) => (
    <div
      onClick={
        isDraftSection
          ? draftClick
          : viewRequestNew
      }
      style={{ cursor: "pointer" }}
      id={data.datatable.data[key]["clientRequestId"]}
      className="request-detail"
    >
      <div
        id={data.datatable.data[key]["clientRequestId"]}
        className="title-and-comment"
      >
        <div className="request-img">
          <img
            onClick={
              isDraftSection
                ? draftClick
                : viewRequestNew
            }
            id={data.datatable.data[key]["clientRequestId"]}
            src={data.datatable.data[key]["request_type_image"]}
            alt="CP2020"
          />
        </div>
        <h2
          id={data.datatable.data[key]["clientRequestId"]}
          className="title"
        >
          {data.datatable.data[key]["requestTitle"]}
        </h2>
        <ul
          id={data.datatable.data[key]["clientRequestId"]}
          className="comment-and-revision"
        >
          <li id={data.datatable.data[key]["clientRequestId"]}>
            <a
              id={data.datatable.data[key]["clientRequestId"]}
              href="#"
              title={
                data.datatable.data[key]["requestTotalComments"]
                  ? data.datatable.data[key][
                  "requestTotalComments"
                  ]
                  : 0 + " Comments"
              }
            >
              {data.datatable.data[key]["requestTotalComments"]
                ? data.datatable.data[key][
                "requestTotalComments"
                ]
                : 0}{" "}
              Comments
            </a>
          </li>
          <li>
            <a
              href="#"
              id={data.datatable.data[key]["clientRequestId"]}
              title={
                data.datatable.data[key][
                "requestRevisionNumber"
                ] + " Revisions"
              }
            >
              {
                data.datatable.data[key][
                "requestRevisionNumber"
                ]
              }{" "}
              Revisions
            </a>
          </li>
        </ul>
      </div>
      {data.datatable && data.datatable.data && data.datatable.data[key] && data.datatable.data[key]["whenAdded"] && <div className="when-added">
        <span>{whenAdded(data.datatable.data[key]["whenAdded"])}</span>
      </div>}
      <div
        className="name-and-status"
        id={data.datatable.data[key]["clientRequestId"]}
      >
        <div className="name-container">
          <span
            id={data.datatable.data[key]["clientRequestId"]}
            className="request-added"
            style={{
              display: user.group_id == 3 ? "" : "none",
            }}
          >
            Designer:{" "}
            {data.datatable.data[key]["assignedTo"] &&
              data.datatable.data[key]["assignedTo"]
              ? data.datatable.data[key]["assignedTo"]
              : ""}
          </span>
          <span
            id={data.datatable.data[key]["clientRequestId"]}
            className="request-added"
          >
            Added By: {data.datatable.data[key]["requestAddedBy"]}
          </span>
        </div>
        <div className="status-container">
          {data?.datatable?.data[key]["sold_id"] > 0 ?
            <span className="request-status sold" >
              Sold
            </span>
            : null}
          <span
            id={data.datatable.data[key]["clientRequestId"]}
            className={
              "request-status " +
              (data.datatable.data[key]["requestStatus"]
                ? data.datatable.data[key]["requestStatus"] == "To be Continued" && (user.group_id == 1 || user.group_id == 2 || user.group_id == 4 || user.group_id == 5) ? "In Progress" : data.datatable.data[key]["requestStatus"]
                : ""
              )
                .toLowerCase()
                .replace(/\s+/g, "-")
            }
          >
            {data.datatable.data[key]["requestStatus"] == "To be Continued" && (user.group_id == 1 || user.group_id == 2 || user.group_id == 4 || user.group_id == 5) ? "In Progress" : data.datatable.data[key]["requestStatus"]}
          </span>
        </div>
      </div>
    </div>
  );

  const borderHighlight = (requestStatusSection, isRequestPrioritised, isAllPriortise, is_all_tab_prioritisation_enabled) => {
    if (is_all_tab_prioritisation_enabled) {
      if (is_all_tab_prioritisation_enabled == 1) {
        return "";
      }
    } else if ((requestStatusSection == 'all-requests' || requestStatusSection == 'designerprioritized' || requestStatusSection == 'myopenrequest') && isRequestPrioritised == 1 && isAllPriortise == false) {
      return "border-highlight";
    } else {
      return "";
    }
  }

  const flagIcon = (isRequestPrioritised, isAllPriortise, is_all_tab_prioritisation_enabled) => {
    if (is_all_tab_prioritisation_enabled) {
      if (is_all_tab_prioritisation_enabled !== 1) {
        return (<div className="flagimg">
          <img src={require("../../assets/images/flag-iconnew.png")} alt="" />
        </div>)
      }
    } else if (user && user.is_all_tab_prioritisation_enabled && user.is_all_tab_prioritisation_enabled == 1) {
      return null;
    } else if (isRequestPrioritised == 1 && isAllPriortise == false) {
      return (<div className="flagimg">
        <img src={require("../../assets/images/flag-iconnew.png")} alt="" />
      </div>)
    } else {
      return null;
    }
  }

  const indexNo = (i, p, isAllPriortise) => {
    if (user["is_all_tab_prioritisation_enabled"] == 1 && p) {
      return p
    } else if (isAllPriortise && p) {
      return p;
    } else {
      return i + 1;
    }
  }

  return (
    <div className="requestlistitem">
      <div className="container">
        <ul className="requestlis">
          {data !== "" && data?.datatable && data?.datatable?.data.length !== 0
            && Object.keys(data.datatable.data).map((key, index) => {
              return (
                <li key={key} className={borderHighlight(requestStatusSection, data.datatable.data[key]["isRequestPrioritised"], isAllPriortise, data.datatable.data[key]["is_all_tab_prioritisation_enabled"])}>
                  <div className="about-request cf">
                    {flagIcon(data.datatable.data[key]["isRequestPrioritised"], isAllPriortise, data.datatable.data[key]["is_all_tab_prioritisation_enabled"])}

                    <div className={`request-left-section ${ratingCopyTimeSpentFlag ? 'has-rating' : ''}`}>
                      <WrapLinkTag
                        to={isViewRequestUrlFunction ? viewRequestUrl(data.datatable.data[key]["clientRequestId"]) : undefined}
                        renderLink={isViewRequestUrlFunction}
                      >
                        <span
                          onClick={
                            isDraftSection
                              ? draftClick
                              : viewRequestNew
                          }
                          style={{ cursor: "pointer" }}
                          id={data.datatable.data[key]["clientRequestId"]}
                          className="request-id"
                        >
                          Request #{data.datatable.data[key]["requestId"]}
                        </span>
                      </WrapLinkTag>

                      <span
                        style={{
                          color: "#DFDFDF",
                          marginLeft: "10px",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      >
                        {"|"}
                      </span>
                      {(requestStatusSection == 'all-requests' || requestStatusSection == 'open-requests' || requestStatusSection == 'wip' || requestStatusSection == 'needs-information') && data.datatable.data[key]["isRequestPrioritised"] == 1 &&
                        user["is_all_tab_prioritisation_enabled"] !== 1 && <span className={data.datatable.data[key]["isRequestPrioritised"] == 1 ? "prioritized" : "prioritize"}>PRIORITIZED</span>}
                      {(requestStatusSection == 'all-requests' || requestStatusSection == 'open-requests' || requestStatusSection == 'wip' || requestStatusSection == 'needs-information') && data.datatable.data[key]["requestStatusSlug"] !== "needs-approval"
                        && data.datatable.data[key]["requestStatusSlug"] !== "needs-information" &&
                        data.datatable.data[key]["requestStatusSlug"] !== "in-progress" && user.is_request_prioritisation_allowed == 1
                        && data.datatable.data[key]["isRequestPrioritised"] !== 1 && user.is_all_tab_prioritisation_enabled !== 1
                        ? <span className={data.datatable.data[key]["isRequestPrioritised"] == 1 ? "prioritized" : "prioritize"}
                          onClick={() => {
                            if (data.datatable.data[key]["isRequestPrioritised"] == 0 && user.is_request_prioritisation_allowed == 1)
                              requestPrioritize(data.datatable.data[key]["clientRequestId"], false)
                          }}>
                          {data.datatable.data[key]["isRequestPrioritised"] == 1 ? "PRIORITIZED" : "PRIORITIZE"}
                          <span className="tooltip-prioritize">Click here to prioritize this design request</span></span> : null}
                      {requestStatusSection == 'prioritized' && user.is_request_prioritisation_allowed == 1 ? <span className={"deprioritized"}
                        onClick={() => {
                          if (user.is_request_prioritisation_allowed == 1) {
                            requestPrioritize(data.datatable.data[key]["clientRequestId"], true)
                          }
                        }}>DEPRIORITIZE<span className="tooltip-prioritize">Click here to deprioritize this design request</span></span> : null}
                      {(requestStatusSection == 'designerprioritized' || requestStatusSection == 'designerprioritizedtab') && data.datatable.data[key]["isRequestPrioritised"] == 1 && isAllPriortise == false ?
                        <span className="prioritized">PRIORITIZED</span> : null}
                      {requestStatusSection == 'myopenrequest' && data.datatable.data[key]["isRequestPrioritised"] == 1 && data.datatable.data[key]["is_all_tab_prioritisation_enabled"] !== 1 ?
                        <span className="prioritized">PRIORITIZED</span> : null}
                      <span
                        style={{
                          display: user.group_id == 3 ? "" : "none",
                          color: "#71B601",
                        }}
                        className="request-id"
                      >
                        {data.datatable.data[key]["company_name"] &&
                          data.datatable.data[key]["company_name"]
                          ? data.datatable.data[key]["company_name"]
                          : ""}
                      </span>
                    </div>
                    <div className="request-right-section">
                      <span
                        style={{
                          display: ratingCopyTimeSpentFlag ? "" : "none",
                        }}
                        className="rating"
                      >
                        {renderRatingStar(
                          data.datatable.data[key]["requestRating"]
                        )}
                      </span>
                      <span
                        style={{
                          display: ratingCopyTimeSpentFlag ? "" : "none",
                        }}
                        className="time-spent"
                      >
                        Time Spent: {data.datatable.data[key]["timeSpent"]}
                      </span>
                      <span className="due-date">
                        Created on: {convertUTCToLocale(data.datatable.data[key]["requestCreateDate"])?.dateAndTime} &nbsp;
                      </span>
                      <span className="due-date">
                        Due: {convertUTCToLocale(data.datatable.data[key]["requestDueDate"])?.date}
                      </span>
                      <span className="last-updated">
                        Last Updated:{" "}
                        {convertUTCToLocale(data.datatable.data[key]["requestLastUpdateDate"])?.dateAndTime}
                      </span>
                      {(isDraftSection || data.datatable.data[key]["requestStatus"] ===
                        "Queued" ||
                        data.datatable.data[key]["status"] === "0") && (
                          <span
                            style={{
                              display:
                                hideDeleteIcon ||
                                  (!data.datatable.data[key]["is_delete"]) && !isDraftSection
                                  ? "none"
                                  : "",
                            }}
                            className="request-delete"
                            onClick={deleteRequestFromList}
                            id={data.datatable.data[key]["clientRequestId"]}
                          >
                            request-delete
                          </span>
                        )}
                      <span className="request-no">#{indexNo(index, data.datatable.data[key]["requestPrioritiseOrder"], isAllPriortise)}</span>
                      <span
                        id={data.datatable.data[key]["clientRequestId"]}
                        onClick={copyRequest}
                        style={{
                          display: ratingCopyTimeSpentFlag ? "" : "none",
                        }}
                        className="copy-tool"
                      >
                        cursor-box
                      </span>
                      {data.datatable.data[key]["has_updates"] == 1 ? <span id={"ind" + data.datatable.data[key]["clientRequestId"]} className="indication"></span> : null}
                    </div>
                  </div>
                  <WrapLinkTag
                    to={isViewRequestUrlFunction ? viewRequestUrl(data.datatable.data[key]["clientRequestId"]) : undefined}
                    renderLink={isViewRequestUrlFunction}
                  >
                    {getItemContent(key)}
                  </WrapLinkTag>
                </li>
              );
            })
          }
        </ul>
        <div className="moredesignrequests">
          {data &&
            data.datatable &&
            data.datatable.data.length !== 0 &&
            !(
              data.datatable.data.length === 0 ||
              data.datatable.data.length === data.datatable.recordsTotal ||
              data.datatable.data.length === data.datatable?.recordsFiltered
            ) && (
              <a
                style={{
                  cursor: "pointer",
                }}
                onClick={loadMoreRecords}
                href={void 0}
                title="And 20 more design requests..."
              >
                And 20 more design requests...
              </a>
            )}
        </div>
      </div>
      {(data === "" || data?.datatable?.data.length === 0) && <MyRequestAddRequest accountType={user.group_id} />}
    </div>
  );
}

const WrapLinkTag = memo(({ children, to, renderLink }) => {
  const { user } = useSelector((state) => state.auth);
  const clearIndicationFromItem = (id) => {
    const element = document.getElementById("ind" + id);
    if (element) {
      element.remove();
    }
  }
  return (
    renderLink ? (
      <Link to={to} target={(user.id == 1 || user.parent_id == 1) ? "_self" : "_blank"}>
        {children}
      </Link>
    ) : children
  )
});

export default RequestListItem;
