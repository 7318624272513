/* eslint-disable no-useless-escape */
import React, {useState} from 'react';
import { useLocation } from 'react-router-dom'
import * as Yup from "yup";
import { Link } from "react-router-dom";
import './index.scss';
import { useConfig } from '../../context/configContext';

function ResetPasswordForm({restClicked}) {
  const {logo, project_name} = useConfig();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const email = decodeURIComponent(location.search.replace("?email=", ""));
  const token = location.pathname.replace(
    "/auth/reset-password/",
    ""
  );
  let ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "The password must be at least 8 characters. Password Should have at least 1 lowercase AND 1 uppercase AND 1 number and with Special Characters."
      )
      .required("New Password is required"),
    confirmPassword: Yup.string()
      .required("Re-Type Password is required")
      .oneOf([Yup.ref("password"), ""], "Passwords must match")
  });
  const changePassword = (e) => {
    setPassword(e.target.value);
    ResetPasswordSchema.validateAt('password', {password: e.target.value}, {abortEarly: false})
    .then(() => {
      setPasswordError('')
    })
    .catch(function(error) {
      setPasswordError(error.errors[0]);
    })
  }
  const changeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    ResetPasswordSchema.validateAt('confirmPassword', {password: password, confirmPassword: e.target.value}, {abortEarly: false})
    .then(() => {
      setConfirmPasswordError('')
    })
    .catch(function(error) {
      setConfirmPasswordError(error.errors[0]);
    })
  }
  const handleSubmit =  (e) => {
    e.preventDefault();    
    if (passwordError === '' && confirmPasswordError === '') {
      restClicked(email,password,confirmPassword,token);
      }
    }
  return (
    <form className="login-form forget-password-form">
        <div className="login-box">
          <Link to="/">
            <span className="title-text">
              <img 
                src={logo?.value}
                alt={project_name?.value}
              />
            </span>
          </Link>
           <div className="title-text">
              Reset your password
           </div>
           <div className="fieldset">
             <div className="field">
               <label className="label required" htmlFor="new_password">New Password</label>
               <input
                id="new_password"
                type="password"
                className="input-text"
                placeholder="New Password"
                name="password"
                onChange={(e) => changePassword(e)}
                value={password}
              />
              {passwordError && passwordError !== '' ? <div className="error">{passwordError}</div> : null}
             </div>
             <div className="field">
               <label className="label required" htmlFor="re_type_password">Re-Type Password</label>
               <input
                id="re_type_password"
                type="password"
                className="input-text"
                placeholder="Re-Type Password"
                name="confirmPassword"
                onChange={(e) => changeConfirmPassword(e)}
                value={confirmPassword}
              />
              {confirmPasswordError && confirmPasswordError !== '' ? <div className="error">{confirmPasswordError}</div> : null}
             </div>
             <div className="action">
               <button className="btn btn-black" onClick={(e) => handleSubmit(e)}>Reset Password</button>
             </div>
           </div>
        </div>
      </form>
  );
}

export default ResetPasswordForm;