/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { actions as authActions } from "./AuthReducer";
import { actions as generalActions } from "./GeneralReducer";
import ShortUniqueId from "short-unique-id";
import apiUrl from "../../config/urls";
import {
  getApiToken,
  postApiToken,
  postApiTokenJson,
  postApiTokenFormData,
  fetchDoorDetails,
} from "../apis";
import queryString from "query-string";
import PdfIcon from "../../assets/images/Antu_acrobat.svg";
const uid = new ShortUniqueId();

export const actionTypes = {
  CLIENT_REQUEST_STEP_1_SUBMIT: "CLIENT_REQUEST_STEP_1_SUBMIT",
  CLIENT_REQUEST_STEP_LOADING: "CLIENT_REQUEST_STEP_1_LOADING",
  CHANGE_CLIENT_REQUEST_NAME: "CHANGE_CLIENT_REQUEST_NAME",
  STEP_1_COMPLETE: "STEP_1_COMPLETE",
  CHANGE_CLIENT_REQUEST_TYPE: "CHANGE_CLIENT_REQUEST_TYPE",
  CLIENT_REQUEST_STEP_2_SUBMIT: "CLIENT_REQUEST_STEP_2_SUBMIT",
  STEP_2_COMPLETE: "STEP_2_COMPLETE",
  SET_REQUEST_ID: "SET_REQUEST_ID",
  GET_CLIENT_REQUEST_STEP_3_OPTIONS: "GET_CLIENT_REQUEST_STEP_3_OPTIONS",
  SET_CLIENT_REQUEST_STEP_3_OPTIONS: "SET_CLIENT_REQUEST_STEP_3_OPTIONS",
  CHANGE_STEP_3_OPTIONS_VALUE: "CHANGE_STEP_3_OPTIONS_VALUE",
  CLIENT_REQUEST_STEP_3_SUBMIT: "CLIENT_REQUEST_STEP_3_SUBMIT",
  STEP_3_COMPLETE: "STEP_3_COMPLETE",
  CHANGE_KITCHEN_MEASUREMENT: "CHANGE_KITCHEN_MEASUREMENT",
  CHANGE_KITCHEN_PHOTOS: "CHANGE_KITCHEN_PHOTOS",
  CHANGE_KITCHEN_KIT: "CHANGE_KITCHEN_KIT",
  CHANGE_SECTION_PROPERLY_LABELED: "CHANGE_SECTION_PROPERLY_LABELED",
  STEP_4_COMPLETE: "STEP_4_COMPLETE",
  CLIENT_REQUEST_STEP_4_SUBMIT: "CLIENT_REQUEST_STEP_4_SUBMIT",
  STEP_5_COMPLETE: "STEP_5_COMPLETE",
  CHANGE_TEXT_EDITOR_DATA: "CHANGE_TEXT_EDITOR_DATA",
  CHANGE_DUE_DATE: "CHANGE_DUE_DATE",
  CHANGE_NOTE: "CHANGE_NOTE",
  CLIENT_REQUEST_STEP_5_SUBMIT: "CLIENT_REQUEST_STEP_5_SUBMIT",
  CHANGE_TEXT_EDITOR_DATA_JSON: "CHANGE_TEXT_EDITOR_DATA_JSON",
  CLEAR_REQUEST_DATA: "CLEAR_REQUEST_DATA",
  SUCCESS_REQUEST_ID: "SUCCESS_REQUEST_ID",
  GET_STEP_DATA: "GET_STEP_DATA",
  SET_STEP_3_VALUES: "SET_STEP_3_VALUES",
  DELETE_KITCHEN_MEDIA: "DELETE_KITCHEN_MEDIA",
  CHANGE_REQUEST_TYPES: "CHANGE_REQUEST_TYPES",
  NAVIGATE_TO_DRAFT_PAGE: "NAVIGATE_TO_DRAFT_PAGE",
  CLEAR_NAVIGATE_TO_DRAFT_FLAG: "CLEAR_NAVIGATE_TO_DRAFT_FLAG",
  GET_DOOR_DETAILS_INIT: "GET_DOOR_DETAILS_INIT",
  GET_DOOR_DETAILS_SUCCESS: "GET_DOOR_DETAILS_SUCCESS",
  GET_DOOR_DETAILS_FAILURE: "GET_DOOR_DETAILS_FAILURE",
};

const initialState = {
  clientRequestName: "",
  clientRequestLoading: false,
  step1Complete: false,
  clientRequestType: "1",
  step2Complete: false,
  requestId: "",
  step3OptionObject: null,
  step3OptionValues: null,
  step3Complete: false,
  kitchenMeasurement: [],
  kitchenPhotos: [],
  kitchenKit: [],
  allSectionProperlyLabeled: false,
  step4Complete: false,
  step5Complete: false,
  textEditorData: "",
  dueDate: new Date(),
  note: "",
  textEditorDataJson: "",
  requestIdSuccess: "",
  deletedMedia: { kitchen_measurements: [], kitchen_photos: [], kit_files: [] },
  requestTypes: [],
  navigateToDraftPage: false,
  doorDetailsFetch: {
    isFetchingDoorDetails: false,
    doorDetailsData: [],
    isFetchingDoorDetailsFailed: false,
    isFetchingDoorDetailsFailedMessage: "",
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_REQUEST_DATA: {
      return {
        ...state,
        clientRequestName: "",
        clientRequestLoading: false,
        step1Complete: false,
        clientRequestType: "1",
        step2Complete: false,
        requestId: "",
        step3OptionObject: null,
        step3OptionValues: null,
        step3Complete: false,
        kitchenMeasurement: [],
        kitchenPhotos: [],
        kitchenKit: [],
        allSectionProperlyLabeled: false,
        step4Complete: false,
        step5Complete: false,
        textEditorData: "",
        dueDate: new Date(),
        note:"",
        textEditorDataJson: "",
        deletedMedia: {
          kitchen_measurements: [],
          kitchen_photos: [],
          kit_files: [],
        },
        requestTypes: [],
        navigateToDraftPage: false,
      };
    }

    case actionTypes.CLIENT_REQUEST_STEP_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        clientRequestLoading: flag,
      };
    }

    case actionTypes.CHANGE_CLIENT_REQUEST_NAME: {
      const { data } = action.payload;
      return {
        ...state,
        clientRequestName: data,
      };
    }
    case actionTypes.CHANGE_REQUEST_TYPES: {
      const { data } = action.payload;
      return {
        ...state,
        requestTypes: data,
      };
    }

    case actionTypes.DELETE_KITCHEN_MEDIA: {
      const { data } = action.payload;
      return {
        ...state,
        deletedMedia: data,
      };
    }

    case actionTypes.SET_STEP_3_VALUES: {
      const { data } = action.payload;
      return {
        ...state,
        step3OptionValues: data,
      };
    }

    case actionTypes.CHANGE_CLIENT_REQUEST_TYPE: {
      const { data } = action.payload;
      return {
        ...state,
        clientRequestType: data,
      };
    }

    case actionTypes.STEP_1_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step1Complete: flag,
      };
    }

    case actionTypes.STEP_2_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step2Complete: flag,
      };
    }

    case actionTypes.STEP_3_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step3Complete: flag,
      };
    }

    case actionTypes.STEP_4_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step4Complete: flag,
      };
    }

    case actionTypes.STEP_5_COMPLETE: {
      const { flag } = action.payload;
      return {
        ...state,
        step5Complete: flag,
      };
    }

    case actionTypes.SET_REQUEST_ID: {
      const { data } = action.payload;
      return {
        ...state,
        requestId: data,
      };
    }

    case actionTypes.SUCCESS_REQUEST_ID: {
      const { data } = action.payload;
      return {
        ...state,
        requestIdSuccess: data,
      };
    }

    case actionTypes.SET_CLIENT_REQUEST_STEP_3_OPTIONS: {
      const { data, values } = action.payload;
      // let valueData = {};
      // Object.keys(data).map((key, index) => {
      //   valueData[key] = {
      //     option: data[key]["option"],
      //     data: [],
      //   };
      // });
      let valueData = {};
      Object.keys(data).map((key) => {
        if (key === "appliances" || key === "loose-appliances") {
          if (values[key] && values[key]["data"]) {
            for (var i = 0; i < data[key]["data"].length; i++) {
              for (var j = 0; j < values[key]["data"].length; j++) {
                if (
                  data[key]["data"][i]["category_option_id"] ===
                  values[key]["data"][j]["category_option_id"]
                ) {
                  if (values[key]["data"][j]["child_data"].length) {
                    data[key]["data"][i]["category_option_value"] =
                      values[key]["data"][j]["category_option_value"];
                  } else {
                    let filterOutDropdown = data[key]["data"][i]["child_data"].filter(
                      (x) => x && (x.category_option_type == "input-text" || x.category_option_type == "input-number")
                    );
                    values[key]["data"][j]["child_data"] =
                      filterOutDropdown;
                    data[key]["data"][i]["category_option_value"] =
                      values[key]["data"][j]["category_option_value"];
                  }
                    
                    let width = values[key]["data"][j]["child_data"].filter(
                      (x) => x && x.name == "Width"
                    );
                    let brand = values[key]["data"][j]["child_data"].filter(
                      (x) => x && x.name == "Brand & Model"
                    );
                    let depth = values[key]["data"][j]["child_data"].filter(
                      (x) => x && x.name == "Depth"
                    );
                    let height = values[key]["data"][j]["child_data"].filter(
                      (x) => x && x.name == "Height"
                    );

                    let dropdownOption = values[key]["data"][j]["child_data"].filter(
                      (x) => x && x.category_option_type == "dropdown"
                    );
                    
                    if(brand.length){
                      values[key]["data"][j]["child_data"][0] = brand[0];
                    }else{
                      values[key]["data"][j]["child_data"][0] = data[key]["data"][i]["child_data"][0];
                    }
                    if(depth.length){
                      values[key]["data"][j]["child_data"][1] = depth[0];
                    }else{
                      values[key]["data"][j]["child_data"][1] = data[key]["data"][i]["child_data"][1];
                    }
                    if(width.length){
                      values[key]["data"][j]["child_data"][2] = width[0];
                    }else{
                      values[key]["data"][j]["child_data"][2] = data[key]["data"][i]["child_data"][2];
                    }
                    if(height.length){
                      values[key]["data"][j]["child_data"][3] = height[0];
                    }else{
                      values[key]["data"][j]["child_data"][3] = data[key]["data"][i]["child_data"][3];
                    }

                    if(dropdownOption.length){
                      dropdownOption[0]['selected'] = true;
                      values[key]["data"][j]["child_data"][4] = dropdownOption[0];
                    }
                    
                    // for (
                    //   var l = 0;
                    //   l < values[key]["data"][j]["child_data"].length;
                    //   l++
                    // ) {
                    //   if (
                    //     data[key]["data"][i]["child_data"][k]["id"] ===
                    //     values[key]["data"][j]["child_data"][l]["id"]
                    //   ) {
                    //     data[key]["data"][i]["child_data"][k] =
                    //       values[key]["data"][j]["child_data"][l];
                    //   }
                    // }
                  
                }
              }
            }
          }

          valueData[key] = {
            option: data[key]["option"],
            data: values[key] && values[key]["data"] ? values[key]["data"] : [],
          };
        } else {
          valueData[key] = {
            option: data[key]["option"],
            data: values[key] && values[key]["data"] ? values[key]["data"] : [],
          };
        }
      });
      return {
        ...state,
        step3OptionObject: data,
        step3OptionValues: valueData,
      };
    }

    case actionTypes.CHANGE_STEP_3_OPTIONS_VALUE: {
      const { value, categoryName, optionId, type, indexChild, isPopup } =
        action.payload;
      const optionObject = JSON.parse(JSON.stringify(state.step3OptionValues));
      const mainObject = JSON.parse(JSON.stringify(state.step3OptionObject));
      switch (type) {
        case "dropdown":
          let categoryObject = optionObject[categoryName];
          let categoryObjectData = categoryObject["data"];
          let dropdownValueIndex = categoryObjectData.findIndex(
            (x) => x.category_option_type === "dropdown"
          );
          if (value !== "Select") {
            let object = {};
            let dropdownObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === Number(optionId));
            if (dropdownObjectIndex !== -1) {
              object = mainObject[categoryName]["data"][dropdownObjectIndex];
            }
            if (dropdownValueIndex !== -1) {
              optionObject[categoryName]["data"][dropdownValueIndex] = object;
            } else {
              optionObject[categoryName]["data"].push(object);
            }
          } else {
            if (dropdownValueIndex !== -1) {
              optionObject[categoryName]["data"].splice(dropdownValueIndex, 1);
            }
          }
          break;

        case "input-text":
          if (value !== "") {
            let object = {};
            let inputTextObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (inputTextObjectIndex !== -1) {
              object = mainObject[categoryName]["data"][inputTextObjectIndex];
              object["category_option_value"] = value;
              let inputTextValueObjectIndex = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
              if (inputTextValueObjectIndex !== -1) {
                optionObject[categoryName]["data"][inputTextValueObjectIndex][
                  "category_option_value"
                ] = value;
              } else {
                optionObject[categoryName]["data"].push(object);
              }
            }
          } else {
            let inputTextObjectIndex = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (inputTextObjectIndex !== -1) {
              optionObject[categoryName]["data"].splice(
                inputTextObjectIndex,
                1
              );
            }
          }
          break;
        case "checkbox":
          if (value) {
            let object = {};
            let checkboxObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);

            if (checkboxObjectIndex !== -1) {
              object = mainObject[categoryName]["data"][checkboxObjectIndex];
              object["category_option_value"] = value;
              let checkboxObjectValueIndex = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
              // if(categoryName == "appliances" || categoryName == "loose_appliances"){

              //   for(let i=0;i<4;i++){

              //   }
              // }
              let objectNew = JSON.parse(JSON.stringify(object))
              let filterOutDropdown = objectNew['child_data'].filter(
                (x) => x && (x.category_option_type == "input-text" || x.category_option_type == "input-number")
              );
              objectNew['child_data'] = filterOutDropdown;
              
              if (checkboxObjectValueIndex !== -1) {
                optionObject[categoryName]["data"][checkboxObjectValueIndex] =
                objectNew;
              } else {
                optionObject[categoryName]["data"].push(objectNew);
              }
              
            }
          } else {
            let checkboxObjectIndex = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (checkboxObjectIndex !== -1) {
              optionObject[categoryName]["data"].splice(checkboxObjectIndex, 1);
            }
          }
          
          break;
          case "appliances":
            if(isPopup){                   
                let applianceObjectIndex = mainObject[categoryName][
                  "data"
                ].findIndex((x) => x.category_option_id === optionId);
                if (applianceObjectIndex !== -1) {
                  let applianceValueObjectIndex = optionObject[categoryName][
                    "data"
                  ].findIndex((x) => x.category_option_id === optionId);
                  if (applianceValueObjectIndex !== -1) {
                    optionObject[categoryName]["data"][applianceValueObjectIndex][
                      "child_data"
                    ][indexChild]["value"] = value;
                  }
                }
            }else{
              let object = {};
              let dropdownObjectIndex = mainObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === Number(optionId));
              let dropdownObjectIndexi = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === Number(optionId));
              
              if (value !== "Select") {
                if (dropdownObjectIndex !== -1) {
                  
                  object = JSON.parse(JSON.stringify(mainObject[categoryName]["data"][dropdownObjectIndex]));
                  
                  let dropdownValueOptions = object['child_data'].filter(
                    (x) => x.category_option_type === "dropdown"
                  );
                  let inputNumberText = object['child_data'].filter(
                    (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                  );
                  if(optionObject[categoryName]['data'].length){
                    let inputNumberTextValue = optionObject[categoryName]['data'][dropdownObjectIndexi]['child_data'].filter(
                      (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                    );
                    inputNumberText.forEach(function(v,i){
                      inputNumberText[i] = inputNumberTextValue[i]
                    });
                    
                  }
                  let getSelectedDropdown;
                  dropdownValueOptions.forEach(function(v,i){
                    if(v.id == Number(value)){
                      v['selected'] = true;
                      getSelectedDropdown = v;
                    }
                  });
                  inputNumberText.push(getSelectedDropdown);
                  optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = inputNumberText;
                }
              } else {
                if (dropdownObjectIndexi !== -1) {
                  optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = [];
                }
              }
            }
            break;
        case "loose-appliances":
          if(isPopup){                   
            let applianceObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === optionId);
            if (applianceObjectIndex !== -1) {
              let applianceValueObjectIndex = optionObject[categoryName][
                "data"
              ].findIndex((x) => x.category_option_id === optionId);
              if (applianceValueObjectIndex !== -1) {
                optionObject[categoryName]["data"][applianceValueObjectIndex][
                  "child_data"
                ][indexChild]["value"] = value;
              }
            }
          }else{
            let object = {};
            let dropdownObjectIndex = mainObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === Number(optionId));
            let dropdownObjectIndexi = optionObject[categoryName][
              "data"
            ].findIndex((x) => x.category_option_id === Number(optionId));
            
            if (value !== "Select") {
              if (dropdownObjectIndex !== -1) {
                
                object = JSON.parse(JSON.stringify(mainObject[categoryName]["data"][dropdownObjectIndex]));
                
                let dropdownValueOptions = object['child_data'].filter(
                  (x) => x.category_option_type === "dropdown"
                );
                let inputNumberText = object['child_data'].filter(
                  (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                );
                if(optionObject[categoryName]['data'].length){
                  let inputNumberTextValue = optionObject[categoryName]['data'][dropdownObjectIndexi]['child_data'].filter(
                    (x) => x.category_option_type === "input-number" || x.category_option_type === "input-text"
                  );
                  inputNumberText.forEach(function(v,i){
                    inputNumberText[i] = inputNumberTextValue[i]
                  });
                  
                }
                let getSelectedDropdown;
                dropdownValueOptions.forEach(function(v,i){
                  if(v.id == Number(value)){
                    v['selected'] = true;
                    getSelectedDropdown = v;
                  }
                });
                inputNumberText.push(getSelectedDropdown);
                optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = inputNumberText;
              }
            } else {
              if (dropdownObjectIndexi !== -1) {
                optionObject[categoryName]["data"][dropdownObjectIndexi]['child_data'] = [];
              }
            }
          }
          break;
        default:
      }
      return {
        ...state,
        step3OptionValues: optionObject,
      };
    }

    case actionTypes.CHANGE_KITCHEN_MEASUREMENT: {
      const { data } = action.payload;
      return {
        ...state,
        kitchenMeasurement: data,
      };
    }

    case actionTypes.CHANGE_KITCHEN_PHOTOS: {
      const { data } = action.payload;
      return {
        ...state,
        kitchenPhotos: data,
      };
    }

    case actionTypes.CHANGE_KITCHEN_KIT: {
      const { data } = action.payload;
      return {
        ...state,
        kitchenKit: data,
      };
    }

    case actionTypes.CHANGE_SECTION_PROPERLY_LABELED: {
      const { flag } = action.payload;
      return {
        ...state,
        allSectionProperlyLabeled: flag,
      };
    }

    case actionTypes.CHANGE_TEXT_EDITOR_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        textEditorData: data,
      };
    }

    case actionTypes.CHANGE_TEXT_EDITOR_DATA_JSON: {
      const { data } = action.payload;
      return {
        ...state,
        textEditorDataJson: data,
      };
    }

    case actionTypes.CHANGE_DUE_DATE: {
      const { data } = action.payload;
      return {
        ...state,
        dueDate: data,
      };
    }
    case actionTypes.CHANGE_NOTE: {
      const { data } = action.payload;
      return {
        ...state,
        note: data,
      };
    }
    case actionTypes.NAVIGATE_TO_DRAFT_PAGE: {
      const { flag } = action.payload;
      return {
        ...state,
        navigateToDraftPage: flag,
      };
    }
    case actionTypes.CLEAR_NAVIGATE_TO_DRAFT_FLAG: {
      return {
        ...state,
        navigateToDraftPage: false,
      };
    }

    case actionTypes.GET_DOOR_DETAILS_INIT: {
      return {
        ...state,
        step3OptionValues: {
          ...state.step3OptionValues,
          "door-style": { ...state.step3OptionValues["door-style"], data: [] },
        },
        doorDetailsFetch: {
          ...state.doorDetailsFetch,
          isFetchingDoorDetails: true,
          doorDetailsData: [],
          isFetchingDoorDetailsFailed: false,
          isFetchingDoorDetailsFailedMessage: "",
        },
      };
    }

    case actionTypes.GET_DOOR_DETAILS_SUCCESS: {
      return {
        ...state,
        step3OptionObject: {
          ...state.step3OptionObject,
          "door-style": {
            ...state.step3OptionObject["door-style"],
            data:
              action.payload?.length > 0
                ? action.payload
                : [{ category_option_type: "dropdown" }],
          },
        },
        doorDetailsFetch: {
          ...state.doorDetailsFetch,
          isFetchingDoorDetails: false,
          doorDetailsData: action.payload,
          isFetchingDoorDetailsFailed: false,
          isFetchingDoorDetailsFailedMessage: "",
        },
      };
    }

    case actionTypes.GET_DOOR_DETAILS_FAILURE: {
      return {
        ...state,
        doorDetailsFetch: {
          ...state.doorDetailsFetch,
          isFetchingDoorDetails: false,
          doorDetailsData: [],
          isFetchingDoorDetailsFailed: true,
          isFetchingDoorDetailsFailedMessage: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export const actions = {
  changeRequestTypes: (data) => ({
    type: actionTypes.CHANGE_REQUEST_TYPES,
    payload: { data },
  }),
  changeDeleteKitchenMedia: (data) => ({
    type: actionTypes.DELETE_KITCHEN_MEDIA,
    payload: { data },
  }),
  setStep3Values: (data) => ({
    type: actionTypes.SET_STEP_3_VALUES,
    payload: { data },
  }),
  clearRequestData: () => ({
    type: actionTypes.CLEAR_REQUEST_DATA,
  }),
  changeClientRequestType: (data) => ({
    type: actionTypes.CHANGE_CLIENT_REQUEST_TYPE,
    payload: { data },
  }),
  changeClientRequestName: (data) => ({
    type: actionTypes.CHANGE_CLIENT_REQUEST_NAME,
    payload: { data },
  }),
  clientRequestStep1Submit: (data, clientAccessToken) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_1_SUBMIT,
    payload: { data, clientAccessToken },
  }),
  clientRequestStepLoading: (flag) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_LOADING,
    payload: { flag },
  }),
  step1Complete: (flag) => ({
    type: actionTypes.STEP_1_COMPLETE,
    payload: { flag },
  }),
  step2Complete: (flag) => ({
    type: actionTypes.STEP_2_COMPLETE,
    payload: { flag },
  }),
  step3Complete: (flag) => ({
    type: actionTypes.STEP_3_COMPLETE,
    payload: { flag },
  }),
  step4Complete: (flag) => ({
    type: actionTypes.STEP_4_COMPLETE,
    payload: { flag },
  }),
  step5Complete: (flag) => ({
    type: actionTypes.STEP_5_COMPLETE,
    payload: { flag },
  }),
  clientRequestStep2Submit: (data, clientAccessToken, isDraft) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_2_SUBMIT,
    payload: { data, clientAccessToken, isDraft },
  }),
  changeRequestId: (data) => ({
    type: actionTypes.SET_REQUEST_ID,
    payload: { data },
  }),
  changeRequestIdSuccess: (data) => ({
    type: actionTypes.SUCCESS_REQUEST_ID,
    payload: { data },
  }),
  changeStep3Option: (data, values) => ({
    type: actionTypes.SET_CLIENT_REQUEST_STEP_3_OPTIONS,
    payload: { data, values },
  }),
  getStep3Option: (requestId, clientAccessToken) => ({
    type: actionTypes.GET_CLIENT_REQUEST_STEP_3_OPTIONS,
    payload: { requestId, clientAccessToken },
  }),
  changeStep3OptionsValue: (
    value,
    categoryName,
    optionId,
    type,
    indexChild = null,
    isPopup = null,
  ) => ({
    type: actionTypes.CHANGE_STEP_3_OPTIONS_VALUE,
    payload: { value, categoryName, optionId, type, indexChild, isPopup },
  }),
  clientRequestStep3Submit: (data, clientAccessToken, isDraft) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_3_SUBMIT,
    payload: { data, clientAccessToken, isDraft },
  }),
  changeKitchenMeasurement: (data) => ({
    type: actionTypes.CHANGE_KITCHEN_MEASUREMENT,
    payload: { data },
  }),
  changeKitchenPhotos: (data) => ({
    type: actionTypes.CHANGE_KITCHEN_PHOTOS,
    payload: { data },
  }),
  changeKitchenKit: (data) => ({
    type: actionTypes.CHANGE_KITCHEN_KIT,
    payload: { data },
  }),
  changeSectionProperlyLabeled: (flag) => ({
    type: actionTypes.CHANGE_SECTION_PROPERLY_LABELED,
    payload: { flag },
  }),
  clientRequestStep4Submit: (clientAccessToken, isDraft) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_4_SUBMIT,
    payload: { clientAccessToken, isDraft },
  }),
  changeTextEditorData: (data) => ({
    type: actionTypes.CHANGE_TEXT_EDITOR_DATA,
    payload: { data },
  }),
  changeTextEditorDataJson: (data) => ({
    type: actionTypes.CHANGE_TEXT_EDITOR_DATA_JSON,
    payload: { data },
  }),
  changeDueDate: (data) => ({
    type: actionTypes.CHANGE_DUE_DATE,
    payload: { data },
  }),
  changeNote: (data) => ({
    type: actionTypes.CHANGE_NOTE,
    payload: { data },
  }),
  clientRequestStep5Submit: (currentComment,clientAccessToken, isDraft) => ({
    type: actionTypes.CLIENT_REQUEST_STEP_5_SUBMIT,
    payload: { clientAccessToken, isDraft,currentComment },
  }),
  getStepData: (step, requestID, clientAccessToken) => ({
    type: actionTypes.GET_STEP_DATA,
    payload: { step, requestID, clientAccessToken },
  }),
  navigateToDraftPage: (flag) => ({
    type: actionTypes.NAVIGATE_TO_DRAFT_PAGE,
    payload: { flag },
  }),
  clearNavigateToDraftFlag: () => ({
    type: actionTypes.CLEAR_NAVIGATE_TO_DRAFT_FLAG,
  }),
  getLatestDoorDetails: (doorId, clientAccessToken) => ({
    type: actionTypes.GET_DOOR_DETAILS_INIT,
    payload: { doorId, clientAccessToken },
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_1_SUBMIT,
    clientRequestStep1SubmitSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_2_SUBMIT,
    clientRequestStep2SubmitSaga
  );
  yield takeLatest(
    actionTypes.GET_CLIENT_REQUEST_STEP_3_OPTIONS,
    getStep3OptionsSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_3_SUBMIT,
    clientRequestStep3SubmitSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_4_SUBMIT,
    clientRequestStep4SubmitSaga
  );
  yield takeLatest(
    actionTypes.CLIENT_REQUEST_STEP_5_SUBMIT,
    clientRequestStep5SubmitSaga
  );
  yield takeLatest(actionTypes.GET_STEP_DATA, getStepDataSaga);
  yield takeLatest(actionTypes.GET_DOOR_DETAILS_INIT, getLatestDoorDetailsSaga);
}

function* clientRequestStep1SubmitSaga(action) {
  let { data, clientAccessToken } = action.payload;
  const { user } = yield select((state) => state.auth);
  let submitData = queryString.stringify({
    client_id: user.parent_id ? user.parent_id : user.id,
    step: 1,
    request_title: data,
  });

  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_STEP_1_URL"),
      clientAccessToken,
      submitData
    );
    if (response.status === 200) {
      yield all([
        put(actions.clientRequestStepLoading(false)),
        put(actions.step1Complete(true)),
      ]);
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 1",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 1",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* clientRequestStep2SubmitSaga(action) {
  let { data, clientAccessToken, isDraft } = action.payload;
  const { requestId } = yield select((state) => state.clientRequest);
  const { user } = yield select((state) => state.auth);
  let submitData;
  if (requestId) {
    submitData = queryString.stringify({
      client_id: user.parent_id ? user.parent_id : user.id,
      step: 2,
      request_title: data.request_title,
      request_type: data.request_type,
      request_id: requestId,
      is_draft: isDraft,
    });
  } else {
    submitData = queryString.stringify({
      client_id: user.parent_id ? user.parent_id : user.id,
      step: 2,
      request_title: data.request_title,
      request_type: data.request_type,
      is_draft: isDraft,
    });
  }

  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_STEP_2_URL"),
      clientAccessToken,
      submitData
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (isDraft) {
          if (requestId) {
            yield all([put(actions.clientRequestStepLoading(false))]);
          } else {
            yield all([
              put(actions.changeRequestId(responseData.data.request_id)),
              put(actions.clientRequestStepLoading(false)),
            ]);
          }
          yield put(actions.navigateToDraftPage(true));
          yield put(actions.clearRequestData());
        } else {
          if (requestId) {
            yield all([
              put(actions.clientRequestStepLoading(false)),
              put(actions.step2Complete(true)),
            ]);
          } else {
            yield all([
              put(actions.changeRequestId(responseData.data.request_id)),
              put(actions.clientRequestStepLoading(false)),
              put(actions.step2Complete(true)),
            ]);
          }
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 2",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 2",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getStep3OptionsSaga(action) {
  let { requestId, clientAccessToken } = action.payload;
  yield all([put(actions.clientRequestStepLoading(true))]);
  //const { user } = yield select((state) => state.auth);
  const filterParams = {
    step: 3,
    // client_id: user.id,
    request_id: requestId,
  };

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_STEP_3_GET_URL"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.clientRequestStepLoading(false)),
          put(
            actions.changeStep3Option(
              responseData.data.dynamic_form_data,
              responseData.data.current_form_data
            )
          ),
        ]);
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 3",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 3",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getStepDataSaga(action) {
  let { step, requestID, clientAccessToken } = action.payload;
  yield all([put(actions.clientRequestStepLoading(true))]);
  //const { user } = yield select((state) => state.auth);
  let filterParams = {
    step: step,
    // client_id: user.id,
    request_id: requestID,
  };
  if (requestID) {
    filterParams = {
      step: step,
      // client_id: user.id,
      request_id: requestID,
    };
  } else {
    filterParams = {
      step: step,
      // client_id: user.id,
    };
  }
  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_REQUEST_STEP_3_GET_URL"),
      queryString.stringify(filterParams),
      clientAccessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (step === 1) {
          if (requestID && responseData.data.current_form_data) {
            yield all([
              put(
                actions.changeClientRequestName(
                  responseData.data.current_form_data.request_title
                )
              ),
            ]);
          }
        }
        if (step === 2) {
          if (requestID) {
            if (
              responseData.data.current_form_data &&
              responseData.data.current_form_data.request_type
            ) {
              yield all([
                put(
                  actions.changeClientRequestType(
                    responseData.data.current_form_data.request_type.toString()
                  )
                ),
                put(
                  actions.changeRequestTypes(responseData.data.request_types)
                ),
              ]);
            }
            yield all([
              put(actions.changeRequestTypes(responseData.data.request_types)),
            ]);
          } else {
            yield all([
              put(actions.changeRequestTypes(responseData.data.request_types)),
            ]);
          }
        }

        if (step === 4) {
          let kitchenPhotostemp = [],
            kitchenKittemp = [],
            kitchenMeasurementtemp = [];
          if (requestID) {
            if (responseData.data.kit_file.length) {
              Object.keys(responseData.data.kit_file).map((keyi, indexi) => {
                kitchenKittemp.push({
                  type: "ext",
                  name: responseData.data.kit_file[keyi]["name"],
                  size: "",
                  id: responseData.data.kit_file[keyi]["id"],
                  data: responseData.data.kit_file[keyi]["url"],
                  blob: "../../assets/images/Text_Page_Icon.svg",
                });
              });
              yield all([put(actions.changeKitchenKit(kitchenKittemp))]);
            }
            if (responseData.data.kitchen_photo.length) {
              Object.keys(responseData.data.kitchen_photo).map(
                (keyi, indexi) => {
                  kitchenPhotostemp.push({
                    type: "ext",
                    name: responseData.data.kitchen_photo[keyi]["name"],
                    size: "",
                    id: responseData.data.kitchen_photo[keyi]["id"],
                    data: responseData.data.kitchen_photo[keyi]["url"],
                    blob: responseData.data.kitchen_photo[keyi]["url"],
                  });
                }
              );
              yield all([put(actions.changeKitchenPhotos(kitchenPhotostemp))]);
            }
            if (responseData.data.kitchen_measurement.length) {
              Object.keys(responseData.data.kitchen_measurement).map(
                (keyi, indexi) => {
                  let fileExtention = /[.]/.exec(
                    responseData.data.kitchen_measurement[keyi]["url"]
                  )
                    ? /[^.]+$/.exec(
                        responseData.data.kitchen_measurement[keyi]["url"]
                      )
                    : undefined;
                  kitchenMeasurementtemp.push({
                    type: "ext",
                    name: responseData.data.kitchen_measurement[keyi]["name"],
                    size: "",
                    id: responseData.data.kitchen_measurement[keyi]["id"],
                    data: responseData.data.kitchen_measurement[keyi]["url"],
                    blob:
                      fileExtention[0] === "pdf"
                        ? PdfIcon
                        : responseData.data.kitchen_measurement[keyi]["url"],
                  });
                }
              );
              yield all([
                put(actions.changeKitchenMeasurement(kitchenMeasurementtemp)),
              ]);
            }

            yield all([
              put(
                actions.changeSectionProperlyLabeled(
                  // eslint-disable-next-line eqeqeq
                  responseData.data.is_all_section_properly_labeled == 1
                    ? true
                    : false
                )
              ),
            ]);
          }
        }
        if (step === 5) {
          if (requestID) {
            if (responseData.data.current_form_data.due_date) {
              yield all([
                put(
                  actions.changeDueDate(responseData.data.current_form_data.due_date
                  )
                ),
                put(
                  actions.changeNote(responseData.data.current_form_data.additional_note
                  )
                ),
              ]);
            }        
          }
        }
        yield all([put(actions.clientRequestStepLoading(false))]);
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step Data",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step Data",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* clientRequestStep3SubmitSaga(action) {
  let { clientAccessToken, isDraft } = action.payload;
  const { requestId, step3OptionValues } = yield select(
    (state) => state.clientRequest
  );
  const { user } = yield select((state) => state.auth);
  let submitData = {
    client_id: user.parent_id ? user.parent_id : user.id,
    step: 3,
    dynamic_form_data: step3OptionValues,
    request_id: requestId,
    is_draft: isDraft,
  };
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiTokenJson,
      apiUrl("CLIENT_REQUEST_STEP_3_URL"),
      clientAccessToken,
      JSON.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (isDraft) {
          yield all([put(actions.clientRequestStepLoading(false))]);
          yield put(actions.navigateToDraftPage(true));
          yield put(actions.clearRequestData());
        } else {
          yield all([
            put(actions.clientRequestStepLoading(false)),
            put(actions.step3Complete(true)),
          ]);
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 3",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 3",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* clientRequestStep4SubmitSaga(action) {
  let { clientAccessToken, isDraft } = action.payload;
  const {
    requestId,
    allSectionProperlyLabeled,
    kitchenMeasurement,
    kitchenPhotos,
    kitchenKit,
    clientRequestType,
    deletedMedia,
  } = yield select((state) => state.clientRequest);
  let kitchenMeasurementtemp = [];
  let kitchenPhotostemp = [];
  let kitchenKittemp = [];
  let checkUrl;
  function isURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  }
  for (var i = 0; i < kitchenMeasurement.length; i++) {
    checkUrl = isURL(kitchenMeasurement[i]["data"]);
    if (checkUrl) {
    } else {
      kitchenMeasurementtemp.push(kitchenMeasurement[i]["data"]);
    }
  }
  for (var i = 0; i < kitchenPhotos.length; i++) {
    checkUrl = isURL(kitchenPhotos[i]["data"]);
    if (checkUrl) {
    } else {
      kitchenPhotostemp.push(kitchenPhotos[i]["data"]);
    }
  }

 
  for (var i = 0; i < kitchenKit.length; i++) {
    checkUrl = isURL(kitchenKit[i]["data"]);
    if (checkUrl) {
    } else {
      kitchenKittemp.push(kitchenKit[i]["data"]);
    }
  }
  

  var formData = new FormData();
  const { user } = yield select((state) => state.auth);

  formData.append("deleted_media", JSON.stringify(deletedMedia));
  formData.append("client_id", user.parent_id ? user.parent_id : user.id);
  formData.append("is_draft", isDraft);
  formData.append("step", 4);
  formData.append("request_id", requestId);
  formData.append("request_type", clientRequestType);
  formData.append(
    "is_all_section_properly_labeled",
    allSectionProperlyLabeled ? 1 : 0
  );

  for (var i = 0; i < kitchenMeasurementtemp.length; i++) {
    formData.append("kitchen_measurements[]", kitchenMeasurementtemp[i]);
  }
  for (var i = 0; i < kitchenPhotostemp.length; i++) {
    formData.append("kitchen_photos[]", kitchenPhotostemp[i]);
  }
  
  for (var i = 0; i < kitchenKittemp.length; i++) {
    formData.append("kit_files[]", kitchenKittemp[i]);
  }
  

  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiTokenFormData,
      apiUrl("CLIENT_REQUEST_STEP_4_URL"),
      clientAccessToken,
      formData
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (isDraft) {
          yield all([put(actions.clientRequestStepLoading(false))]);
          yield put(actions.navigateToDraftPage(true));
          yield put(actions.clearRequestData());
        } else {
          yield all([
            put(actions.clientRequestStepLoading(false)),
            put(actions.step4Complete(true)),
            put(actions.changeKitchenKit([])),
            put(actions.changeKitchenMeasurement([])),
            put(actions.changeKitchenPhotos([])),
          ]);
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 4",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 4",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* clientRequestStep5SubmitSaga(action) {
  let { clientAccessToken, isDraft,currentComment } = action.payload;
  const { requestId, dueDate } =
    yield select((state) => state.clientRequest);
  var date = new Date(dueDate),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    var due_date = [date.getFullYear(), mnth, day].join("-");
    if(!isDraft) {
      var date1 = new Date(),
      mnth1 = ("0" + (date1.getMonth() + 1)).slice(-2),
      day1 = ("0" + date1.getDate()).slice(-2);
      var temp_date = [date1.getFullYear(), mnth1, day1].join("-");

      if(due_date < temp_date) {
        due_date = temp_date;
      }
    }
  const { user } = yield select((state) => state.auth);
  let submitData = {
    client_id: user.parent_id ? user.parent_id : user.id,
    step: 5,
    request_id: requestId,
    due_date: due_date,
    additional_note: currentComment,
    is_draft: isDraft,
  };
  yield all([put(actions.clientRequestStepLoading(true))]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_REQUEST_STEP_5_URL"),
      clientAccessToken,
      queryString.stringify(submitData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (isDraft) {
          yield all([put(actions.clientRequestStepLoading(false))]);
          yield put(actions.navigateToDraftPage(true));
          yield put(actions.clearRequestData());
        } else {
          yield all([
            put(actions.clientRequestStepLoading(false)),
            put(actions.step5Complete(true)),
            put(
              actions.changeRequestIdSuccess(responseData.data.request_number)
            ),
            put(actions.clearRequestData()),
          ]);
        }
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.logout());
      } else {
        yield all([put(actions.clientRequestStepLoading(false))]);
        yield put(
          generalActions.addToast(
            "Client Request Step 5",
            "Something went wrong!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.clientRequestStepLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Client Request Step 5",
          errorData.message,
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.clientRequestStepLoading(false))]);
  }
}

function* getLatestDoorDetailsSaga(action) {
  let { doorId, clientAccessToken } = action.payload;
  try {
    let response = yield call(
      fetchDoorDetails,
      apiUrl("CLIENT_GET_DOOR_STYLE"),
      doorId,
      clientAccessToken
    );
    let responseData = yield call([response, response.json]);
    if (response) {
      yield put({
        type: actionTypes.GET_DOOR_DETAILS_SUCCESS,
        payload: responseData?.data,
      });      
    }
  } catch (e) {
    yield put({
      type: actionTypes.GET_DOOR_DETAILS_FAILURE,
      payload: e,
    });
  }
}
