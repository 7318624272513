/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useCookies } from "react-cookie";
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";
import { postApiTokenFormData } from "../../redux/apis";
import apiUrl from "../../config/urls";
import ShortUniqueId from "short-unique-id";
import PdfIcon from "../../assets/images/PDF.svg";
import DocIcon from "../../assets/images/Doc.svg";
import KitIcon from "../../assets/images/kit.png";
import FileIcon from "../../assets/images/file.png";
import PptIcon from "../../assets/images/PPT.svg";
import XlsIcon from "../../assets/images/XLS.svg";
import "./index.scss";

function EditRequestKitchenPhotos({ data, requestId }) {
  const dispatch = useDispatch();
  const uid = new ShortUniqueId();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { isAllsectionproperlyLabeled, removedImages } = useSelector(
    (state) => state.myRequest
  );
  let [images, setImages] = useState("");
  let [
    dropAreaClassForKitchenMeasurement,
    setDropAreaClassForKitchenMeasurement,
  ] = useState("kitchen-measurement");
  let [
    dropAreaClassForKitchenPhoto,
    setDropAreaClassForKitchenPhoto,
  ] = useState("kitchen-measurement");

  useEffect(() => {
    if (data && data["images"]) {
      setImages(data["images"]);
      dispatch(myRequestActions.changeEditRequestUpdatedMedia(data["images"]));
      dispatch(
        myRequestActions.changeAllSectionProperlyLabeled(
          data["is_all_section_properly_labeled"] === 1 ? true : false
        )
      );
    }
  }, [data, dispatch]);

  const addKitchenMeasurement = (e) => {
    let imgArr = [];
    imgArr.push(e.target.files);
    let fileArr = images[e.target.id];
    let fileExtention;
    let filevalidation = false;
    let fileExtentionvalidation = { type: "", flag: false };
    let allowedFileExtention = ["pdf", "png", "jpg", "jpeg"];
    if (e.target.id === "kit_file") {
      allowedFileExtention = ["kit", "KIT"];
    }
    if (e.target.id === "kitchen_measurement") {
      allowedFileExtention = ["pdf", "png", "jpg", "jpeg", "kit","doc","xls","xlsx","ppt","pptx","docx","webp","heic"];
    }
    if (e.target.id === "kitchen_photo") {
      allowedFileExtention = ["png", "jpg", "jpeg", "webp", "heic"];
    }

    for (let i = 0; i < imgArr[0].length; i++) {
      fileExtention = /[.]/.exec(imgArr[0][i].name)
        ? /[^.]+$/.exec(imgArr[0][i].name)
        : undefined;        
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      var j = parseInt(
        Math.floor(Math.log(imgArr[0][i].size) / Math.log(1024))
      );
      if (Math.round(imgArr[0][i].size / 1024) > 51200) {
        filevalidation = true;
      } else if (!allowedFileExtention.includes(fileExtention[0])) {        
        fileExtentionvalidation.type = fileExtention[0];
        fileExtentionvalidation.flag = true;
      } else {
        if (fileExtention[0] === "pdf") {
        } else if (fileExtention[0] === "doc" || fileExtention[0] === "docx") {
        } else {
        }
      }
    }
    if (filevalidation) {
      dispatch(
        generalActions.addToast(
          "File Size",
          "Max File Size for each media file: 50 MB",
          "danger",
          uid()
        )
      );
    } else if (fileExtentionvalidation.flag) {
      dispatch(
        generalActions.addToast(
          "File Type",
          fileExtentionvalidation.type + " file not allowed for upload",
          "danger",
          uid()
        )
      );
    } else {
      dispatch(myRequestActions.myRequestListingLoading(true));
      let formData = new FormData();
      formData.append("request_id", requestId);
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append(e.target.id + "s", e.target.files[i]);
      }
      postApiTokenFormData(
        apiUrl("CLIENT_REQUEST_KITCHEN_MEDIA_UPLOAD_URL"),
        cookies.clientAccessToken,
        formData
      )
        .then((resp) => resp.json())
        .then(function (response) {
          if (response.data.length > 0) {
            dispatch(myRequestActions.myRequestListingLoading(false));
            Object.keys(removedImages).map((key, index) => {
              if (removedImages[key]) {
                for (var i = 0; i < response.data[0][key].length; i++) {
                  let found = removedImages[key].some(
                    (el) => el.id === response.data[0][key][i]["id"]
                  );
                  if (found) {
                    response.data[0][key].splice(i, 1);
                  }
                }
              }
            });
            setImages(response.data[0]);
            dispatch(
              myRequestActions.changeEditRequestUpdatedMedia(response.data[0])
            );
            e.target.value = null;
          }else{
            dispatch(myRequestActions.myRequestListingLoading(false));
            dispatch(generalActions.addToast(
              "Media Upload Error",
              response.message,
              "danger",
              uid()
            ))
          }
        }).catch(error => {
          console.log("Error : ", error)
          dispatch(myRequestActions.myRequestListingLoading(false));
          dispatch(generalActions.addToast(
          "Media Upload Error",
          error?.response?.data?.message,
          "danger",
          uid()
        ))})
    }
  };
  let dropAreaID, dragAreaID, dragItemID, imageInstance;
  const startDrag = (e, v, i) => {
    if (v) {
      dragAreaID = v;
      dragItemID = i;
    }
  };

  const dragOver = (ev, v) => {
    ev.preventDefault();
  };
  const dragLeave = (ev, v) => {
    // if (ev.target.className == "kitchen-measurement") {
    //   ev.target.style.border = "";
    // }
    ev.preventDefault();
  };

  const dragEnter = (e, v) => {
    e.preventDefault();
    // if (e.target.className == "kitchen-measurement") {
    //   e.target.style.border = "3px dotted red";
    // }
  };
  const drop = (e, id) => {
    if (e.nativeEvent.path[2]["id"]) {
      if (e.nativeEvent.path[2]["id"] === "kitchen_measurement_item") {
        dropAreaID = "kitchen_measurement";
      }
      if (e.nativeEvent.path[2]["id"] === "kitchen_photo_item") {
        dropAreaID = "kitchen_photo";
      }
    }
    if (dropAreaID === dragAreaID) {
    } else {
      imageInstance = JSON.parse(JSON.stringify(images));
      if (imageInstance[dropAreaID] && images[dragAreaID]) {
        imageInstance[dropAreaID].push(images[dragAreaID][dragItemID]);
        imageInstance[dragAreaID].splice(dragItemID, 1);
        setImages(imageInstance);
        dispatch(myRequestActions.changeEditRequestUpdatedMedia(imageInstance));
      }
    }
    // setDropAreaClassForKitchenMeasurement("kitchen-measurement");

    // setDropAreaClassForKitchenPhoto("kitchen-measurement");
  };
  const removeFile = (e) => {
    let temparr = e.target.id.split("-");
    imageInstance = JSON.parse(JSON.stringify(images));
    let removedImagesTemp = JSON.parse(JSON.stringify(removedImages));

    const found = removedImagesTemp[temparr[0]].some(
      (el) => el.id === imageInstance[temparr[0]][temparr[1]]["id"]
    );
    if (found) {
    } else {
      removedImagesTemp[temparr[0]].push({
        id: imageInstance[temparr[0]][temparr[1]]["id"],
      });
    }

    dispatch(myRequestActions.changeRemovedImages(removedImagesTemp));
    dispatch(myRequestActions.changeEditRequestDeletedMedia(removedImagesTemp));
    imageInstance[temparr[0]].splice(temparr[1], 1);
    setImages(imageInstance);
    dispatch(myRequestActions.changeEditRequestUpdatedMedia(imageInstance));
  };

  const changeAllSectionProperlyLabeledCheckbox = (e) => {
    dispatch(
      myRequestActions.changeAllSectionProperlyLabeled(e.target.checked)
    );
  };
  const renderImages = (val) => {
    if (images) {
      var fileSrc;
      if (val === "kit_file") {
        return (
          <div id={val + "_item"} className="kitchen-measurement">
            {Object.keys(images[val]).map((key, index) => {
              return (
                <div key={key} className="measurement-box" id={key}>
                  <div className="img-box">
                    <img
                      className="successfully-img"
                      src={KitIcon}
                      alt="kitchen"
                    />
                    <span
                      className="close-btn"
                      id={val + "-" + index}
                      onClick={removeFile}
                    ></span>
                  </div>
                  <span className="filename">{images[val][key]["name"]}</span>
                </div>
              );
            })}
            <div className="measurement-box">
              <div className="img-box add">
                <label htmlFor={val} className="add-measurement"></label>
                <input
                  style={{ display: "none" }}
                  id={val}
                  type="file"
                  accept=".kit,.KIT"
                  onChange={addKitchenMeasurement}
                  multiple
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            id={val + "_item"}
            className="kitchen-measurement"
            onDragEnter={(e) => dragEnter(e, val)}
            onDragOver={(e) => dragOver(e, val)}
            onDrop={(e) => drop(e, e.target.id)}
            onDragLeave={(e) => dragLeave(e, val)}
          >
            {Object.keys(images[val]).map((key, index) => {
              var fileExtention = /[.]/.exec(images[val][key]["url"])
              ? /[^.]+$/.exec(images[val][key]["url"])
              : undefined;
              if ((fileExtention[0]).toLowerCase() === "pdf") {
                fileSrc = PdfIcon;
              } else if((fileExtention[0]).toLowerCase() === "doc" || (fileExtention[0]).toLowerCase() === "docx"){
                fileSrc = DocIcon;
              } else if((fileExtention[0]).toLowerCase() === "xls" || (fileExtention[0]).toLowerCase() === "xlsx"){
                fileSrc = XlsIcon;
              } else if ((fileExtention[0]).toLowerCase() === "kit") {
                fileSrc = KitIcon;
              } else if((fileExtention[0]).toLowerCase() === "png" || (fileExtention[0]).toLowerCase() === "jpg" || (fileExtention[0]).toLowerCase() === "jpeg"){
                fileSrc = images[val][key]["url"];
              } else if((fileExtention[0]).toLowerCase() === "ppt" || (fileExtention[0]).toLowerCase() === "pptx") {
                fileSrc = PptIcon;
              } else {
                fileSrc = FileIcon;;
              }

              return (
                <div
                  draggable
                  onDragStart={(e) => startDrag(e, val, key)}
                  key={key}
                  className="measurement-box"
                  id={key}
                >
                  <div className="img-box">
                    <img
                      className="successfully-img"
                      src={fileSrc}
                      alt="kitchen"
                    />
                    <span
                      className="close-btn"
                      id={val + "-" + index}
                      onClick={removeFile}
                    ></span>
                  </div>
                  <span className="filename">{images[val][key]["name"]}</span>
                </div>
              );
            })}
            <div className="measurement-box">
              <div className="img-box add">
                <label htmlFor={val} className="add-measurement"></label>
                <input
                  style={{ display: "none" }}
                  id={val}
                  type="file"
                  accept={
                    val === "kitchen_measurement"
                      ? "image/jpeg,image/jpg,image/png,application/pdf,.kit,.KIT,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.webp,.heic"
                      : "image/jpeg,image/jpg,image/png,.webp,.heic"
                  }
                  onChange={addKitchenMeasurement}
                  multiple
                />
              </div>
            </div>
          </div>
        );
      }
    }
  };
  return (
    <div className="editrequest-kitchen-photos">
      <ul>
        <li>
          <strong className="title">Measurements</strong>
          <div className="content">
            {renderImages("kitchen_measurement")}
            <div className="check-box">
              <input
                checked={isAllsectionproperlyLabeled}
                id="all_section_are_properly_labeled"
                className="custom-checkbox"
                type="checkbox"
                onChange={changeAllSectionProperlyLabeledCheckbox}
              />
              <label
                htmlFor="all_section_are_properly_labeled"
                className="label"
              >
                All the section are properly labeled
              </label>
            </div>
          </div>
        </li>
        <li>
          <strong className="title">Kitchen Photo</strong>
          <div className="content">{renderImages("kitchen_photo")}</div>
        </li>
        <li
          style={{
            display:
              data
                ? ""
                : "none",
          }}
        >
          <strong className={data && data["request_type"][0]["slug"] === "marketing-packet" ? "title": "title"}>Kitchen Kit</strong>
          <div className="content">{renderImages("kit_file")}</div>
        </li>
      </ul>
    </div>
  );
}

export default EditRequestKitchenPhotos;
