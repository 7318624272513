import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { actions as AuthActions } from "../../redux/reducers/AuthReducer";

import "./index.scss";

function RequestSuccess() {
  const { requestIdSuccess } = useSelector((state) => state.clientRequest);
  const history = useHistory();
  const [cookies] = useCookies(["clientAccessToken"]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!requestIdSuccess) {
      history.push("/app/client/myrequests/all-requests");
    }
    // if(requestIdSuccess){
    //   window.gtag('config', 'AW-826159004');
    //   window.gtag('event', 'conversion', {'send_to': 'AW-826159004/j1ukCLnfjOwCEJzf-IkD'});
    // }
    dispatch(AuthActions.getUserDetails(cookies.clientAccessToken));
  }, [requestIdSuccess]);

  return (
    <div className="request-success-page">
      <div className="container">
        <div className="request-success-inner">
          <img
            src={require("../../assets/images/success-icon.svg")}
            alt="Your request has been submitted successfully"
          />
          <h1>Your request has been submitted successfully</h1>
          <p>
            Your request number is{" "}
            <Link to="/app/client/myrequests/all-requests" title="request number">
              #{requestIdSuccess}
            </Link>
            , and you can keep track of it under your queue
          </p>
          <Link to="/app/client/myrequests/all-requests">
            <button className="btn">Go back to your requests</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RequestSuccess;
