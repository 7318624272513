const COMPANY_ADDRESS = {
  NAME: "Cabinet IQ",
  LINE_1: "2419 S. Bell Blvd.",
  LINE_2: "",
  CITY: "Cedar Park",
  STATE_CODE: "TX",
  POSTAL_CODE: "78613",
};

const dev = {
  API_ENDPOINT: "http://127.0.0.1:5001/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6Ld_XBwoAAAAAEIUFWuJsP8NDHEDL8GAXHumKBUd",
  STRIPE_KEY:
    "pk_test_51KQTHsEjxIMKvGlZ9a1YmtYAdK3zF8PpUEMOEJP9Hh0npRdqqF0cnmRE8FbBycQ7FisfKvscwGLOdlo3jfR8gSYo00wNLqOCZz",
  COMPANY_ADDRESS,
};

const stage = {
  API_ENDPOINT: "https://stagingapi.cabinetiq.com/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6Ld_XBwoAAAAAEIUFWuJsP8NDHEDL8GAXHumKBUd",
  STRIPE_KEY:
    "pk_test_51KQTHsEjxIMKvGlZ9a1YmtYAdK3zF8PpUEMOEJP9Hh0npRdqqF0cnmRE8FbBycQ7FisfKvscwGLOdlo3jfR8gSYo00wNLqOCZz",
  COMPANY_ADDRESS,
};

const prod = {
  API_ENDPOINT: "https://designapi.cabinetiq.com/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6Ld_XBwoAAAAAEIUFWuJsP8NDHEDL8GAXHumKBUd",
  STRIPE_KEY: "pk_live_hKXT1tonh4wAqqljfWDDo7j1002YHHvE4F",
  COMPANY_ADDRESS,
};

function getConfig() {
  if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "staging") {
    return stage;
  } else if (
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_ENV === "production"
  ) {
    return prod;
  } else {
    return dev;
  }
}

const config = getConfig();

export default config;
