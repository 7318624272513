/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { convertStringToHtml } from '../../Utils/utils'
import PdfIcon from "../../assets/images/PDF.svg";
import DocIcon from "../../assets/images/Doc.svg";
import KitIcon from "../../assets/images/kit.png";
import FileIcon from "../../assets/images/file.png";
import PptIcon from "../../assets/images/PPT.svg";
import XlsIcon from "../../assets/images/XLS.svg";

import "./index.scss";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useDispatch } from "react-redux";
import DownloadWithProgressBar from "../Loader/downloadProgress";
// const fileIcons = {
//   "pdf": PdfIcon,
//   "doc": WordIcon,
//   "docx": WordIcon,
// }

function ViewRequestContent({ data }) {

  const [show, setShow] = useState(false);
  const [nonStandardApp, setNonStandardApp] = useState([])
  const [looseApp, setLooseApp] = useState([])
  const [currentImage, setCurrentImage] = useState("")

  const [cookies] = useCookies(["clientAccessToken"]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      let Brand, Depth, Width, Height, CategoryName;
      let arr = [];
      let valueDropdown;
      if (data.category_data.appliances) {
        data.category_data.appliances.data.forEach(function (i) {
          if (i['child_data'].length) {
            valueDropdown = i['child_data'].filter(
              (x) => x && x.category_option_type == "dropdown"
            );
          } else {
            valueDropdown = [];
          }
          Brand = i['child_data'].filter(
            (x) => x && x.name == "Brand & Model"
          );
          Depth = i['child_data'].filter(
            (x) => x && x.name == "Depth"
          );
          Width = i['child_data'].filter(
            (x) => x && x.name == "Width"
          );
          Height = i['child_data'].filter(
            (x) => x && x.name == "Height"
          );
          CategoryName = i['category_option_name'];
          if (i['is_others'] !== "1") {
            arr.push({
              "brand_style": Brand.length ? Brand[0]['value'] : "",
              "dimension": `
              ${Depth.length ? Depth[0]['value'] : 0} in (D) *
              ${Width.length ? Width[0]['value'] : 0} in (W) * 
              ${Height.length ? Height[0]['value'] : 0} in (H)`,
              "parentCategory": CategoryName + " " + (valueDropdown && valueDropdown.length ? "(" + valueDropdown[0]['name'] + ")" : ""),
            });
          }
        })
        setNonStandardApp(arr)
      }
      if (data.category_data['loose-appliances']) {
        arr = [];
        data.category_data['loose-appliances'].data.forEach(function (i) {
          if (i['child_data'].length) {
            valueDropdown = i['child_data'].filter(
              (x) => x && x.category_option_type == "dropdown"
            );
          } else {
            valueDropdown = [];
          }
          Brand = i['child_data'].filter(
            (x) => x && x.name == "Brand & Model"
          );
          Depth = i['child_data'].filter(
            (x) => x && x.name == "Depth"
          );
          Width = i['child_data'].filter(
            (x) => x && x.name == "Width"
          );
          Height = i['child_data'].filter(
            (x) => x && x.name == "Height"
          );
          CategoryName = i['category_option_name'];
          if (i['is_others'] !== "1") {
            arr.push({
              "brand_style": Brand.length ? Brand[0]['value'] : "",
              "dimension": `
              ${Depth.length ? Depth[0]['value'] : 0} in (D) *
              ${Width.length ? Width[0]['value'] : 0} in (W) * 
              ${Height.length ? Height[0]['value'] : 0} in (H)`,
              "parentCategory": CategoryName + " " + (valueDropdown && valueDropdown.length ? "(" + valueDropdown[0]['name'] + ")" : ""),
            });
          }
          if (i['is_others'] == "1") {

          }
        })
        setLooseApp(arr)
      }
      // let tempData = data?.category_data?.appliances
      // ?.data?.map(item=>({...item.child_data,parentCategory: item.category_option_name})).map(child=>({
      //   brand_style: child[0]?.value,
      //   dimension: `
      //   ${child[1] && child[1]['category_option_type'] == "input-number" && child[1].value ? child[1].value: 0} in (D) *
      //   ${child[2] && child[2]['category_option_type'] == "input-number" && child[2].value ? child[2].value: 0} in (W) * 
      //   ${child[3] && child[3]['category_option_type'] == "input-number" && child[3].value ? child[3].value: 0} in (H)`,
      //   parentCategory:child?.parentCategory
      //   }))
      //   .filter(i=>i.brand_style !== undefined)
      //   setNonStandardApp(tempData)        
    }
    dispatch(designerRequestActions.changeStartFromMyOpenRequest(0));
  }, [data, dispatch]);

  const openImagePopup = (image, extension) => {
    if (extension[0] !== "pdf" && extension[0] !== "kit") {
      setCurrentImage(image)
      setShow(true)
    }
  }

  const renderFormOptions = (options, key) => {
    if (options && options.length !== 0) {
      let formOptions = [...options];
      let dropdownOptions = formOptions.filter(
        (x) => x.category_option_type === "dropdown"
      );
      let valueDropdown;
      let secondaryDoorInputValue = formOptions.filter(
        (x) => x.category_option_type === "input-text"
      );

      let doorFinishWall = formOptions.filter(
        (x) => x.category_option_name === "Wall"
      );

      let doorFinishBase = formOptions.filter(
        (x) => x.category_option_name === "Base"
      );

      let doorFinishIsland = formOptions.filter(
        (x) => x.category_option_name === "Island"
      );
      let soffitsWidthHeight = formOptions.filter(
        (x) => x.category_name === "Soffits" && x.category_option_type === "input-number"
      );
      const commaSeprateOthersValue = (v) => {
        if (v) {
          let temparr = v.split(',');
          let tempstr = "";
          temparr.forEach((val) => {
            tempstr += "<span>" + val + "</span>"
          })
          return tempstr;
        } else {
          return null;
        }
      }
      if ((key == "loose-appliances" && looseApp.length > 0) || (key == "appliances" && nonStandardApp.length)) {
        return null;
      } else {
        return (
          <div key={key} className="description-type">
            {formOptions && formOptions.length !== 0
              ? formOptions.map((data, index) => {
                if (data.child_data.length) {
                  valueDropdown = data['child_data'].filter(
                    (x) => x && x.category_option_type == "dropdown"
                  );
                } else {
                  valueDropdown = [];
                }
                switch (data.category_option_type) {
                  case "dropdown":
                    return null;
                  case "input-number":
                    return (
                      <span key={index}>
                        {data.category_option_name}:{" "}
                        {data.category_option_value}{" "}
                        {data.category_option_unit
                          ? data.category_option_unit
                          : "inches"}
                      </span>
                    );
                  case "input-text":
                    if (key == "secondary-door-style" || key == "multiplier" || key == "freshsales-url") {
                      return <span key={index}>{secondaryDoorInputValue.length && secondaryDoorInputValue[0].category_option_value}</span>;
                    }
                  case "checkbox":
                    if (key !== "door-finish") {
                      if (key == "soffits") {
                        if (soffitsWidthHeight.length == 0) {
                          return <span key={index}>{data.category_option_name} {valueDropdown && valueDropdown.length ? "(" + valueDropdown[0]['name'] + ")" : null}</span>;
                        }
                      } else {
                        return <span key={index} className={data.is_others == "1" ? "others" : null}>{data.category_option_name} <br></br> {data.is_others == "1" && data.child_data.length == 0 ?
                          <span className="others-child" dangerouslySetInnerHTML={{
                            __html: commaSeprateOthersValue(data.others_value),
                          }}></span> : null} {data.is_others == "1" && data.child_data.length > 0 ?
                            "(" + data.child_data[0]['value'] + ")" : null} {valueDropdown && valueDropdown.length ? "(" + valueDropdown[0]['name'] + ")" : null}</span>;
                      }
                    }
                  default:
                    return null;
                }
              })
              : null}
            {dropdownOptions && dropdownOptions.length !== 0 ? (
              <span
                id={data.category_option_id}
                key={data.category_option_id}
                value={data.category_option_id}
              >
                {dropdownOptions.map((data, index) => (
                  <span key={data.category_option_id}>
                    {data.category_option_name} {data.is_others == "1" && data.child_data.length == 0 ? "(" + data.others_value + ")" : null}
                  </span>
                ))}
              </span>
            ) : null}
            {key == "door-finish" ? <span>Wall: {doorFinishWall.length ? doorFinishWall[0].category_option_value : ""}
              <br></br>Base: {doorFinishBase.length ? doorFinishBase[0].category_option_value : ""}
              <br></br>Island: {doorFinishIsland.length ? doorFinishIsland[0].category_option_value : ""}</span> : ""}
          </div>
        );
      }
    } else {
      return null;
    }
  };
  const renderForm = () => {
    if (data["category_data"]) {
      let secondaryDoorInputValue = data["category_data"]['secondary-door-style'] && data["category_data"]['secondary-door-style']['data'].filter(
        (x) => x.category_option_type === "input-text"
      );
      let tempstr = null;
      let tempstr2 = null;
      const commaSeprateOthersValue = (v, key) => {
        if (key == "appliances") {
          if (!tempstr) {
            v.forEach((value) => {
              if (value.is_others && value.is_others == "1") {
                if (value.child_data.length) {
                  let temparr = value.child_data[0]['value'].split(',');
                  tempstr = "<span class='others'>Others</span>";
                  temparr.forEach((val) => {
                    tempstr += "<span class='others-child'>" + val + "</span>"
                  })
                }
              }
            });
            if (tempstr) {
              return tempstr;
            } else {
              return null;
            }
          } else {
            return null;
          }
        } else {
          if (!tempstr2) {
            v.forEach((value) => {
              if (value.is_others && value.is_others == "1") {
                if (value.child_data.length) {
                  let temparr = value.child_data[0]['value'].split(',');
                  tempstr2 = "<span class='others'>Others</span>";
                  temparr.forEach((val) => {
                    tempstr2 += "<span class='others-child'>" + val + "</span>"
                  })
                }
              }
            });
            if (tempstr2) {
              return tempstr2;
            } else {
              return null;
            }
          } else {
            return null;
          }
        }
      }
      return (
        <div>
          {Object.keys(data["category_data"]).map((key, index) => {
            return (
              <li style={{ display: (key == "secondary-door-style" && secondaryDoorInputValue.length) || (key !== "secondary-door-style") ? "" : "none" }} key={key} className={data["category_data"][key]["option"]["category_name"] === "Appliances" || data["category_data"][key]["option"]["category_name"] === "Loose Appliances" ? "appliances-row" : ""}>
                {key == "secondary-door-style" && secondaryDoorInputValue.length ? <strong className="title">
                  {data["category_data"][key]["option"]["category_name"]}
                </strong> : ""}
                {key !== "secondary-door-style" ? <strong className="title">
                  {data["category_data"][key]["option"]["category_name"] === "Appliances" ? "Standard Appliances" : data["category_data"][key]["option"]["category_name"]}
                </strong> : null}
                <div className="content">
                  {renderFormOptions(data["category_data"][key]["data"], key)}
                  {<div className="non-starndered description-type" style={{ width: "100%" }}>
                    {data["category_data"][key]["option"]["category_name"] === "Appliances" ? (
                      <>
                        {nonStandardApp?.map((item, index) => (
                          <div style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>{item.parentCategory}</div>
                            <ul className="list" key={index}>
                              {/* <li>
                              {`${item.parentCategory} (NS)`}
                            </li> */}
                              <li>
                                {`Brand & Model: ${item.brand_style}`}
                              </li>
                              <li>
                                {`Measurements : ${item.dimension}`}
                              </li>
                            </ul>
                            <br></br>
                          </div>
                        ))}
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "50%" }} dangerouslySetInnerHTML={{
                            __html: commaSeprateOthersValue(data["category_data"][key]["data"], "appliances"),
                          }}></div>
                        </div>
                      </>
                    ) : null}
                    {data["category_data"][key]["option"]["category_name"] === "Loose Appliances" ? (
                      <>
                        {looseApp?.map((item, index) => (
                          <div style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>{item.parentCategory}</div>
                            <ul style={{ width: "50%" }} className="list" key={index}>
                              {/* <li>
                              {`${item.parentCategory} (NS)`}
                            </li> */}
                              <li>
                                {`Brand & Model: ${item.brand_style}`}
                              </li>
                              <li>
                                {`Measurements : ${item.dimension}`}
                              </li>
                            </ul>
                            <br></br>
                          </div>
                        ))}
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "50%" }} dangerouslySetInnerHTML={{
                            __html: commaSeprateOthersValue(data["category_data"][key]["data"], "loose-appliances"),
                          }}></div>
                        </div>
                      </>
                    ) : null}
                  </div>}
                </div>
              </li>
            );
          })}
        </div>
      );
    }
  };
  const renderImages = (val) => {
    if (data["images"]) {
      var fileSrc;
      return (
        <div className="kitchen-measurement">
          {Object.keys(data["images"][val]).map((key, index) => {
            var fileExtention = /[.]/.exec(data["images"][val][key]["url"])
              ? /[^.]+$/.exec(data["images"][val][key]["url"])
              : undefined;
            if ((fileExtention[0]).toLowerCase() === "pdf") {
              fileSrc = PdfIcon;
            } else if ((fileExtention[0]).toLowerCase() === "doc" || (fileExtention[0]).toLowerCase() === "docx") {
              fileSrc = DocIcon;
            } else if ((fileExtention[0]).toLowerCase() === "xls" || (fileExtention[0]).toLowerCase() === "xlsx") {
              fileSrc = XlsIcon;
            } else if ((fileExtention[0]).toLowerCase() === "kit") {
              fileSrc = KitIcon;
            } else if ((fileExtention[0]).toLowerCase() === "png" || (fileExtention[0]).toLowerCase() === "jpg" || (fileExtention[0]).toLowerCase() === "jpeg") {
              fileSrc = data["images"][val][key]["url"];
            } else if ((fileExtention[0]).toLowerCase() === "ppt" || (fileExtention[0]).toLowerCase() === "pptx") {
              fileSrc = PptIcon;
            } else {
              fileSrc = FileIcon;
            }
            let fileName = data["images"][val][key]["url"].split("/").pop()
              .split("#")[0].split("?")[0]
            // let storagePath = data["images"][val][key]["url"].match(/storage.+/gm)[0]
            return (
              <div key={key} className="measurement-box">
                <div className="img-box"
                  style={(fileExtention && fileExtention[0] === "pdf") || (fileExtention && fileExtention[0] === "kit") ? { cursor: "default" } : null}
                  onClick={() => openImagePopup(data["images"][val][key]["url"], /[^.]+$/.exec(data["images"][val][key]["url"]))}>
                  <img
                    className="successfully-img"
                    src={fileSrc}
                    alt="kitchen"
                  />
                </div>
                <span className="filename">
                  {fileName}
                </span>

                <DownloadWithProgressBar {...{fileUrl:data["images"][val][key]["url"], fileName, clientAccessToken:cookies.clientAccessToken}}/>
                {/* <span className="download-link"
                  onClick={() => {
                    // const link = document.createElement("a");
                    // link.href = data["images"][val][key]["url"];
                    // link.setAttribute("download", fileName);
                    // document.body.appendChild(link);
                    // link.click();
                    // link.parentNode.removeChild(link);
                    downloadFile(data["images"][val][key]["url"], fileName, cookies.clientAccessToken)
                  }}>Download</span> */}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const hasAdditionalInformationContent = data?.['magento_note'] && data?.['magento_note'] !== "" && typeof data?.['magento_note'] === "string";
  const magentoData = hasAdditionalInformationContent ? JSON.parse(data["magento_note"]) : {};
  const additionalInformationContentKeys = hasAdditionalInformationContent ? Object.keys(magentoData) : [];
  return (
    <div className="viewrequestcontent">
      <ul className="">
        {hasAdditionalInformationContent && magentoData && magentoData['Job Name: '] && <li>
          <strong className="title">Job Name</strong>
          <div className="content">
            <span>
              {magentoData['Job Name: ']}
            </span>
          </div>
        </li>}
        <li style={{ display: data && data.request_type ? "" : "none" }}>
          <strong className="title">Request Type</strong>
          <div className="content">
            <span>
              {data && data.request_type && data.request_type[0]["name"]
                ? data.request_type[0]["name"]
                : ""}
            </span>
          </div>
        </li>
        {renderForm()}

        {data?.plan_name &&
          <li>
            <strong className="title">Payment Plan</strong>
            <div className="content">
              <span>
                {data?.plan_name}
              </span>
            </div>
          </li>
        }

        <li
          style={{
            display:
              data && data["images"]["kitchen_measurement"].length > 0
                ? ""
                : "none",
          }}
        >
          <strong className="title">Measurements</strong>
          <div className="content">
            {renderImages("kitchen_measurement")}

            <p
              style={{
                display:
                  data && data["is_all_section_properly_labeled"] ? "" : "none",
              }}
              className="properlylabeled"
            >
              <strong>All the section are properly labeled</strong>
            </p>

            <div className={`image-popup-outer ${show ? "show" : ""}`}>
              <div className="image-popup-inner">
                <span className="close-btn" onClick={() => setShow(false)}></span>
                <div className="image-box">
                  <img alt="kitchen measurement" src={currentImage} />
                </div>
              </div>
            </div>
          </div>
        </li>
        <li
          style={{
            display:
              data && data["images"]["kitchen_photo"].length > 0 ? "" : "none",
          }}
        >
          <strong className="title">Kitchen Photo</strong>
          <div className="content">{renderImages("kitchen_photo")}</div>
        </li>
        <li
          style={{
            display:
              data &&
                data["images"]["kit_file"] &&
                data["images"]["kit_file"].length > 0
                ? ""
                : "none",
          }}
        >
          <strong className="title">Kitchen Kit</strong>
          <div className="content">{renderImages("kit_file")}</div>
        </li>
        <li
          style={{
            display: data && data["note"] ? "" : "none",
          }}
        >
          <strong className="title">Additional Notes</strong>
          <div className="content">
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: convertStringToHtml(data?.note) }}
            />
          </div>
        </li>
        {hasAdditionalInformationContent && additionalInformationContentKeys.length > 0 ? <li>
          <strong className="title">Other Info</strong>
          <div className="content">
            {additionalInformationContentKeys.map((o, i) => (
              <div key={o + "_" + i} className="additional-information-content">
                <span><strong>{o}</strong></span>
                <span>{magentoData[o]}</span>
              </div>
            ))}
          </div>
        </li> : null}
      </ul>
    </div>
  );
}

export default ViewRequestContent;
