import React from "react";
import { useSelector, useDispatch } from "react-redux";
import LoaderComponent from "../../components/Loader";
import "./index.scss";
import ForgetPasswordForm from "../../components/ForgetPasswordForm";
import { actions as AuthActions } from '../../redux/reducers/AuthReducer'
import FooterLinks from "../../components/Common/FooterLinks";
import { useConfig } from "../../context/configContext";

function ForgetPassword() {
	const {project_name,login_page_banner} = useConfig();
	const dispatch = useDispatch();
	const { forgotPasswordLoading } = useSelector(state => state.auth);


	const onResetButtonClicked = (email) => {
		dispatch(AuthActions.emailForgotPassword(email));
	}
	return (
		<div>
			<LoaderComponent showhide={forgotPasswordLoading} />
			<div className="login-wrapper">
				<div className="login-left">
					<ForgetPasswordForm onResetButtonClicked={(email) => onResetButtonClicked(email)} />
				</div>
				<div className="login-right">
					<div className="login-title">
						<h2>
							Creating Beautiful<span>Kitchen Designs</span>
						</h2>
					</div>
					<div className="kitchen-cabinet">
						<img
              src={login_page_banner?.value}
              alt={project_name?.value+" Banner"}
            />
					</div>
				</div>
			</div>
			<FooterLinks />
		</div>
	);
}

export default ForgetPassword;
