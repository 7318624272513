import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { actions as AuthActions } from '../../redux/reducers/AuthReducer';
import Filters from '../Filters/';

import "./index.scss";

function MyRequestHeader({ requestStatusSection, myRequestListingData }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const {
    filtersDropdownData,
    filters,
    length,
    sortByFilter,
    sortDir,
    filterNotChangeByApi,
  } = useSelector((state) => state.myRequest);
  const { user, indicationStatus } = useSelector((state) => state.auth);
  const filterComponentRef = useRef();
  const [getIsComponentMounted, setIsComponentMounted] = useState(false); 
  useEffect(() => {
    if(user?.is_all_tab_prioritisation_enabled == 1 && requestStatusSection !== "closed"){
      dispatch(myRequestActions.changeSortByFilter("prioritise_order"));
    }else{
      dispatch(myRequestActions.changeSortByFilter("created_at"));
    }
  }, []);
  useEffect(() => {
    if(user?.is_all_tab_prioritisation_enabled == 1 && requestStatusSection !== "closed"){
      dispatch(myRequestActions.changeSortByFilter("prioritise_order"));
      getRequestList(filters,'prioritise_order',sortDir)
    }else{
      dispatch(myRequestActions.changeSortByFilter("created_at"));
      getRequestList(filters,'created_at',sortDir)
    }
  },[user.is_all_tab_prioritisation_enabled])
  useEffect(() => {
    if(filterComponentRef.current && filterComponentRef.current.clearAllFilters && typeof filterComponentRef.current.clearAllFilters === 'function' && getIsComponentMounted){
      if(filters.request_status){
        delete filters.request_status;
      }
      dispatch(myRequestActions.updateFilters(filters));
      filterComponentRef.current.clearAllFilters(1);
      if(user?.is_all_tab_prioritisation_enabled == 1 && requestStatusSection !== "closed"){
        dispatch(myRequestActions.changeSortByFilter("prioritise_order"));
      }else{
        dispatch(myRequestActions.changeSortByFilter("created_at"));
      }
    }
  }, [requestStatusSection]);
const getRequestList = (appliedFilter,sortDateFilter,sortDir)=>{
  if (requestStatusSection === "needs-approval") {
    dispatch(myRequestActions.changeStartFrom(0));
    dispatch(
      myRequestActions.getNeesApprovalListing(
        appliedFilter,
        sortDateFilter,
        sortDir,
        0,
        length,
        cookies.clientAccessToken
      )
    );
    setIsComponentMounted(true);
  } else if (requestStatusSection === "yourdrafts") {
    dispatch(myRequestActions.changeStartFrom(0));
    dispatch(
      myRequestActions.getDraftListing(0, length, cookies.clientAccessToken)
    );
    setIsComponentMounted(true);
  } else if (requestStatusSection === "open-requests") {
    dispatch(myRequestActions.changeStartFrom(0));
    dispatch(
      myRequestActions.getOpenRequestListing(
        appliedFilter,
        sortDateFilter,
        sortDir,
        0,
        length,
        cookies.clientAccessToken
      )
    );
    setIsComponentMounted(true);
  }else if (requestStatusSection === "all-requests") {
    dispatch(myRequestActions.changeStartFrom(0));
    dispatch(
      myRequestActions.getAllRequestListing(
        appliedFilter,
        sortDateFilter,
        sortDir,
        0,
        length,
        cookies.clientAccessToken
      )
    );
    setIsComponentMounted(true);
  } else if (requestStatusSection === "wip") {
    dispatch(myRequestActions.changeStartFrom(0));
    dispatch(
      myRequestActions.getWipRequestListing(
        appliedFilter,
        sortDateFilter,
        sortDir,
        0,
        length,
        cookies.clientAccessToken
      )
    );
    setIsComponentMounted(true);
  } else if (requestStatusSection === "needs-information") {
    dispatch(myRequestActions.changeStartFrom(0));
    dispatch(
      myRequestActions.getNeedsInfoRequestListing(
        appliedFilter,
        sortDateFilter,
        sortDir,
        0,
        length,
        cookies.clientAccessToken
      )
    );
    setIsComponentMounted(true);
  } else if (requestStatusSection === "closed") {
    dispatch(myRequestActions.changeStartFrom(0));
    dispatch(
      myRequestActions.getClosedRequestListing(
        appliedFilter,
        sortDateFilter,
        sortDir,
        0,
        length,
        cookies.clientAccessToken
      )
    );
    setIsComponentMounted(true);
  }
  else if (requestStatusSection === "prioritized") {
    
    dispatch(myRequestActions.changeStartFrom(0));
    dispatch(myRequestActions.changeRequestListingData([]));
    dispatch(
      myRequestActions.getRequestListingPrioritize(
        0,
        length,
        cookies.clientAccessToken
      )
    );
    setIsComponentMounted(true);
  }
}
  useEffect(() => {
    dispatch(AuthActions.getTabIndicationStatus(null,cookies.clientAccessToken));
    if(user?.is_all_tab_prioritisation_enabled == 1 && requestStatusSection !== "closed"){
      getRequestList(filters,'prioritise_order',sortDir)
    }else{
      dispatch(myRequestActions.changeSortDir("ASC"));
      getRequestList(filters,'created_at','ASC')
    }
  }, [requestStatusSection]);

  useEffect(()=>{
    if(indicationStatus&&indicationStatus.is_request_order_changed){
      if(user?.is_all_tab_prioritisation_enabled == 1 && requestStatusSection !== "closed"){
        getRequestList(filters,'prioritise_order',sortDir)
      }else{
        getRequestList(filters,'created_at',sortDir)
      }
    }
  },[indicationStatus])

  useEffect(() => {
    if(filterNotChangeByApi){
    getRequestList(filters,sortByFilter,sortDir);
    dispatch(myRequestActions.setFilterNotChangeByApi(false));
    }
   }, [filterNotChangeByApi]);

  const newRequest = () => {
    history.push("/app/client/submitrequest");
  };

  const handleFilterChange = (filtersData) => {
    if(filters.request_status && requestStatusSection !== "all-requests"){
      filtersData['request_status'] = filters.request_status
    }
    dispatch(myRequestActions.updateFilters(filtersData));
    // getRequestList(filtersData,sortByFilter)
  }

  const sortByChange = (e) =>{
    if(e.target.value == "updated_at"){
      dispatch(myRequestActions.changeSortDir("DESC"));
    }else{
      dispatch(myRequestActions.changeSortDir("ASC"));
    }
    dispatch(myRequestActions.changeSortByFilter(e.target.value));
    getRequestList(filters,e.target.value,e.target.value == "updated_at"?"DESC":"ASC")
  }

  const changeOrder = (v) =>{
    if(v == "ASC"){
      dispatch(myRequestActions.changeSortDir("DESC"));
      getRequestList(filters,sortByFilter,"DESC");
    }else{
      dispatch(myRequestActions.changeSortDir("ASC"));
      getRequestList(filters,sortByFilter,"ASC");
    }
  }

  let filterOptions = ['request_title', 'request_number', 'request_type', 'request_status', 'created_by', 'assign_to', 'start_date', 'end_date','due_start_date', 'due_end_date','has_updates'];
  if (user?.group_id === 4 || user?.group_id === 5) {
    // Client type is `General` OR `User`.
    filterOptions = ['request_title', 'request_number', 'request_type', 'request_status', 'assign_to', 'start_date', 'end_date','due_start_date', 'due_end_date','has_updates']
  }


  if (
    requestStatusSection === "yourdrafts"
  ) {
    return user?.group_id === 4 ? null : (
      <div className="my-request-header cf">
        <div className="container">
          <button
            type="button"
            onClick={newRequest}
            className="btn btn-black new-request-btn"
          >
            New Request
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="my-request-header cf">
        <div className="container">
          {user?.group_id === 4 ? null : <button
            type="button"
            onClick={newRequest}
            className="btn btn-black new-request-btn"
          >
            New Request
          </button>}
          {requestStatusSection !== "prioritized" && <Filters
            ref={filterComponentRef}
            onChangeHandler={handleFilterChange}
            requestTypeList={filtersDropdownData.request_types}
            statusList={filtersDropdownData.request_status}
            createdByList={filtersDropdownData.created_by}
            assignedToList={filtersDropdownData.assign_to}
            filterOptions={filterOptions}
            requestStatusSection={requestStatusSection}
          />}
          {sortByFilter !== "prioritise_order"&&requestStatusSection !== "prioritized" &&<div className="headermonthselect" style={{display: 'inline'}}>
            <button className="sort-dir-btn" type="button" onClick={()=>{changeOrder(sortDir)}}>{sortDir == "ASC"?<><svg className="active" width="9.813" height="17.123" viewBox="0 0 9.813 17.123"><path id="_4216565441537182966" data-name="4216565441537182966" d="M107.153,4.907V17.123h-1.078V4.907h-4.367L106.613,0l4.906,4.907Z" transform="translate(-101.707)"/></svg><svg width="9.813" height="17.123" viewBox="0 0 9.813 17.123"><path id="_4216565441537182966" data-name="4216565441537182966" d="M107.153,12.216V0h-1.078V12.216h-4.367l4.906,4.907,4.906-4.907Z" transform="translate(-101.707)"/></svg></>:<><svg width="9.813" height="17.123" viewBox="0 0 9.813 17.123"><path id="_4216565441537182966" data-name="4216565441537182966" d="M107.153,4.907V17.123h-1.078V4.907h-4.367L106.613,0l4.906,4.907Z" transform="translate(-101.707)"/></svg><svg className="active" width="9.813" height="17.123" viewBox="0 0 9.813 17.123"><path id="_4216565441537182966" data-name="4216565441537182966" d="M107.153,12.216V0h-1.078V12.216h-4.367l4.906,4.907,4.906-4.907Z" transform="translate(-101.707)"/></svg></>}</button>
          </div>}
          {requestStatusSection !== "prioritized" && <div className="headermonthselect" style={{display: 'inline'}}>
            <select
                className="form-control"
                id="month"
                onChange={sortByChange}
                value={sortByFilter}
                style={{float: 'right', width: 'auto'}}
              >
               {user?.is_all_tab_prioritisation_enabled == 1 && requestStatusSection !== "closed"? <option key="prioritise_order" value="prioritise_order">
                Sequence
                </option>:null}
                <option key="created_at" value="created_at">
                    Created Date
                </option>
                <option key="updated_at" value="updated_at">
                    Updated Date
                </option>
                <option key="due_date" value="due_date">
                    Due Date
                </option>
              </select>
              <span className="sort-by">Sort By: </span>
          </div>
          }
          
        </div>
      </div>
    );
  }
}

export default MyRequestHeader;
