/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import { actions as AuthActions } from "../../redux/reducers/AuthReducer";
import "./index.scss";
import LoginForm from "../../components/LoginForm";
import LoaderComponent from "../../components/Loader";
import FooterLinks from "../../components/Common/FooterLinks";
import { useConfig } from "../../context/configContext";

function Login() {
  const {project_name,login_page_banner} = useConfig();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    loginLoading,
    loginSuccess,
    loginFailed,
    clientAccessToken,
    expire_in,
    user,
    redirect,
    logoutSuccess,
  } = useSelector((state) => state.auth);
  const [cookies, setCookie, removeCookie] = useCookies(["clientAccessToken"]);


  //when navigating from admin for the first time
  //1st step 
  useEffect(() => {
    let clientAccessTokenFromURL = new URLSearchParams(location.search).get("clientAccessToken");
    let redirectUrl = new URLSearchParams(location.search).get("redirect");
    if (clientAccessTokenFromURL) {
      dispatch(AuthActions.getUserDetails(clientAccessTokenFromURL, redirectUrl));
      removeCookie("clientAccessToken", { path: "/" });
      setCookie("clientAccessToken", clientAccessTokenFromURL, { path: "/" });
    } else {
      history.push("/login");
    }
  }, [location.search, dispatch, history, setCookie, removeCookie]);
  //2ndstep
  useEffect(() => {
    if (loginSuccess && redirect) {
      history.push(redirect);
    } else if (loginSuccess) {
      if (user.group_id == 3) {
        history.push("/app/designer/myrequest");
      } else {
        history.push("/app/client/myrequests/all-requests");
      }
    }
    dispatch(AuthActions.clearLoginFlags());
  }, [loginSuccess, redirect])

  //(when navigating from admin for the first time )done

  //when navigationg from main.js when cookies.clientAccessToken
  useEffect(() => {
    if (clientAccessToken) {
      dispatch(AuthActions.getUserDetails(clientAccessToken));
      removeCookie('clientAccessToken', { path: '/' });
      setCookie("clientAccessToken", clientAccessToken, {
        path: "/",
      });
      if (user.group_id == 3) {
        history.push("/app/designer/myrequest");
      } else {
        if (redirect) {
          history.push(redirect);
        } else {
          history.push("/app/client/myrequests/all-requests");
        }
      }

      dispatch(AuthActions.clearAccessToken());
    }
  }, [clientAccessToken, history, dispatch, setCookie, removeCookie, redirect])

  const handleLoginSuccess = useCallback(
    (clientAccessToken, expire_in, group_id) => {
      let seconds = Math.round(new Date() / 1000);
      seconds = seconds + expire_in;
      let tokenExpiryDate = new Date(seconds * 1000);
      setCookie("clientAccessToken", clientAccessToken, {
        path: "/",
        expires: tokenExpiryDate,
      });
      dispatch(AuthActions.clearLoginFlags());
      // eslint-disable-next-line eqeqeq
      if (user.group_id == 3) {
        history.push("/app/designer/myrequest");
      } else {
        history.push("/app/client/myrequests/all-requests");
      }
    },
    [dispatch, history, setCookie, user]
  );

  useEffect(() => {
    if (
      !loginLoading &&
      loginSuccess &&
      !loginFailed &&
      clientAccessToken !== "" &&
      expire_in &&
      user.group_id
    ) {
      handleLoginSuccess(clientAccessToken, expire_in, user.group_id);
    }
  }, [
    loginLoading,
    loginSuccess,
    loginFailed,
    clientAccessToken,
    expire_in,
    handleLoginSuccess,
  ]);

  const signInClicked = (email, password) => {
    dispatch(AuthActions.login(email, password));
  };
  return (
    <div>
      <LoaderComponent showhide={loginLoading} />
      <div className="login-wrapper">
        <div className="login-left">
          <LoginForm
            signInClicked={(email, password) => signInClicked(email, password)}
          />
        </div>
        <div className="login-right">
          <div className="login-title">
            <h2>
              Creating Beautiful<span>Kitchen Designs</span>
            </h2>
          </div>
          <div className="kitchen-cabinet">
            <img
              src={login_page_banner?.value}
              alt={project_name?.value+" Banner"}
            />
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
}

export default Login;
