import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useConfig } from "../../../../context/configContext";
import config from "../../../../config/config";

function Viewinvoice({ isOpen, handleToggleModel }) {
  const { logo, project_name } = useConfig();
  const { viewInvoiceResponse: { data = [] } = {} } =
    useSelector((state) => state?.myAccountReducer) || {};
  const { COMPANY_ADDRESS = {} } = config;
  const { NAME, LINE_1, LINE_2, CITY, STATE_CODE, POSTAL_CODE } =
    COMPANY_ADDRESS;

  return (
    <div
      className={`image-popup-outer ${
        isOpen ? "show is-open-popup invoice-popup" : ""
      }`}
    >
      <div className="image-popup-inner">
        <span className="close-btn" onClick={handleToggleModel}></span>
        <div className="image-box">
          <div className="invoice-popup">
            <div className="image-popup-inner">
              <div className="logo">
                <img src={logo.value} alt={project_name.value} />
              </div>
              <div className="invoice">
                <div className="invoiceNumber">
                  <h1>Invoice #{(data[0] || {})?.id}</h1>
                  <p className="date">
                    Date issued:{" "}
                    {(data[0] || {})?.created_at
                      ? moment((data[0] || {})?.created_at).format("MM/DD/YYYY")
                      : ""}
                  </p>
                </div>
                <div
                  className={`invoiceStatus ${
                    (data[0] || {})?.status === "failed" ? "failinvoice" : ""
                  } `}
                >
                  Status:
                  <span> {(data[0] || {})?.status} </span>
                </div>
              </div>
              <div className="billingmain">
                <div className="billingmain-table">
                  <table
                    border="0"
                    cellPadding="0"
                    cellspacing="0"
                    width="100%"
                  >
                    <tr>
                      <td width="50%" className="bill-from" valign="top">
                        <table
                          border="0"
                          cellPadding="0"
                          cellspacing="0"
                          width="100%"
                        >
                          <tr>
                            <td className="billtitle">BILL FROM</td>
                          </tr>
                          <tr>
                            <td>{NAME}</td>
                          </tr>
                          <tr>
                            <td>{LINE_1}</td>
                          </tr>
                          {LINE_2?<tr>
                            <td>{LINE_2}</td>
                          </tr>:null}

                          <tr>
                            <td>{CITY}</td>
                          </tr>
                          <tr>
                            <td>{`${STATE_CODE} ${POSTAL_CODE}`}</td>
                          </tr>
                        </table>
                      </td>
                      <td width="50%" className="bill-to" valign="top">
                        <table
                          border="0"
                          cellPadding="0"
                          cellspacing="0"
                          width="100%"
                        >
                          <tr>
                            <td className="billtitle">BILL To</td>
                          </tr>
                          {(data[0] || {})?.client?.company_name && (
                            <tr>
                              <td>{(data[0] || {})?.client?.company_name}</td>
                            </tr>
                          )}
                          <tr>
                            <td>{(data[0] || {})?.street}</td>
                          </tr>
                          <tr>
                            <td>{(data[0] || {})?.street_2}</td>
                          </tr>
                          {(data[0] || {}) && (
                            <tr>
                              <td>
                                {(data[0] || {})?.city},{" "}
                                {(data[0] || {})?.region_name}-
                                {(data[0] || {})?.postcode}
                              </td>
                            </tr>
                          )}
                          {/* {(data[0] || {}) && <tr><td>{(data[0] || {})?.region_name}</td></tr>}
                                                    {(data[0] || {}) && <tr><td>{(data[0] || {})?.postcode}</td></tr>} */}
                          {(data[0] || {}) && (
                            <tr>
                              <td>{(data[0] || {})?.country_name}</td>
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="paymentDetails">
                <h3>Payment details</h3>
                <div className="payment-table">
                  <table>
                    <tr>
                      <th>Description</th>
                      <th>Qty</th>
                      <th>Unit Price</th>
                      <th>Amount</th>
                    </tr>
                    <tr>
                      <td>
                        {project_name?.value} Design Service Charge{" "}
                        {/* {(data[0] || {})?.plan &&
                          `(${(data[0] || {})?.plan?.label} - ${
                            (data[0] || {})?.plan?.name
                          })`} */}
                      </td>
                      <td>1</td>
                      <td>
                        {(data[0] || {})?.client_plan?.price
                          ? "$" + (data[0] || {})?.client_plan?.price
                          : ""}
                      </td>
                      <td>
                        {(data[0] || {})?.client_plan?.price
                          ? "$" + (data[0] || {})?.client_plan?.price
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td>
                        {(data[0] || {})?.client_plan?.price
                          ? "$" + (data[0] || {})?.client_plan?.price
                          : ""}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="paymentStatus">
                <h3>Payment Status</h3>
                <p className="paymentDate">
                  Date Paid:{" "}
                  {(data[0] || {})?.created_at
                    ? moment((data[0] || {})?.created_at).format(
                        "MM/DD/YYYY - h:mm a"
                      )
                    : ""}{" "}
                </p>
                {((data[0] || {})?.client_plan?.plan_type === "days" ||
                  (data[0] || {})?.client_plan?.plan_type === "both") && (
                  <p className="paymentDate">
                    Plan Validity:{" "}
                    {moment((data[0] || {})?.client_plan?.plan_start).format(
                      "MM/DD/YYYY"
                    )}{" "}
                    -{" "}
                    {moment((data[0] || {})?.client_plan?.plan_end).format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                )}
                {(data[0] || {})?.card_type && (data[0] || {})?.card_number && (
                  <p className="paymentMethod">
                    Payment Method: {(data[0] || {})?.card_type} ending with{" "}
                    {(data[0] || {})?.card_number}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Viewinvoice;
